import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Route, Switch, withRouter } from 'react-router-dom';
import { 
  AssessmentOutlined, 
  CodeOutlined, 
  MeetingRoomOutlined, 
  KeyboardArrowDown,
  VpnKeyOutlined,
  Work,
  People,
  Person,
  Business,
  Folder
} from '@material-ui/icons';
import Icon from '@material-ui/core/Icon';

import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import { Avatar, Chip, Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router';
import { PagePaths } from '../../enums/page-paths.enum';
import { AuthenticationGetter } from '../../store/authentication/authentication.getters';
import store from '../../store/store';
import { AUTHENTICATION_ACTIONS } from '../../store/authentication/authentication.actions';
import DataExportPage from './data-export/data-export.page';
import ApiCredentialPage from './api-credential/api-credential.page';
import KillDataPage from './kill-data/kill-data.page';
import KillDataConfigPage from './kill-data/kill-data-config.page';
import SettingsIcon from '@material-ui/icons/Settings';
import GlobalConfigPage from './global-config/global-config.page';
import ProjectSettingPage from './project-setting/project-setting.page';
import RolesPage from './human-resources/roles/roles.page';
import ResourcesPage from './human-resources/resources/resources.page';
import { NavigationItemInterface } from '../../interfaces/common/navigation-item.interface.interface';
import { NavigationGetter } from '../../store/navigation/navigation.getter';
import LoginsPage from './human-resources/logins/logins.page';
import PeoplePage from './human-resources/people/people.page';
import CompanyPositionsPage from './human-resources/companies/company-positions.page';
import { CompanyInterface } from '../../interfaces/schema/company.interface';
import { PersonInterface } from '../../interfaces/schema/person.interface';
import CompaniesPage from './human-resources/companies/companies.page';
import { RoleInterface } from '../../interfaces/schema/role.interface';
import ChangeRoleDialog from '../../dialogs/authentication/change-role.dialog';
import { cloneDeep, update } from 'lodash';
import EditKillDataConfigPage from './kill-data/edit-kill-data-config.page';
import ImportedFilesPage from './data-export/imported-data.page';
import UpdatePasswordDialog from './../../components/dialogs/update-password.dialog';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: '100%',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    overflow: 'auto',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

/**
 * Dashboard Page
 * 
 * @returns {JSX.Element}
 */
 const DashboardPage = () => {
  /** Initial variables */
  const classes = useStyles();
  const theme   = useTheme();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [navigationList, setNavigationList] = useState([] as NavigationItemInterface[]);
  const [currentCompany, setCurrentCompany] = useState({} as CompanyInterface);
  const [currentOperator, setCurrentOperator] = useState({} as PersonInterface);
  const [currentRole, setCurrentRole] = useState({} as RoleInterface);
  const [availableRoles, setAvailableRoles] = useState([] as RoleInterface[]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isPermitted, setIsPermitted] = useState(null as boolean | null)
  const [showChangeRole, setShowChangeRole] = useState(false as boolean);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false as boolean);

  const toggleMenu = () => setOpen(!open);

  /** Initial App */
  useEffect(() => {
    setStates();

    const stopRouteListener = history.listen((location, action) => {
      setIsPermitted(NavigationGetter.isPermitted());
    });


    const unsubscribe = store.subscribe(() => {
      setStates();

      if (!AuthenticationGetter.isLoggedIn())
        history?.replace(PagePaths.LOGIN)
    })

    return () => {
      unsubscribe();
      stopRouteListener();
    }
  }, [])

  /**
   * Sync stats when store updates
   */
  const setStates = () => {
    setIsPermitted(NavigationGetter.isPermitted());
    setNavigationList(NavigationGetter.listAvailable());
    setCurrentCompany(AuthenticationGetter.currentCompany());
    setCurrentOperator(AuthenticationGetter.currentOperator());
    setCurrentRole(AuthenticationGetter.currentRole());
    setAvailableRoles(
      AuthenticationGetter.roles()?.map((item: any) => item.role)
    );
  }

  /**
   * Logout
   */
  const logout = () => {
    store.dispatch(AUTHENTICATION_ACTIONS.AUTHENTICATION_LOGOUT())
  }

  /**
   * Navigate to
   * 
   * @param {PagePaths} path
   */
  const navTo = (path: NavigationItemInterface) => {
    if (path.external) {
      window.open(path.path, path.openInNew ? '_blank' : '_self');
    }
    else
      history.push(path.path);
  }

  /**
   * Navigate to external website
   * 
   * @param {PagePaths} path
   */
  const navToExternal = (path: PagePaths) => {
    window.open(path, '_blank')
  }

  /**
   * Can Change Role
   */
  const canChangeRole = () => {
    return !!AuthenticationGetter.roles()?.length
  }

  /**
   * Update Password
   */
  const updatePassword = () => {
    setShowUpdatePassword(true);
  }

  return (
    <div>
      <UpdatePasswordDialog
        showDialog    = {showUpdatePassword}
        setShowDialog = {setShowUpdatePassword}
      ></UpdatePasswordDialog>
      <ChangeRoleDialog
        history={history}
        setShow={setShowChangeRole}
        show={showChangeRole} />
      <CssBaseline />
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className="d-flex">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleMenu}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Meat Research
          </Typography>

          <Chip 
            className="ms-auto"
            clickable
            avatar={<Avatar>{currentOperator?.person_last_name?.slice(0, 1)}</Avatar>} 
            label={`${currentOperator?.person_first_name} - ${currentCompany?.company_name}`}
            onDelete={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            deleteIcon={<KeyboardArrowDown />}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            <div className="px-3 py-2">
              <table>
                <thead>
                  <tr className="bg-light border-bottom">
                    <th>Operator ID: </th>
                    <td className="ps-2 py-2">
                      {currentOperator?.serial_number}
                    </td>
                  </tr>
                  <tr className="border-bottom">
                    <th>Operator: </th>
                    <td className="ps-2 py-2">
                      {currentOperator?.person_first_name} {currentOperator?.person_last_name}
                    </td>
                  </tr>
                  <tr className="bg-light border-bottom">
                    <th>Company: </th>
                    <td className="ps-2 py-2">
                      {currentCompany?.company_name}
                    </td>
                  </tr>
                  <tr className="border-bottom">
                    <th>Role: </th>
                    <td className="ps-2 py-2">
                      <div>
                        {
                          availableRoles.map((role, index) => {
                            return (<div key={index}>
                              {role.role_name}
                            </div>)
                          })
                        }
                      </div>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>

            {/* <MenuItem onClick={() => {
              setAnchorEl(null);
              history.push(PagePaths.SETTINGS)
            }}>
              <div className="d-flex w-100">
                <Icon>settings</Icon>
                <span className="ms-auto">Settings</span>
              </div>
            </MenuItem> */}

            {/* canChangeRole() && <MenuItem onClick={() => {
              setAnchorEl(null);
              setShowChangeRole(true)
            }}>
              <div className="d-flex w-100">
                <Icon>change_circle</Icon>
                <span className="ms-auto">Change Role</span>
              </div>
          </MenuItem> */}

            <MenuItem onClick={() => {
              setAnchorEl(null);
              updatePassword();
            }}>
              <div className="d-flex w-100">
                <Icon>vpn_key</Icon>
                <span className="ms-auto">Update Password</span>
              </div>
            </MenuItem>

            <MenuItem onClick={() => {
              setAnchorEl(null);
              logout();
            }}>
              <div className="d-flex w-100">
                <MeetingRoomOutlined />
                <span className="ms-auto">Logout</span>
              </div>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <div className="d-flex">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={toggleMenu}>
              {theme.direction === 'rtl' ? <MenuIcon /> : <MenuIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {
              navigationList.map((navigationItem: NavigationItemInterface, navigationIndex) => {
                return <ListItem 
                  button 
                  key={navigationIndex}
                  onClick={() => navTo(navigationItem)}
                >
                  <ListItemIcon>
                    <Icon>{navigationItem.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={navigationItem.name} />
                </ListItem>
              })
            }
          </List>
          <Divider />
          <List>
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <MeetingRoomOutlined />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div/>
            {isPermitted && <Switch>
              <Route path={PagePaths.KILL_DATA}>
                <KillDataPage />
              </Route>
              <Route path={PagePaths.SESSION_DATA}>
                <DataExportPage />
              </Route>
              <Route path={PagePaths.PROJECT_SETTING}>
                <ProjectSettingPage />
              </Route>
              <Route path={PagePaths.GLOBAL_CONFIG}>
                <GlobalConfigPage />
              </Route>
              <Route path={PagePaths.API_CREDENTIAL}>
                <ApiCredentialPage />
              </Route>
              <Route path={PagePaths.ROLES}>
                <RolesPage />
              </Route>
              <Route path={PagePaths.RESOURCES}>
                <ResourcesPage />
              </Route>
              <Route path={PagePaths.LOGINS}>
                <LoginsPage />
              </Route>
              <Route path={PagePaths.PEOPLE}>
                <PeoplePage />
              </Route>
              <Route path={PagePaths.COMPANY_POSITIONS}>
                <CompanyPositionsPage />
              </Route>
              <Route path={PagePaths.COMPANIES}>
                <CompaniesPage />
              </Route>
              <Route path={PagePaths.KILL_DATA_CONFIG + '/add'}>
                <EditKillDataConfigPage />
              </Route>
              <Route path={PagePaths.KILL_DATA_CONFIG + '/:id/edit'}>
                <EditKillDataConfigPage />
              </Route>
              <Route path={PagePaths.KILL_DATA_CONFIG}>
                <KillDataConfigPage />
              </Route>
              <Route path={PagePaths.IMPORTED_FILES}>
                <ImportedFilesPage />
              </Route>
            </Switch> ||
              <div className="my-5 text-center mx-auto text-muted py-5">
                {/* <h1>
                  <b>403</b>
                </h1>
                <h4>Access Denied</h4> */}
              </div>
            }
        </main>
      </div>
    </div>
  );
}

export default withRouter(DashboardPage);