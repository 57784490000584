import { SessionTypeInterface } from "../../interfaces/schema/session-type.interface";
import { RequestService } from "../common/request.service";

export const ProjectService = {

  /**
   * Insert or update project
   * @param payload 
   */
  save(payload: SessionTypeInterface) {
    const _http   = new RequestService();
    return _http.post(`/projects`, payload).then(res => res.response?.result);
  },

  /**
   * Archive project
   * @param projectId 
   */
  archive(projectId: string) {
    const _http = new RequestService();
    return _http.delete(`/projects/${projectId}`).then(res => res.response);
  }
}