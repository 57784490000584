import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeclarationComponent from "../../components/common/declaration.component";
import ObjectEditorComponent from "../../components/common/object-editor.component";
import { SessionRecordInterface } from "../../interfaces/schema/session-record.interface";
import { SessionInterface } from "../../interfaces/schema/session.interface";

import store from "../../store/store";
import { SESSION_RECORD_ACTION } from "../../store/session-record/session-record.actions";
import { cloneDeep } from "lodash";

export const EditSessionRecordDialog = forwardRef((props: {
  show: boolean,
  ref: any,
  setShow: (show: boolean) => void,
  record: SessionRecordInterface,
  session: SessionInterface
}, ref) => {

  const [checked, setChecked]       = useState(false as boolean);
  const [recordData, setRecordData] = useState("{}" as string);
  const [record, setRecord]         = useState({} as SessionRecordInterface)

  useImperativeHandle(ref, () => ({
      /**
       * Initial Record Data
       * 
       * @param record 
       */
       initialEditingItem(record: SessionRecordInterface) {
        setRecord(cloneDeep(record));
        setRecordData(JSON.stringify(record.session_record_data || {}, null, 2))
      }
    })
  )

  /**
   * Save changes
   */
  const save = () => {
    store.dispatch(SESSION_RECORD_ACTION.MODIFY_RECORD({
      projectId: props.session.project?._id || "",
      sessionId: props.session._id || "",
      record: {
        ...record,
        session_record_data: JSON.parse(recordData)
      }
    }))
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.show}
        onClose={() => props.setShow(false)}
      >
        <DialogTitle>
          Edit {props.session.session_type?.session_type_name} #{props.session.serial_number} Record No. {props.record.session_progress}
        </DialogTitle>
        <DialogContent>

          <ObjectEditorComponent
            object={recordData}
            setObject={setRecordData}
          ></ObjectEditorComponent>

          <DeclarationComponent checked={checked} setChecked={setChecked}></DeclarationComponent>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => props.setShow(false)} color="primary">
            <b>Cancel</b>
          </Button>
          <Button onClick={save} color="secondary" disabled={!checked}>
            <b>Confirm</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
});