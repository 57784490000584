import { PagePaths } from "../../enums/page-paths.enum";
import { NavigationItemInterface } from "../../interfaces/common/navigation-item.interface.interface";

export const NavigationList: NavigationItemInterface[] = [
  {
    name: "Kill Date Stats",
    icon: "signal_cellular_4_bar",
    resources: [
      {
        name: "Kill Data",
        permission: ["*"]
      }
    ],
    path: PagePaths.KILL_DATA
  },
  {
    name: " Data Specs",
    icon: "perm_data_setting",
    resources: [
      {
        name: "Project Settings",
        permission: ["*"]
      }
    ],
    path: PagePaths.KILL_DATA_CONFIG
  },
  {
    name: "Session Data",
    icon: "assessment",
    resources: [
      {
        name: "Raw Session Data",
        permission: ["*"]
      }
    ],
    path: PagePaths.SESSION_DATA
  },  {
    name: "Imported Files",
    icon: "file_upload",
    resources: [
      {
        name: "Raw Session Data",
        permission: ["*"]
      }
    ], 
    path: PagePaths.IMPORTED_FILES
  },
  {
    name: "Project Settings",
    icon: "work",
    resources: [
      {
        name: "Project Settings",
        permission: ["*"]
      }
    ],
    path: PagePaths.PROJECT_SETTING
  },
  {
    name: "Global Config",
    icon: "settings",
    resources: [
      {
        name: "Global Configs",
        permission: ["*"]
      }
    ],
    path: PagePaths.GLOBAL_CONFIG
  },
  {
    name: "Companies",
    icon: "business",
    resources: [
      {
        name: "Global Configs",
        permission: ["*"]
      }
    ],
    path: PagePaths.COMPANIES
  },
  {
    name: "Company Positions",
    icon: "badge",
    resources: [
      {
        name: "Human Resource",
        permission: ["*"]
      }
    ],
    path: PagePaths.COMPANY_POSITIONS
  },
  {
    name: "People",
    icon: "folder_shared",
    resources: [
      {
        name: "Human Resource",
        permission: ["*"]
      },
      {
        name: "Global Configs",
        permission: ["*"]
      }
    ],
    path: PagePaths.PEOPLE
  },
  {
    name: "Logins",
    icon: "switch_account",
    resources: [
      {
        name: "Human Resource",
        permission: ["*"]
      }
    ],
    path: PagePaths.LOGINS
  },
  {
    name: "Roles",
    icon: "admin_panel_settings",
    resources: [
      {
        name: "Global Configs",
        permission: ["*"]
      }
    ],
    path: PagePaths.ROLES
  },
  {
    name: "Resources",
    icon: "folder",
    resources: [
      {
        name: "Global Configs",
        permission: ["*"]
      }
    ],
    path: PagePaths.RESOURCES
  },
  {
    name: "API Credential",
    icon: "vpn_key",
    resources: [
      {
        name: "Developer",
        permission: ["*"]
      },
      {
        name: "Global Configs",
        permission: ["*"]
      }
    ],
    released: false,
    path: PagePaths.API_CREDENTIAL
  },
  {
    name: "Swagger API",
    icon: "code",
    resources: [
      {
        name: "Developer",
        permission: ["*"]
      },
      {
        name: "Global Configs",
        permission: ["*"]
      }
    ],
    released: false,
    external: true,
    openInNew: true,
    path: PagePaths.API_SWAGGER
  }
]