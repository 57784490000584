import { SessionTypeInterface } from './../../interfaces/schema/session-type.interface';
import { Pagination } from "../../interfaces/common/pagination.interface"
import { SessionInterface } from '../../interfaces/schema/session.interface';
import { SessionRecordInterface } from '../../interfaces/schema/session-record.interface';

function FETCH(options: {
  projectId: string, 
  sessionId: string,
  pagination?: Pagination
}) {
  return {
    type: SESSION_RECORD_ACTION_TYPES.FETCH,
    projectId: options.projectId,
    sessionId: options.sessionId,
    payload: {
      pagination: options.pagination || {}
    }
  }
}

function FETCH_SUCCESS(results: SessionRecordInterface[], count: number) {
  return {
    type: SESSION_RECORD_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

function ARCHIVE_RECORD(options: {
  projectId: string
  sessionId: string
  recordId: string
}) {
  return {
    type: SESSION_RECORD_ACTION_TYPES.ARCHIVE_RECORD,
    projectId: options.projectId,
    sessionId: options.sessionId,
    payload: options.recordId
  }
}

function ARCHIVE_RECORD_SUCCESS(recordId: string) {
  return {
    type: SESSION_RECORD_ACTION_TYPES.ARCHIVE_RECORD_SUCCESS,
    payload: recordId

  }
}

function MODIFY_RECORD(options: {
  projectId: string
  sessionId: string
  record: SessionRecordInterface
}) {
  return {
    type: SESSION_RECORD_ACTION_TYPES.MODIFY_RECORD,
    projectId: options.projectId,
    sessionId: options.sessionId,
    payload: options.record
  }
}

function MODIFY_RECORD_SUCCESS(record: SessionRecordInterface) {
  return {
    type: SESSION_RECORD_ACTION_TYPES.MODIFY_RECORD_SUCCESS,
    payload: record
  }
}

export const SESSION_RECORD_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  ARCHIVE_RECORD,
  ARCHIVE_RECORD_SUCCESS,
  MODIFY_RECORD,
  MODIFY_RECORD_SUCCESS
}

export const SESSION_RECORD_ACTION_TYPES = {
  FETCH                  : '[SESSION_RECORD] FETCH',
  FETCH_SUCCESS          : '[SESSION_RECORD] FETCH SUCCESS',
  
  ARCHIVE_RECORD         : '[SESSION_RECORD] ARCHIVE RECORD',
  ARCHIVE_RECORD_SUCCESS : '[SESSION_RECORD] ARCHIVE RECORD SUCCESS',

  MODIFY_RECORD          : '[SESSION_RECORD] MODIFY RECORD',
  MODIFY_RECORD_SUCCESS  : '[SESSION_RECORD] MODIFY RECORD SUCCESS'
}