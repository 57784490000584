
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Chip, Icon, IconButton, TextField, Tooltip } from '@material-ui/core';
import store from '../../../../store/store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { cloneDeep } from 'lodash';
import { UtilsService } from '../../../../services/common/utils.service';
import { GlobalStyleConstant } from '../../../../constants/styles/global.const';
import { LOGINS_ACTION } from '../../../../store/logins/logins.actions';
import { LoginGetter } from '../../../../store/logins/logins.getter';
import { LoginInterface } from '../../../../interfaces/schema/login.interface';
import { ROLE_ACTION } from '../../../../store/role/role.actions';
import { RoleInterface } from '../../../../interfaces/schema/role.interface';
import { RoleGetter } from '../../../../store/role/role.getter';
import { AuthenticationGetter } from '../../../../store/authentication/authentication.getters';
import { ExitToApp } from '@material-ui/icons';
import { AUTHENTICATION_ACTIONS } from '../../../../store/authentication/authentication.actions';

/**
 * Logins Page
 * @returns {JSX.Element}
 */
export default function LoginsPage() {

  const [logins, setLogins] = useState([] as LoginInterface[])
  const [showDialog, setShowDialog] = useState(false)
  const [editingItem, setEditingItem] = useState({} as LoginInterface)
  const [roles, setRoles] = useState([] as RoleInterface[])
  const [currentCompany, setCurrentCompany] = useState({} as {_id?: string, company_name?: string})

  useEffect(() => {
    store.dispatch(LOGINS_ACTION.FETCH());
    store.dispatch(ROLE_ACTION.FETCH());

    const unsubscribe = store.subscribe(() => {
      setLogins(LoginGetter.listAll())
      setRoles(RoleGetter.listAll());
      setCurrentCompany(AuthenticationGetter.currentCompany());
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Pop up edit dialog
   * 
   * @param login 
   */
  const editLogin = (login?: LoginInterface) => {
    setEditingItem(cloneDeep(login || {
      dashboard_restricted: true
    }))
    setShowDialog(true);
  }

  /**
   * Apply User View
   * 
   * @param login 
   */
  const applyUserView = (login: LoginInterface) => {
    console.info(login);
    store.dispatch(AUTHENTICATION_ACTIONS.APPLY_USER_VIEW(login))
  }

  /**
   * Save Editing Login
   */
  const save = () => {
    const payload = cloneDeep(editingItem);
    if (!payload._id && payload.password != payload.confirm_password) {
      return UtilsService.showToast("Please Confirm Your Password!", "bg-danger");
    }
    delete payload.confirm_password;
    store.dispatch(LOGINS_ACTION.SAVE(payload, () => {
      setShowDialog(false);
      UtilsService.showToast("Saved");
    }));
  }

  /**
   * Is Role Attached
   * 
   * @param role 
   */
  const isRoleAttached = (role: RoleInterface, login: LoginInterface = editingItem) => {
    return login.roles?.some(r => r.role == role._id && r.company == currentCompany._id);
  }

  /**
   * Attached Roles
   */
  const attachedRoles = (login: LoginInterface) => {
    return login.roles?.filter(role => role?.company == currentCompany?._id) || [];
  }

  return (
    <div>
      <div className="d-flex mb-3">
        <span className="h3">Logins</span>
        <Button variant="contained" color="primary" className="ms-auto" onClick={() => editLogin()}>Create Login</Button>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Dashboard Assess</TableCell>
                <TableCell>APP Assess</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logins.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell component="th" scope="row">
                    {row.username}
                  </TableCell>
                  <TableCell>
                    {row.email}
                  </TableCell>
                  <TableCell>
                    {
                      attachedRoles(row).map((role, roleIndex) => {
                        return <Chip 
                          label={RoleGetter.item(role.role)?.role_name || "Unknown Role"} 
                          icon={<Icon>{RoleGetter.item(role.role)?.role_icon}</Icon>}
                          key={roleIndex} />
                      })
                    }
                  </TableCell>
                  <TableCell>
                    <Icon>{row.dashboard_restricted ? 'check_box_outline_blank' : 'check_box'}</Icon>
                  </TableCell>
                  <TableCell>
                    <Icon>{row.app_restricted ? 'check_box_outline_blank' : 'check_box'}</Icon>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit User Detail">
                      <IconButton onClick={() => editLogin(row)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Apply User View">
                      <IconButton onClick={() => applyUserView(row)} size="small">
                        <ExitToApp />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="lg">
        <DialogTitle className="pb-0">{editingItem._id ? "Edit" : "Create"} User</DialogTitle>
        <DialogContent style={{width: GlobalStyleConstant.dialogMd}}>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            required
            disabled={!!editingItem._id}
            value = {editingItem.username}
            onChange={(e) => {
              setEditingItem({
                ...editingItem,
                username: e.target.value
              })
            }}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            required
            value = {editingItem.email}
            onChange={(e) => {
              setEditingItem({
                ...editingItem,
                email: e.target.value
              })
            }}
            fullWidth
          />
          {!editingItem._id && <TextField
            autoFocus
            margin="dense"
            label="Password"
            required
            value = {editingItem.password}
            onChange={(e) => {
              setEditingItem({
                ...editingItem,
                password: e.target.value
              })
            }}
            fullWidth
          />}
          {!editingItem._id && <TextField
            autoFocus
            margin="dense"
            label="Confirm Password"
            required
            value = {editingItem.confirm_password}
            onChange={(e) => {
              setEditingItem({
                ...editingItem,
                confirm_password: e.target.value
              })
            }}
            fullWidth
          />}
          <span>Disable Dashboard Access:</span>
          <Checkbox
            color="primary"
            checked={editingItem.dashboard_restricted}
            onChange={e => {
              setEditingItem({
                ...editingItem,
                dashboard_restricted: e.target.checked
              })
            }}
          />
          <span>Disable Mobile App Access:</span>
          <Checkbox
            color="primary"
            checked={editingItem.app_restricted}
            onChange={e => {
              setEditingItem({
                ...editingItem,
                app_restricted: e.target.checked
              })
            }}
          />
          <TableContainer component={Paper} className="mt-3">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Role</TableCell>
                  <TableCell align="right">Attached</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                roles.map((role, roleIndex) => {
                  return <TableRow key={roleIndex}>
                    <TableCell>
                      {role.role_name}
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={isRoleAttached(role)}
                        onChange={e => {
                          const newPayload = cloneDeep(editingItem);
                          if (!newPayload.roles) newPayload.roles = [];
                          const index = newPayload.roles?.findIndex(r => r.role == role._id);
                          if (index > -1) {
                            newPayload.roles.splice(index, 1);
                          } else {
                            newPayload.roles.push({
                              role: role._id,
                              company: currentCompany._id
                            })
                          }
                          setEditingItem(newPayload);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                })
              }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => save()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
