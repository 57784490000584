import { Pagination } from "../../interfaces/common/pagination.interface"
import { RoleInterface } from "../../interfaces/schema/role.interface"

function FETCH(pagination?: Pagination) {
  return {
    type: ROLE_ACTION_TYPES.FETCH,
    payload: {
      pagination: pagination || {}
    }
  }
}

function FETCH_SUCCESS(results: RoleInterface[], count: number) {
  return {
    type: ROLE_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

function SAVE(payload: RoleInterface, cb?: () => void) {
  return {
    type: ROLE_ACTION_TYPES.SAVE,
    payload: {
      payload,
      cb
    }
  }
}

function SAVE_SUCCESS(result: RoleInterface) {
  return {
    type: ROLE_ACTION_TYPES.SAVE_SUCCESS,
    payload: {
      result
    }
  }
}

function ARCHIVE(roleId: string, cb: () => void) {
  return {
    type: ROLE_ACTION_TYPES.ARCHIVE,
    payload: {
      cb,
      roleId
    }
  }
}

function ARCHIVE_SUCCESS(roleId: string) {
  return {
    type: ROLE_ACTION_TYPES.ARCHIVE_SUCCESS,
    payload: {
      roleId
    }
  }
}

export const ROLE_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  ARCHIVE,
  ARCHIVE_SUCCESS
}

export const ROLE_ACTION_TYPES = {
  FETCH: '[ROLE] FETCH',
  FETCH_SUCCESS: '[ROLE] FETCH SUCCESS',
  SAVE: '[ROLE] SAVE',
  SAVE_SUCCESS: '[ROLE] SAVE SUCCESS',
  ARCHIVE: '[ROLE] ARCHIVE',
  ARCHIVE_SUCCESS: '[ROLE] ARCHIVE SUCCESS'
}