import { Observable, Subject } from "rxjs";
import { ActionNames } from "../../enums/action-names.enum";
import { PagePaths } from "../../enums/page-paths.enum";

const communicator: Subject<{action: string, data: any}> = new Subject();

export const UtilsService = {

  navTo(path: PagePaths) {
    this.communicate(ActionNames.HISTORY_NAV_TO, path)
  },

  reset(path: PagePaths) {
    this.communicate(ActionNames.HISTORY_RESET, path)
  },
  
  communicate(action: string, data:any = []) {
    communicator.next({
      action: action,
      data: data
    });
  },

  getCommunicate(): Observable<{action: string, data: any}> {
    return communicator.asObservable();
  },

  showToast(message: string, panelClass = "bg-success") {
    this.communicate(ActionNames.SHOW_TOAST, {
      message,
      panelClass
    })
  },

  showErrors(message: string) {
    // this._toast.open('😥 ' + message, 'X', {
    //   duration: 30 * 1000,
    //   panelClass: "bg-danger"
    // });
  },

  showLoading() {
    this.communicate(ActionNames.SHOW_LOADER)
  },

  hideLoading() {
    setTimeout(() => {  
      this.communicate(ActionNames.HIDE_LOADER)
    }, 500);
  },

  copy(str: string) {
    // const el = document.createElement('textarea');
    // el.value = str;
    // document.body.appendChild(el);
    // el.select();
    // document.execCommand('copy');
    // document.body.removeChild(el);

    // this.showToast("Copied");
  }
}