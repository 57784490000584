import { RegistrationRequest } from "../../interfaces/authentication/registration-request.interface"
import { CompanyRoleItem } from "../../interfaces/page-state/dashboard-page-state.interface"
import { LoginInterface } from "../../interfaces/schema/login.interface"
import { PersonInterface } from "../../interfaces/schema/person.interface"
import { ResourceInterface } from "../../interfaces/schema/resource.interface"
import { RoleInterface } from "../../interfaces/schema/role.interface"

function AUTHENTICATION_LOGIN_SUCCESS(payload: any) {
  return {
    type: AUTHENTICATION_ACTION_TYPES.LOGIN_SUCCESS,
    payload
  }
}

function AUTHENTICATION_LOGIN(payload: {username: string, password: string}) {
  return {
    type: AUTHENTICATION_ACTION_TYPES.LOGIN,
    payload
  }
}

function AUTHENTICATION_LOGIN_FAILED() {
  return {
    type: AUTHENTICATION_ACTION_TYPES.LOGIN_FAILED,
    payload: {}
  }
}

function AUTHENTICATION_LOGOUT() {
  return {
    type: AUTHENTICATION_ACTION_TYPES.LOGOUT,
    payload: {}
  }
}

function AUTHENTICATION_LOGOUT_SUCCESS() {
  return {
    type: AUTHENTICATION_ACTION_TYPES.LOGOUT_SUCCESS,
    payload: {}
  }
}

function AUTHENTICATION_INITIAL_APP() {
  return {
    type: AUTHENTICATION_ACTION_TYPES.INITIAL_APP,
    payload: {}
  }
}

function AUTHENTICATION_INITIAL_APP_COMPLETED(payload: any = {}) {
  return {
    type: AUTHENTICATION_ACTION_TYPES.INITIAL_APP_COMPLETED,
    payload
  }
}

function AUTHENTICATION_SIGNUP(payload: RegistrationRequest) {
  return {
    type: AUTHENTICATION_ACTION_TYPES.SIGNUP,
    payload
  }
}

function AUTHENTICATION_SIGNUP_FAILED(error: any) {
  return {
    type: AUTHENTICATION_ACTION_TYPES.SIGNUP_FAILED,
    payload: error
  }
}

function AUTHENTICATION_SHOW_LOADING() {
  return {
    type: AUTHENTICATION_ACTION_TYPES.SHOW_LOADING
  }
}

function AUTHENTICATION_INITIAL_PAGE() {
  return {
    type: AUTHENTICATION_ACTION_TYPES.INITIAL_PAGE
  }
}

function UPDATE_CURRENT_OPERATOR_SUCCESS(operator: PersonInterface) {
  return {
    type: AUTHENTICATION_ACTION_TYPES.UPDATE_CURRENT_OPERATOR_SUCCESS,
    payload: operator
  }
}

function UPDATE_CURRENT_LOGIN_SUCCESS(login: LoginInterface) {
  return {
    type: AUTHENTICATION_ACTION_TYPES.UPDATE_CURRENT_LOGIN_SUCCESS,
    payload: login
  }
}

function CHANGE_ROLE(role: RoleInterface) {
  return {
    type: AUTHENTICATION_ACTION_TYPES.CHANGE_ROLE,
    payload: role
  }
}

function LOAD_RESOURCES_SUCCESS(resources: ResourceInterface) {
  return {
    type: AUTHENTICATION_ACTION_TYPES.LOAD_RESOURCES_SUCCESS,
    payload: resources
  }
}

function APPLY_USER_VIEW(login: LoginInterface) {
  return {
    type: AUTHENTICATION_ACTION_TYPES.APPLY_USER_VIEW,
    payload: login
  }
}

export const AUTHENTICATION_ACTIONS = {
  APPLY_USER_VIEW,
  AUTHENTICATION_INITIAL_PAGE,
  AUTHENTICATION_LOGIN_SUCCESS,
  AUTHENTICATION_SHOW_LOADING,
  AUTHENTICATION_SIGNUP_FAILED,
  AUTHENTICATION_SIGNUP,
  AUTHENTICATION_INITIAL_APP_COMPLETED,
  AUTHENTICATION_INITIAL_APP,
  AUTHENTICATION_LOGOUT_SUCCESS,
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_LOGIN_FAILED,
  AUTHENTICATION_LOGIN,
  UPDATE_CURRENT_OPERATOR_SUCCESS,
  UPDATE_CURRENT_LOGIN_SUCCESS,
  CHANGE_ROLE,
  LOAD_RESOURCES_SUCCESS
}

export const AUTHENTICATION_ACTION_TYPES = {
  APPLY_USER_VIEW: '[AUTHENTICATION] APPLY USER VIEW',
  CHANGE_ROLE: '[AUTHENTICATION] CHANGE ROLE',
  LOAD_RESOURCES_SUCCESS: '[AUTHENTICATION] LOAD RESOURCES SUCCESS',
  SHOW_LOADING: '[AUTHENTICATION] SHOW LOADING',
  SIGNUP: '[AUTHENTICATION] SIGNUP',
  SIGNUP_FAILED: '[AUTHENTICATION] SIGNUP_FAILED',
  LOGIN: '[AUTHENTICATION] LOGIN',
  LOGIN_SUCCESS: '[AUTHENTICATION] LOGIN_SUCCESS',
  LOGIN_FAILED: '[AUTHENTICATION] LOGIN_FAILED',
  LOGOUT: '[AUTHENTICATION] LOGOUT',
  LOGOUT_SUCCESS: '[AUTHENTICATION] LOGOUT_SUCCESS',
  INITIAL_APP: '[AUTHENTICATION] INITIAL_APP',
  INITIAL_APP_COMPLETED: '[AUTHENTICATION] INITIAL_APP_COMPLETED',
  INITIAL_PAGE: '[AUTHENTICATION] INITIAL_PAGE',
  UPDATE_CURRENT_OPERATOR_SUCCESS: '[AUTHENTICATION] UPDATE CURRENT OPERATOR SUCCESS',
  UPDATE_CURRENT_LOGIN_SUCCESS: '[AUTHENTICATION] UPDATE CURRENT LOGIN SUCCESS'
}