
import { RequestService } from "../../services/common/request.service";
import { CompanyRoleItem } from '../../interfaces/page-state/dashboard-page-state.interface';
import { StorageService } from "../../services/common/storage.service";
import { SESSION_ACTION, SESSION_ACTION_TYPES } from "./session.actions";
import { SessionInterface } from "../../interfaces/schema/session.interface";
import { StorageKeys } from "../../enums/storage-keys.enum";
import { PageName } from "../../enums/page-name.enum";
import * as qs from 'qs';
import { UtilsService } from "../../services/common/utils.service";

const {
  FETCH_VIEW_SUCCESS,
  ARCHIVE_SESSION_SUCCESS,
  MODIFY_SESSION_SUCCESS
} = SESSION_ACTION;

export const SessionMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any, projectId: string}) => {
  const _http   = new RequestService();
  const storage = new StorageService();

  if (action.type === SESSION_ACTION_TYPES.FETCH_VIEW) {
    const res = await _http.get('/views/sessions?' + qs.stringify(action.payload))
    return store.dispatch(FETCH_VIEW_SUCCESS(res.response))
  }
  
  else if (action.type === SESSION_ACTION_TYPES.ARCHIVE_SESSION) {
    await _http.delete(`/projects/${action.projectId}/sessions/${action.payload}`);
    return store.dispatch(ARCHIVE_SESSION_SUCCESS(action.payload))
  }
  
  else if (action.type === SESSION_ACTION_TYPES.MODIFY_SESSION) {
    const {response} = await _http.post(`/projects/${action.projectId}/sessions`, {payload: action.payload});
    UtilsService.showToast("Session Updated");
    return store.dispatch(MODIFY_SESSION_SUCCESS(response.result))
  }

  else if (action.type === SESSION_ACTION_TYPES.MODIFY_META_DATA) {
    const {response} = await _http.post(`/projects/${action.projectId}/sessions`, action.payload);
    UtilsService.showToast("Session Updated");
    return store.dispatch(MODIFY_SESSION_SUCCESS(response.result))
  }

  /** Pass */
  else {
    return next(action);
  }
}