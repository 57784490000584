import store from './../store';

export const GradeDataGetter = {
  item: (process_id: string) => 
    store.getState().gradeData.files?.find(file => file.process_id == process_id),

  listAll: () => 
    store.getState().gradeData.files,
    
  count: () => 
    store.getState().gradeData.count,
    
  categories: () => 
    store.getState().gradeData.fileCategories,

  categoryFields: (category: string): string[] =>
    store.getState().gradeData.categoryFields[category] || []
}