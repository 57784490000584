import store from '../store';

export const RoleGetter = {
  item   : (roleId?: string) => 
    store.getState().role.entities?.find(entity => entity._id == roleId),

  listAll: () => 
    store.getState().role.entities,
    
  count  : () => 
    store.getState().role.count,
}