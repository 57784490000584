import React, { useEffect, useState } from 'react';
import store from '../../../store/store';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Button, Icon, FormControl, InputLabel, Menu, MenuItem, Select, TableSortLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { KILL_DATA_ACTIONS } from '../../../store/kill-data/kill-data.actions';
import { KillDataGetter } from '../../../store/kill-data/kill-data.getters';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { KillDataConfigInterface } from '../../../interfaces/schema/kill-data-config.interface';
import { KILL_DATA_CONFIG_ACTION } from '../../../store/kill-data-config/kill-data-config.actions';
import { KillDataConfigGetter } from '../../../store/kill-data-config/kill-data-config.getter';
import { GlobalStyleConstant } from '../../../constants/styles/global.const';
import { UtilsService } from '../../../services/common/utils.service';
import { KillDataViewMode } from '../../../enums/kill-data-view-mode.enum';
import { KillDataNestedRecordInterface } from '../../../interfaces/schema/kill-data-nested-record.interface';

/**
 * Kill Data Page
 * @returns {JSX.Element}
 */
export default function KillDataPage() {
  /**
   * Initial Variables
   */
  const [killDateFrom  , setKillDateFrom]   = useState(moment());
  const [killDateTo    , setKillDateTo  ]   = useState(moment());
  const [records       , setRecords     ]   = useState([] as {initialIndex: number, row: string[]}[]);
  const [headers       , setHeaders     ]   = useState([] as string[]);
  const [order         , setOrder       ]   = useState('asc' as 'asc' | 'desc');
  const [orderBy       , setOrderBy     ]   = useState(0);
  const [configs       , setConfigs     ]   = useState([] as KillDataConfigInterface[])
  const [highlightedRow, setHighlightedRow] = useState([] as number[]);
  const [selectedConfig, setSelectedConfig] = useState(null as string | null);
  const [exportAnchorEl, setExportAnchorEl] = useState(null as HTMLButtonElement | null);
  const [summary       , setSummary     ]   = useState([] as {source: string, count: number}[]);
  const [selectedView  , setSelectedView]   = useState(KillDataViewMode.NEST as KillDataViewMode);
  const [nestedRecords , setNestedRecords]  = useState([] as KillDataNestedRecordInterface[]);
  const [expandedRow   , setExpandedRow ]   = useState(-1 as number);
  const [nestedTables  , setNestedTables]   = useState([] as {headers: string[], data: string[][], title: string}[])

  /**
   * Initial Listener
   */
  useEffect(() => {
    store.dispatch(KILL_DATA_CONFIG_ACTION.FETCH_AVAILABLE())

    const unsubscribe = store.subscribe(() => {
      setConfigs(KillDataConfigGetter.listAll().filter(item => !item.parent_config));
      setRecords(KillDataGetter.listAllRecords().map((row, rowIndex) => {
        return {
          initialIndex: rowIndex,
          row
        }
      }));
      setHeaders(KillDataGetter.listAllHeaders());
      setSummary(KillDataGetter.summary())
      setNestedRecords(KillDataGetter.listAllNestedRecords());
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Search data by date
   * 
   * @param options 
   */
  const search = (options?: {
    format?: string
  }) => {
    if (!canSearch()) return;

    store.dispatch(KILL_DATA_ACTIONS.FETCH({
      kill_date_from: killDateFrom.format("YYYYMMDD"),
      kill_date_to  : killDateTo.format("YYYYMMDD"),
      config_id     : selectedConfig as string,
      format        : options?.format || "",
      view          : selectedView,
      order,
      orderBy
    }))
  }

  /**
   * Determine input valid
   */
  const canSearch = () => {
    return !!(selectedConfig && killDateFrom && killDateTo)
  }

  /**
   * Can a row expand
   */
  const canExpend = (rowIndex:number, colIndex: number) => {
    return colIndex == 0
      && selectedView == KillDataViewMode.NEST 
      && nestedRecords.some(record => record.data_map.some((row: any) => row.parent_row_index == rowIndex))
  }

  /**
   * Get Summary
   */
  const fetchSummary = () => {
    store.dispatch(KILL_DATA_ACTIONS.FETCH_SUMMARY({
      kill_date_from: killDateFrom.format("YYYYMMDD"),
      kill_date_to  : killDateTo.format("YYYYMMDD"),
      config_id     : selectedConfig as string
    }))
  }

  /**
   * Flush Datah
   */
  const flushCache = () => {
    store.dispatch(KILL_DATA_ACTIONS.FLUSH_CACHE({
      kill_date_from: killDateFrom.format("YYYYMMDD"),
      kill_date_to  : killDateTo.format("YYYYMMDD")
    }))
  }

  /**
   * Determine parent config contains child
   * 
   * @param configId 
   * @returns 
   */
  const hasAnyChild = (configId: string) => {
    return !!KillDataConfigGetter.listAll().filter(item => item.parent_config == configId).length
  }

  /**
   * Find preset config
   */
  const fetchPresetKillDate = (configId: string) => {
    const config = KillDataConfigGetter.item(configId);
    if (config?.kill_date_range?.from) {
      setKillDateFrom(moment(config?.kill_date_range?.from, "YYYYMMDD", true));
    }
    if (config?.kill_date_range?.to) {
      setKillDateTo(moment(config?.kill_date_range?.to, "YYYYMMDD", true));
    }
  }

  /**
   * Expand Row
   * 
   * @param rowIndex 
   */
  const expandRow = (rowIndex: number) => {
    setExpandedRow(rowIndex == expandedRow ? -1 : rowIndex);
    const data = nestedRecords.map(nestedRecord => {
      const title = nestedRecord.config_name;
      const headers = nestedRecord.headers;
      const data = nestedRecord.data_map.find(item => item.parent_row_index == rowIndex)?.rows || []
      return {
        title,
        headers,
        data
      }
    });
    setNestedTables(data);
  }

  return (
    <div>
      <div className="d-flex">
        <span className="h3">Kill Date Stats</span>
        <div className="ms-auto">

          <FormControl className="me-3" required style={{
            minWidth: GlobalStyleConstant.textFieldWidthMd
          }}>
            <InputLabel>Data Spec</InputLabel>
            <Select
              required
              value={selectedConfig}
              onChange={(evt) => {
                setSelectedConfig(evt.target.value as string);
                fetchPresetKillDate(evt.target.value as string);
              }}
            >
              {
                configs.map((config, configIndex) => (
                  <MenuItem key={configIndex} value={config._id}>{config.config_name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>

          {
            selectedConfig && hasAnyChild(selectedConfig) && <FormControl className="me-3" required style={{
              minWidth: GlobalStyleConstant.textFieldWidthMd
            }}>
              <InputLabel>View Mode</InputLabel>
              <Select
                value={selectedView}
                onChange={(evt) => {
                  setSelectedView(evt.target.value as KillDataViewMode);
                }}
              >
                <MenuItem value={KillDataViewMode.NEST}>{KillDataViewMode.NEST}</MenuItem>
                <MenuItem value={KillDataViewMode.FLAT}>{KillDataViewMode.FLAT}</MenuItem>
              </Select>
            </FormControl>
          }

          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                variant="inline"
                required
                format="DD MMM YYYY"
                label="Kill Date From"
                value={killDateFrom}
                onChange={(evt) => {
                  setKillDateFrom(evt as moment.Moment)
                }}
              />
              <KeyboardDatePicker
                className="ms-3"
                variant="inline"
                required
                format="DD MMM YYYY"
                label="Kill Date To"
                value={killDateTo}
                onChange={(evt) => {
                  setKillDateTo(evt as moment.Moment)
                }}
              />
              <div className="d-inline-block py-2 mt-1">
                <Button variant="contained" className="ms-3" color="primary" disabled={!canSearch()} onClick={() => search()}>
                  Search
                </Button>
                <Button variant="contained" className="ms-3" color="primary" disabled={!canSearch()} onClick={(e) => setExportAnchorEl(e.target as HTMLButtonElement)}>
                  Export
                  <ExpandMoreIcon />
                </Button>
                <Menu
                  anchorEl={exportAnchorEl}
                  keepMounted
                  open={Boolean(exportAnchorEl)}
                  onClose={() => {
                    setExportAnchorEl(null);
                  }}
                >
                  <MenuItem onClick={() => {
                    setExportAnchorEl(null);
                    fetchSummary();
                  }}>Summary</MenuItem>
                  <MenuItem onClick={() => {
                    setExportAnchorEl(null);
                    search({format: "csv"});
                  }}>CSV</MenuItem>
                  <MenuItem onClick={() => {
                    setExportAnchorEl(null);
                    search({format: "json"});
                  }}>JSON</MenuItem>
                  <MenuItem onClick={() => {
                    setExportAnchorEl(null);
                    UtilsService.showToast("501 Not Implemented Yet", "bg-danger")
                  }}>Data Spec</MenuItem>
                </Menu>
              </div>
            </MuiPickersUtilsProvider>
          </div>
        </div>
        {
          !!summary.length
          &&
          <div className="w-100 overflow-auto my-3">
            <table className="border">
              <thead>
                <tr className="text-white bg-dark">
                  {
                    summary.map((item, index) => (
                      <th key={index} className="px-2 py-1">{item.source}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white">
                  {
                    summary.map((item, index) => (
                      <th key={index} className="px-2 py-1">{item.count}</th>
                    ))
                  }
                </tr>
              </tbody>
            </table>
          </div>
        }
        { 
          !!headers.length 
            && 
          !!records.length
            &&
          <div className="w-100 overflow-auto">
          <TableContainer style={{
            maxHeight: 700
          }} component={Paper}>
            <Table stickyHeader className="w-100 table table-striped" size="small">
              <TableHead>
                <TableRow>
                  {
                    headers.map((header: string, keyIndex: number) => (
                      <TableCell style={{backgroundColor: "black", color: "white"}} key={keyIndex}>
                        {/* {header} */}
                        
                        <TableSortLabel
                          active={orderBy === keyIndex}
                          direction={orderBy === keyIndex ? order : 'asc'}
                          onClick={() => {
                            setOrderBy(keyIndex);
                            setOrder(order == 'desc' ? 'asc' : 'desc')
                            setRecords(records.sort((a, b) => {
                              let aValue = a.row[keyIndex] as any;
                              let bValue = b.row[keyIndex] as any;
                              if (!isNaN(Number(aValue))) aValue = Number(aValue);
                              if (!isNaN(Number(bValue))) bValue = Number(bValue);
                              const sorted = aValue > bValue ? -1 : 1;
                              return sorted * (order == 'desc' ? -1 : 1);
                            }))
                          }}
                        >
                          {header}
                          {orderBy === keyIndex ? (
                            <span style={{
                              border: 0,
                              clip: 'rect(0 0 0 0)',
                              height: 1,
                              margin: -1,
                              overflow: 'hidden',
                              padding: 0,
                              position: 'absolute',
                              top: 20,
                              width: 1
                            }}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map((row: {initialIndex: number, row: string[]}) => (
                  <React.Fragment key={row.initialIndex} >
                    <TableRow 
                      onClick={() => {
                        const index = highlightedRow.findIndex(i => i == row.initialIndex);
                        if (index == -1) 
                          setHighlightedRow([
                            ...highlightedRow,
                            row.initialIndex
                          ])
                        else {
                          setHighlightedRow(
                            highlightedRow.filter(i => i != row.initialIndex)
                          )
                        }
                      }}
                      className={highlightedRow.includes(row.initialIndex) ? 'bg-warning' : ''}
                    > 
                      {
                        row.row.map((col: string, colIndex: number) => (
                          <TableCell key={colIndex}>
                            {
                              canExpend(row.initialIndex, colIndex) && 
                              <Icon 
                                onClick={() => expandRow(row.initialIndex)}
                                className="vertical-inherit pointer me-2" 
                                color="primary"
                              >
                                {row.initialIndex == expandedRow ? 'indeterminate_check_box' : 'add_box'}
                              </Icon> 
                            }
                            <span style={{whiteSpace: "nowrap"}}>{col}</span>
                          </TableCell>
                        ))
                      }
                    </TableRow>
                    {
                      row.initialIndex == expandedRow &&
                      nestedTables.length &&
                      selectedView == KillDataViewMode.NEST &&
                      <TableRow>
                        <TableCell colSpan={row.row.length}>
                          {
                            nestedTables.filter(nestedTable => {
                              return nestedTable.data.length
                            }).map((nestedTable, tableIndex) => (
                              <div key={tableIndex} className="py-2">
                                <h6 style={{
                                  fontWeight: 600
                                }}>{nestedTable.title}</h6>
                                <TableContainer>
                                  <Table className="shadow rounded-lg borders" style={{
                                    width: 'unset'
                                  }}>
                                    <TableHead>
                                      {
                                        nestedTable.headers.map((header, headerIndex) => (
                                          <TableCell className="py-1" key={headerIndex}>{header}</TableCell>
                                        ))
                                      }
                                    </TableHead>
                                    <TableBody>
                                      {
                                        nestedTable.data.map((row, rowIndex) => (
                                          <TableRow key={rowIndex}>
                                            {
                                              row.map((cell, cellIndex) => (
                                                <TableCell className="py-1" key={cellIndex}>{cell}</TableCell>
                                              ))
                                            }
                                          </TableRow>
                                        ))
                                      }
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            ))
                          }
                        </TableCell>
                      </TableRow>
                    }
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
    </div>
  );
}
