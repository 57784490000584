import { CompanyPositionInterface } from "../../interfaces/schema/company-position.interface";
import { RequestService } from "../../services/common/request.service";
import { UtilsService } from "../../services/common/utils.service";
import { CompanyPositionService } from "../../services/store/company-position.service";
import { COMPANY_POSITION_ACTION, COMPANY_POSITION_ACTION_TYPES } from "./company-position.actions";

const {
  FETCH_SUCCESS,
  SAVE_SUCCESS,
  ARCHIVE_SUCCESS
} = COMPANY_POSITION_ACTION;

export const CompanyPositionMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any, projectId: string}) => {
  const _http   = new RequestService();
  /** 
   * Fetch company-positions
   */
  if (action.type === COMPANY_POSITION_ACTION_TYPES.FETCH) {
    try {
      const {results, count} = await CompanyPositionService.fetch();
      return store.dispatch(FETCH_SUCCESS(results, count));
    }  
    catch (error) {
      console.error(COMPANY_POSITION_ACTION_TYPES.FETCH, error);
      return ;
    }
  }
  /** 
   * Save company-position
   */
  else if (action.type === COMPANY_POSITION_ACTION_TYPES.SAVE) {
    try {
      const newCompanyPosition: CompanyPositionInterface = await CompanyPositionService.save(action.payload.payload);
      UtilsService.showToast("Saved");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(SAVE_SUCCESS(newCompanyPosition))
    } catch (error) {
      return console.error(COMPANY_POSITION_ACTION_TYPES.SAVE, error);
    }
  }
  /**
   * Archive company-position
   */
  else if (action.type === COMPANY_POSITION_ACTION_TYPES.ARCHIVE) {
    try {
      await CompanyPositionService.archive(action.payload.positionId);
      UtilsService.showToast("Archived");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(ARCHIVE_SUCCESS(action.payload.positionId))
    } catch (error) {
      return console.error(COMPANY_POSITION_ACTION_TYPES.ARCHIVE, error);
    }
  }
  else {
    return next(action);
  }
}