import { GRADE_DATA_ACTIONS, GRADE_DATA_ACTION_TYPES } from './grade-data.actions';
import { RequestService } from "../../services/common/request.service";
import * as qs from 'qs';


export const GradeDataMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any}) => {
  const _http = new RequestService();
  
  /** Fetch project by company and role */
  if (action.type === GRADE_DATA_ACTION_TYPES.UPLOAD) {
    try {
      await _http.postFile('/data/grade-data/upload', action.payload.file);
      if (action.payload.cb)
        action.payload.cb();
    } catch (error) {
      console.error(GRADE_DATA_ACTION_TYPES.UPLOAD, error);
      return ;
    }
  }
  else if (action.type === GRADE_DATA_ACTION_TYPES.FETCH) {
    try {
      const { response } = await _http.get(`/data/grade-data?${qs.stringify(action.payload.pagination || {})}`);
      if (action.payload.cb)
        action.payload.cb();
      return store.dispatch(GRADE_DATA_ACTIONS.FETCH_SUCCESS(response.results, response.count));
    } catch (error) {
      console.error(GRADE_DATA_ACTION_TYPES.FETCH, error);
      return ;
    }
  }
  else if (action.type === GRADE_DATA_ACTION_TYPES.ARCHIVE) {
    try {
      await _http.delete('/data/grade-data/' + action.payload.process_id);
      if (action.payload.cb)
        action.payload.cb();
      return store.dispatch(GRADE_DATA_ACTIONS.ARCHIVE_SUCCESS(action.payload.process_id));
    } catch (error) {
      console.error(GRADE_DATA_ACTION_TYPES.ARCHIVE, error);
      return ;
    }
  }
  else if (action.type === GRADE_DATA_ACTION_TYPES.UPDATE) {
    try {
      const {response} = await _http.patch('/data/grade-data/' + action.payload.process_id, {file: action.payload.file});
      if (action.payload.cb)
        action.payload.cb();
      return store.dispatch(GRADE_DATA_ACTIONS.UPDATE_SUCCESS(response.result));
    } catch (error) {
      console.error(GRADE_DATA_ACTION_TYPES.UPDATE, error);
      return ;
    }
  }
  else if (action.type === GRADE_DATA_ACTION_TYPES.FETCH_CATEGORIES) {
    try {
      const {response} = await _http.get('/data/grade-data/categories');
      return store.dispatch(GRADE_DATA_ACTIONS.FETCH_CATEGORIES_SUCCESS(response));
    } catch (error) {
      console.error(GRADE_DATA_ACTION_TYPES.FETCH_CATEGORIES, error);
      return ;
    }
  }
  else if (action.type === GRADE_DATA_ACTION_TYPES.FETCH_CATEGORY_FIELDS) {
    try {
      const {response} = await _http.post('/data/grade-data/categories/get-fields', {category: action.payload.category});
      return store.dispatch(GRADE_DATA_ACTIONS.FETCH_CATEGORY_FIELDS_SUCCESS(action.payload.category, response));
    } catch (error) {
      console.error(GRADE_DATA_ACTION_TYPES.FETCH_CATEGORY_FIELDS, error);
      return ;
    }
  }
  else {
    return next(action);
  }
}