import { RequestService } from "../../services/common/request.service";
import { CompanyRoleItem } from '../../interfaces/page-state/dashboard-page-state.interface';
import * as qs from 'qs';
import { StorageService } from "../../services/common/storage.service";
import { UtilsService } from "../../services/common/utils.service";
import { ROLE_ACTION, ROLE_ACTION_TYPES } from "./role.actions";
import { RoleService } from "../../services/store/role.service";
import { RoleInterface } from "../../interfaces/schema/role.interface";

const {
  FETCH_SUCCESS,
  SAVE_SUCCESS,
  ARCHIVE_SUCCESS
} = ROLE_ACTION;

export const RoleMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any, projectId: string}) => {
  const _http   = new RequestService();
  /** 
   * Fetch roles
   */
  if (action.type === ROLE_ACTION_TYPES.FETCH) {
    try {
      const {results, count} = await RoleService.fetch();
      return store.dispatch(FETCH_SUCCESS(results, count));
    }  
    catch (error) {
      console.error(ROLE_ACTION_TYPES.FETCH, error);
      return ;
    }
  }
  /** 
   * Save role
   */
  else if (action.type === ROLE_ACTION_TYPES.SAVE) {
    try {
      const newRole: RoleInterface = await RoleService.save(action.payload.payload);
      UtilsService.showToast("Saved");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(SAVE_SUCCESS(newRole))
    } catch (error) {
      return console.error(ROLE_ACTION_TYPES.SAVE, error);
    }
  }
  /**
   * Archive Role
   */
  else if (action.type === ROLE_ACTION_TYPES.ARCHIVE) {
    try {
      await RoleService.archive(action.payload.roleId);
      UtilsService.showToast("Archived");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(ARCHIVE_SUCCESS(action.payload.roleId))
    } catch (error) {
      return console.error(ROLE_ACTION_TYPES.ARCHIVE, error);
    }
  }
  else {
    return next(action);
  }
}