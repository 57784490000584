
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import store from '../../../../store/store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { cloneDeep } from 'lodash';
import { UtilsService } from '../../../../services/common/utils.service';
import { GlobalStyleConstant } from '../../../../constants/styles/global.const';
import { ResourceInterface } from '../../../../interfaces/schema/resource.interface';
import { ResourceGetter } from '../../../../store/resource/resource.getter';
import { RESOURCE_ACTION } from '../../../../store/resource/resource.actions';

/**
 * Resources Page
 * @returns {JSX.Element}
 */
export default function ResourcesPage() {

  const [resources, setResources] = useState([] as ResourceInterface[])
  const [showDialog, setShowDialog] = useState(false)
  const [editingItem, setEditingItem] = useState({} as ResourceInterface)

  useEffect(() => {
    store.dispatch(RESOURCE_ACTION.FETCH());
    const unsubscribe = store.subscribe(() => {
      setResources(ResourceGetter.listAll())
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Pop up edit dialog
   * 
   * @param resource 
   */
  const editResource = (resource?: ResourceInterface) => {
    setEditingItem(cloneDeep(resource || {}))
    setShowDialog(true);
  }

  const save = () => {
    store.dispatch(RESOURCE_ACTION.SAVE(editingItem, () => {
      setShowDialog(false);
      UtilsService.showToast("Saved");
    }));
  }

  return (
    <div>
      <div className="d-flex mb-3">
        <span className="h3">Resources</span>
        <Button variant="contained" color="primary" className="ms-auto" onClick={() => editResource()}>Create Resource</Button>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Resource Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell component="th" scope="row">
                    {row.resource_name}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Config">
                      <IconButton onClick={() => editResource(row)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="lg">
        <DialogTitle className="pb-0">{editingItem._id ? "Edit" : "Create"} Resource</DialogTitle>
        <DialogContent style={{width: GlobalStyleConstant.dialogMd}}>
          <TextField
            autoFocus
            margin="dense"
            label="Role Name"
            value = {editingItem.resource_name}
            onChange={(e) => {
              setEditingItem({
                ...editingItem,
                resource_name: e.target.value
              })
            }}
            fullWidth
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={editingItem.has_view}
                onChange={(e) => {
                  setEditingItem({
                    ...editingItem,
                    has_view: e.target.checked
                  })
                }}
                color="primary"
              />
            }
            label="Has View"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={editingItem.has_create}
                onChange={(e) => {
                  setEditingItem({
                    ...editingItem,
                    has_create: e.target.checked
                  })
                }}
                color="primary"
              />
            }
            label="Has Create"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={editingItem.has_edit}
                onChange={(e) => {
                  setEditingItem({
                    ...editingItem,
                    has_edit: e.target.checked
                  })
                }}
                color="primary"
              />
            }
            label="Has Edit"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={editingItem.has_delete}
                onChange={(e) => {
                  setEditingItem({
                    ...editingItem,
                    has_delete: e.target.checked
                  })
                }}
                color="primary"
              />
            }
            label="Has Delete"
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => save()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
