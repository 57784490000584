import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import store from '../../../store/store';
import { useHistory } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { GlobalConfigInterface } from '../../../interfaces/schema/global-config.interface';
import { GLOBAL_CONFIG_ACTION } from '../../../store/global-config/global-config.actions';
import { GlobalConfigGetter } from '../../../store/global-config/global-config.getters';
import EditIcon from '@material-ui/icons/Edit';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { cloneDeep } from 'lodash';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import { UtilsService } from '../../../services/common/utils.service';

/**
 * Global Configs Page
 * @returns {JSX.Element}
 */
export default function GlobalConfigPage() {

  const [configs, setConfigs] = useState([] as GlobalConfigInterface[])
  const [showDialog, setShowDialog] = useState(false)
  const [editingItem, setEditingItem] = useState({} as GlobalConfigInterface)
  const [globalConfigBody, setGlobalConfigBody] = useState("{}")

  useEffect(() => {
    store.dispatch(GLOBAL_CONFIG_ACTION.FETCH());
    const unsubscribe = store.subscribe(() => {
      setConfigs(GlobalConfigGetter.listAll())
    })

    return () => {
      unsubscribe();
    }
  }, [])

  const editConfig = (config?: GlobalConfigInterface) => {
    setEditingItem(cloneDeep(config ? config : {}))
    setGlobalConfigBody("{}")

    setShowDialog(true);
    if (config?.config_name) {
      store.dispatch(GLOBAL_CONFIG_ACTION.FETCH_ITEM(config.config_name, (config: GlobalConfigInterface) => {
        setEditingItem(config)
        setGlobalConfigBody(JSON.stringify(config.config_data || {}, null, 2))
      }));
    }
  }

  const save = () => {
    try {
      const newPayload = {
        ...editingItem,
        config_data: JSON.parse(globalConfigBody)
      };
      setEditingItem(newPayload)
      store.dispatch(GLOBAL_CONFIG_ACTION.SAVE(newPayload, () => {
        setShowDialog(false);
        UtilsService.showToast("Saved");
      }));
    } catch (error) {
      UtilsService.showToast(error.toString(), "bg-danger");
    }
  }

  return (
    <div>
      <div className="d-flex mb-3">
        <span className="h3">Global Config</span>
        <Button variant="contained" color="primary" className="ms-auto" onClick={() => editConfig()}>Create</Button>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Config Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configs.map((row) => (
                <TableRow key={row.serial_number}>
                  <TableCell component="th" scope="row">
                    {row.serial_number}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.config_name}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Config">
                      <IconButton onClick={() => editConfig(row)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle className="pb-0">{editingItem._id ? "Edit" : "Create"} Global Config</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Config Name"
            value = {editingItem.config_name}
            fullWidth
          />
          <AceEditor
            mode = "json"
            theme = "github"
            onChange = {(e) => {
              setGlobalConfigBody(e)
            }}
            value = {globalConfigBody}
          />,
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => save()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
