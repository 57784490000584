import { Button, IconButton, Paper, TablePagination, Tooltip, Icon, FormControlLabel, Switch, TextField, Chip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import store from '../../../store/store';
import { Pagination } from '../../../interfaces/common/pagination.interface';
import { UtilsService } from '../../../services/common/utils.service';
import ConfirmDialog from '../../../dialogs/common/confirm.dialog';
import { GRADE_DATA_ACTIONS } from '../../../store/grade-data/grade-data.actions';
import { ImportedFileInterface } from '../../../interfaces/schema/imported-file.interface';
import { GradeDataGetter } from '../../../store/grade-data/grade-data.getters';
import { PersonInterface } from '../../../interfaces/schema/person.interface';
import { cloneDeep } from 'lodash'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeclarationComponent from '../../../components/common/declaration.component';
import { ProjectInterface } from '../../../interfaces/schema/project.interface';
import { ProjectGetter } from '../../../store/project/project.getters';
import { PROJECT_ACTION } from '../../../store/project/project.actions';
import Autocomplete from '@material-ui/lab/Autocomplete';

function EditFileCategoryDialog(props: {
  show    : boolean,
  confirm : () => void,
  projects: ProjectInterface[],
  file    : ImportedFileInterface,
  setShow : (show: boolean) => void,
  setFile : (file: ImportedFileInterface) => void,
}) {

  const [checked, setChecked] = useState(false as boolean)

  return (
    <React.Fragment>
      <Dialog
        open={props.show}
        onClose={() => props.setShow(false)}
      >
        <DialogTitle>
          Edit File Meta Data
        </DialogTitle>
        <DialogContent>
          
          <TextField
            className="w-100 mb-2"
            label="File Category"
            required
            value={props.file.file_category}
            onChange={(evt) => {
              props.setFile({
                ...props.file,
                file_category: evt.target.value
              })
            }} />

          <Autocomplete
            fullWidth
            multiple
            className         = "mb-4"
            renderInput       = {(params) => <TextField {...params} label="Involved Projects" />}
            options           = {props.projects.filter(project => !props.file.projects?.some(item => item._id == project._id))}
            getOptionLabel    = {(option: ProjectInterface) => option.project_name || ""}
            value             = {props.file.projects || []}
            onChange          = {(event: any, newValue: ProjectInterface[]) => {
              props.setFile({
                ...props.file,
                projects: newValue
              })
            }}
          />
          <DeclarationComponent checked={checked} setChecked={setChecked}></DeclarationComponent>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => props.setShow(false)} color="primary">
            <b>Cancel</b>
          </Button>
          <Button onClick={props.confirm} color="secondary" disabled={!checked}>
            <b>Confirm</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

/**
 * Imported Files Page
 * @returns {JSX.Element}
 */
 export default function ImportedFilesPage() {
  /** Initial Variables */
  const [showEditDialog, setShowEditDialog] = useState(false as boolean)
  const [editingItem, setEditingItem] = useState({} as ImportedFileInterface)
  const [count, setCount] = useState(0 as number)
  const [files, setFiles] = useState([] as ImportedFileInterface[])
  const [showConfirm, setShowConfirm] = useState(false as boolean)
  const [archiveFile, setArchiveFile] = useState({} as ImportedFileInterface)
  const [projects, setProjects] = useState([] as ProjectInterface[])
  const [pagination, setPagination] = useState({
    index: 0,
    size: 25,
    archive_mode: false
  } as Pagination)

  /**
   * Setup Listener
   */
  useEffect(() => {
    store.dispatch(GRADE_DATA_ACTIONS.FETCH(pagination))
    store.dispatch(PROJECT_ACTION.FETCH())

    const unsubscribe = store.subscribe(() => {
      setFiles(GradeDataGetter.listAll());
      setCount(GradeDataGetter.count());
      setProjects(ProjectGetter.listAll());
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Archive file
   */
  const archive = () => {
    archiveFile.process_id && store.dispatch(GRADE_DATA_ACTIONS.ARCHIVE(archiveFile.process_id, () => {
      UtilsService.showToast("Archived");
      setShowConfirm(false);
    }))
  }

  /**
   * Show Confirm Dialog
   * @param file 
   */
  const confirmArchiveFIle = (file: ImportedFileInterface) => {
    setArchiveFile(file);
    setShowConfirm(true);
  }


  /**
   * Upload File
   * 
   * @param evt 
   */
  const uploadGradeData = (evt: Event) => {
    const el    = evt.target as HTMLInputElement;
    const files = el.files;
    if (files?.item(0)) {
      store.dispatch(GRADE_DATA_ACTIONS.UPLOAD(files.item(0), () => {
        UtilsService.showToast("Uploaded")
      }))
    }
  }

  /**
   * Toggle Archive Mode
   */
  const toggleArchiveMode = () => {
    const newPagination = cloneDeep(pagination) as Pagination;
    newPagination.archive_mode = !newPagination.archive_mode;

    updatePagination(newPagination);
  }

  /**
   * Update Pagination and Fetch Again
   */
  const updatePagination = (newPagination: Pagination) => {
    setPagination(newPagination);
    store.dispatch(GRADE_DATA_ACTIONS.FETCH(newPagination))
  }

  /**
   * Update file category
   */
  const updateFile = () => {
    store.dispatch(GRADE_DATA_ACTIONS.UPDATE(
      editingItem.process_id as string,
      {
        file_category: editingItem.file_category,
        projects     : editingItem.projects || []
      },
      () => {
        setShowEditDialog(false);
        UtilsService.showToast("Saved")
      }
    ))
  }

  return (
    <div>
      <input type="file" className="d-none" id="data_uploader" accept=".csv,.xml" onChange={uploadGradeData as any} />
      <EditFileCategoryDialog
        show={showEditDialog}
        setShow={setShowEditDialog}
        file={editingItem}
        confirm={updateFile}
        projects={projects}
        setFile={setEditingItem}
      ></EditFileCategoryDialog>
      <ConfirmDialog
        show={showConfirm}
        setShow={setShowConfirm}
        message={`Are you sure you want to archive ${archiveFile.source_file}?`}
        confirm={archive}
      ></ConfirmDialog>
      <div className="d-flex">
        <span className="h3">Imported Files</span>

        <FormControlLabel
            className="ms-auto"
            label="Current Only"
            control={<Switch
              color="secondary"
              checked={!pagination.archive_mode}
              onChange={toggleArchiveMode}
            />}
          />
        <Button className="ms-2" variant="contained" color="primary" onClick={() => {
          document.getElementById("data_uploader")?.click()
        }}>
          Import CSV / XML File
        </Button>
      </div>
      <div className="mt-4">
        <TableContainer component={Paper}>
            <Table className="w-100" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>File Name</TableCell>
                  <TableCell>File Category</TableCell>
                  <TableCell>Projects</TableCell>
                  <TableCell>Record Count</TableCell>
                  <TableCell>Uploaded By</TableCell>
                  <TableCell>Uploaded At</TableCell>
                  <TableCell>Is Current</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  files.map((file, fileIndex) => (
                    <TableRow key={fileIndex}>
                      <TableCell>
                        {file.source_file}
                      </TableCell>
                      <TableCell>
                        {file.file_category}
                      </TableCell>
                      <TableCell >
                        {
                          file.projects?.map((project, projectIndex) => {
                            return <Chip className="me-1" key={projectIndex} label={project.project_name}/>
                          })
                        }
                      </TableCell>
                      <TableCell>
                        {file.record_count}
                      </TableCell>
                      <TableCell>
                        {(file.operator as PersonInterface)?.person_first_name || ""}
                        &nbsp;
                        {(file.operator as PersonInterface)?.person_last_name || ""}
                      </TableCell>
                      <TableCell>
                        {file.created_at}
                      </TableCell>
                      <TableCell>
                        {file.is_active ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton size="small" color="primary" onClick={() => {
                          setShowEditDialog(true);
                          setEditingItem(cloneDeep(file))
                        }}>
                          <Icon>create</Icon>
                        </IconButton>
                        <IconButton size="small" color="secondary" onClick={() => confirmArchiveFIle(file)}>
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    )
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={count}
            page={pagination.index || 0}
            onPageChange={(evt, page) => {
              const newPagination = cloneDeep(pagination);
              newPagination.index = page;
              updatePagination(newPagination);
            }}
            onRowsPerPageChange={(evt)=> {
              const newPagination = cloneDeep(pagination);
              newPagination.size = Number(evt.target.value);
              updatePagination(newPagination);
            }}
            rowsPerPage={pagination.size || 25}
          />
      </div>
    </div>
  )
 }