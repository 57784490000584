import { ProjectInterface } from "../../interfaces/schema/project.interface";
import { PROJECT_ACTION_TYPES } from "./project.actions";


export interface ProjectStateInterface {
  entities: ProjectInterface[],
  count   : number
}

const initialState: ProjectStateInterface = {
  entities: [],
  count   : 0
}

export function ProjectReducer(
  state: ProjectStateInterface = initialState, 
  action: {type: string, payload?: any}) 
{
  if (action.type === PROJECT_ACTION_TYPES.FETCH_SUCCESS) {
    state.entities = action.payload?.results || [];
    state.count    = action.payload?.count   || 0;
  }

  else if (action.type === PROJECT_ACTION_TYPES.SAVE_SUCCESS) {
    const entityIndex = state.entities.findIndex(entity => entity._id ==action.payload?.result?._id);
    if (entityIndex == -1) {
      state.count += 1;
      state.entities.push(action.payload?.result)
    } else {
      state.entities[entityIndex] = action.payload?.result;
    }
  }

  return state;
}