import { RegistrationRequest } from "../../interfaces/authentication/registration-request.interface";
import { CompanyRoleItem } from "../../interfaces/page-state/dashboard-page-state.interface";
import { LoginInterface } from "../../interfaces/schema/login.interface";
import { PersonInterface } from "../../interfaces/schema/person.interface";
import { RequestService, StorageService, UtilsService } from "../../services/common/common.module";
import { AUTHENTICATION_ACTIONS, AUTHENTICATION_ACTION_TYPES } from "./authentication.actions";

const { 
  LOAD_RESOURCES_SUCCESS,
  AUTHENTICATION_LOGIN_SUCCESS, 
  AUTHENTICATION_LOGIN_FAILED, 
  AUTHENTICATION_LOGOUT_SUCCESS, 
  AUTHENTICATION_INITIAL_APP_COMPLETED, 
  AUTHENTICATION_LOGOUT, 
  AUTHENTICATION_SIGNUP_FAILED, 
  AUTHENTICATION_LOGIN, 
  AUTHENTICATION_SHOW_LOADING,
  APPLY_USER_VIEW
} = AUTHENTICATION_ACTIONS;

export const AuthenticationMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any, request: boolean}) => {
  const _http  = new RequestService();
  console.info(action.type);

  // Verify Token
  if (action.request) {
    const _http   = new RequestService();
    const storage = new StorageService();
    try {
      await _http.checkToken();
    } catch (error) {
      UtilsService.showToast("Token expired, please login again.", "bg-danger")
      await storage.clear();
      return store.dispatch(AUTHENTICATION_LOGOUT_SUCCESS());
    }
  }

  /** Update operator information in storage */
  if (action.type === AUTHENTICATION_ACTION_TYPES.UPDATE_CURRENT_OPERATOR_SUCCESS) {
    const storage = new StorageService();
    const operators: PersonInterface[] = await storage.get('operators');
    const selectedOperatorIndex = operators.findIndex(operator => operator._id == action.payload._id);
    if (selectedOperatorIndex >= 0) {
      operators[selectedOperatorIndex] = action.payload;
      await storage.set('operators', operators);
    }
    await storage.set('operator', action.payload);
    return next(action);
  }

  else if (action.type === AUTHENTICATION_ACTION_TYPES.UPDATE_CURRENT_LOGIN_SUCCESS) {
    const storage = new StorageService();
    await storage.set('login', action.payload);
    return next(action);
  }

  else if (action.type === AUTHENTICATION_ACTION_TYPES.LOGIN) {
    store.dispatch(AUTHENTICATION_SHOW_LOADING());
    try {
      const res = await _http.post('/auth/login', {
        username        : action.payload.username, 
        password        : action.payload.password,
        client_type     : 'dashboard'
      }, {
        noToken         : true,
        baseUrl         : process.env.REACT_APP_API_URL,
        doNotHandleError: true
      }).then(res => res.response)

      if (!res.login?.roles?.length) 
        throw "The login has no role attached with it."
      
        UtilsService.showToast("Welcome back", "bg-success")

      /** Store credential */
      const storage = new StorageService();
      await storage.set('token', res.tokens.token);
      await storage.set('refreshToken', res.tokens.refreshToken);
      await storage.set('login', res.login);
      await storage.set('operator', res.operators[0]);
      await storage.set('operators', res.operators);
      await storage.set('role', res.defaultRole);
      await storage.set('roles', res.login?.roles);
      await storage.set('resources', res.resources || []);
      
      return store.dispatch(AUTHENTICATION_LOGIN_SUCCESS(res));
    } catch (error: any) {
      console.error("[Authentication] Login Failed: ", error);
      UtilsService.showToast(error?.toString() || "Unknown Error", "bg-danger")
      return next(AUTHENTICATION_LOGIN_FAILED());
    } finally {
      UtilsService.hideLoading();
    }
  }
  else if (action.type === AUTHENTICATION_ACTION_TYPES.APPLY_USER_VIEW) {
    try {
      const res = await _http.post('/auth/apply-user-view', {
        username    : (action.payload as LoginInterface).username,
        client_type : 'dashboard'
      }).then(res => res.response)

      if (!res.login?.roles?.length) 
        throw "The login has no role attached with it."
      
      UtilsService.showToast("Welcome back", "bg-success")

      /** Store credential */
      const storage = new StorageService();
      await storage.set('token', res.tokens.token);
      await storage.set('refreshToken', res.tokens.refreshToken);
      await storage.set('login', res.login);
      await storage.set('operator', res.operators[0]);
      await storage.set('operators', res.operators);
      await storage.set('role', res.defaultRole);
      await storage.set('roles', res.login?.roles);
      await storage.set('resources', res.resources || []);
      
      return store.dispatch(AUTHENTICATION_LOGIN_SUCCESS(res));
    } catch (error: any) {
      console.error("[Authentication] Apply User View Failed: ", error);
      UtilsService.showToast(error?.toString() || "Unknown Error", "bg-danger");
    } finally {
      UtilsService.hideLoading();
    }
  }
  else if (action.type === AUTHENTICATION_ACTION_TYPES.LOGOUT) {
    const storage = new StorageService();
    await storage.clear();
    return store.dispatch(AUTHENTICATION_LOGOUT_SUCCESS());
  }
  else if (action.type === AUTHENTICATION_ACTION_TYPES.CHANGE_ROLE) {
    const role = action.payload as CompanyRoleItem;
    const storage = new StorageService();
    const {response: res} = await _http.get(`/auth/resources/${role?.role?._id}`);
    
    await storage.set('role', role);
    await storage.set('resources', res?.results || []);

    store.dispatch(LOAD_RESOURCES_SUCCESS(res?.results || []));

    return store.dispatch(AUTHENTICATION_INITIAL_APP_COMPLETED({
      role,
      isLoggedIn: true
    }));
  }
  else if (action.type === AUTHENTICATION_ACTION_TYPES.INITIAL_APP) {
    try{
      const storage      = new StorageService();
      const login        = await storage.get('login');
      const operator     = await storage.get('operator');
      const operators    = await storage.get('operators');
      const role         = await storage.get('role');
      const roles        = await storage.get('roles');
      let   token        = await storage.get('token');
      let   refreshToken = await storage.get('refreshToken');
      let   resources    = await storage.get('resources');
      let   isLoggedIn   = false;

      console.info("[NETWORK STATUS]", store.getState().network?.isConnected ? 'Connected' : 'No Internet Access');
      if (store.getState().network?.isConnected) {
        await _http.checkToken();
        isLoggedIn = !!token;
      } else {
        isLoggedIn = !!(login && operator && token && refreshToken)
      }

      /** Fetch Newest User Policies */
      if (isLoggedIn) {
        /** Verify Token */
        const { response: tokens } = await _http.post('/auth/token/verify', {
          token,
          refreshToken
        });
        token = tokens.token;
        refreshToken = tokens.refreshToken;
        await storage.set('token', token);
        await storage.set('refreshToken', refreshToken);

        /** Pull New User Resources */
        const { response: newResources } = await _http.get('/auth/resources/login/' + login._id);
        resources = newResources?.results || [];

        if (!resources?.length) 
          throw "The resource attached with this login."
      }
      return store.dispatch(AUTHENTICATION_INITIAL_APP_COMPLETED({
        login,
        token,
        role,
        roles: roles || [],
        operator,
        operators: operators || [],
        resources: resources || [],
        refreshToken,
        isLoggedIn
      }))
    } catch (error) {
      return store.dispatch(AUTHENTICATION_INITIAL_APP_COMPLETED({
        operator    : {},
        login       : {},
        token       : "",
        refreshToken: "",
        isLoggedIn  : false
      }))
    }
  }
  else if (action.type === AUTHENTICATION_ACTION_TYPES.SIGNUP) {
    try {
      const payload: RegistrationRequest = action.payload;

      store.dispatch(AUTHENTICATION_SHOW_LOADING());
      const res = await _http.post('/auth/register', payload, {
        noToken         : true,
        baseUrl         : process.env.REACT_APP_API_URL,
        doNotHandleError: true
      }).then(res => res.response)

      const storage = new StorageService();
      await storage.set('token', res.tokens.token);
      await storage.set('refreshToken', res.tokens.refreshToken);
      await storage.set('login', res.login);
      await storage.set('operator', res.operators[0]);
      await storage.set('operators', res.operators);
      await storage.set('role', res.login?.roles[0]);
      await storage.set('roles', res.login?.roles);
      await storage.set('resources', res.resources || []);

      res.operator = res.operators[0];
      return store.dispatch(AUTHENTICATION_LOGIN_SUCCESS(res));
    } catch (error) {
      return store.dispatch(AUTHENTICATION_SIGNUP_FAILED(error));
    }
  }
  else if (action.type === AUTHENTICATION_ACTION_TYPES.SIGNUP_FAILED) {
    UtilsService.showToast("Registration failed.", "bg-danger")
    return next(action);
  }
  else {
    return next(action);
  }
}