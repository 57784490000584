import AsyncStorage from '@react-native-async-storage/async-storage';

export class StorageService {

  public async set(key: string, value: any) {
    if (!value && value !== false && value !== 0) return;
    try {
      value = JSON.stringify({value});
      await AsyncStorage.setItem(key, value);
    } catch (error) {
      throw error;
    }
  }

  public async get(key: string) {
    try {
      const value = await AsyncStorage.getItem(key);
      const obj = value ? JSON.parse(value).value : value;
      return obj;
    } catch (error) {
      throw error;
    }
  }

  public async clear() {
    try {
      await AsyncStorage.clear()
    } catch(error) {
      throw error;
    }
  }
  
}