import { CompanyInterface } from "../../interfaces/schema/company.interface";
import { RequestService } from "../common/request.service";

export const CompanyService = {
  /**
   * Fetch company
   */ 
   fetch() {
    const _http   = new RequestService();
    return _http.get(`/companies`).then(res => res.response);
  },

  /**
   * Insert or update company
   * @param payload 
   */
   save(payload: CompanyInterface) {
    const _http   = new RequestService();
    return _http.post(`/companies`, payload).then(res => res.response?.result);
  },

  /**
   * Archive company
   * @param companyId 
   */
  archive(companyId: string) {
    const _http = new RequestService();
    return _http.delete(`/companies/${companyId}`).then(res => res.response);
  }
}