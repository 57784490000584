import React, { forwardRef, useImperativeHandle, useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeclarationComponent from "../../components/common/declaration.component";
import { SessionInterface } from "../../interfaces/schema/session.interface";
import store from "../../store/store";
import { SESSION_ACTION } from "../../store/session/session.actions";
import { cloneDeep } from "lodash";

export const EditSessionDialog = forwardRef((props: {
  show: boolean,
  setShow: (show: boolean) => void,
  session: SessionInterface
}, ref) => {

  const [checked, setChecked] = useState(false as boolean)
  const [session, setSession] = useState({} as SessionInterface)

  
  useImperativeHandle(ref, () => ({
      /**
       * Initial Record Data
       * 
       * @param record 
       */
      initialEditingItem(sessionItem: SessionInterface) {
        setSession(cloneDeep(sessionItem));
      }
    })
  )


  /**
   * Save changes
   */
  const save = () => {
    store.dispatch(SESSION_ACTION.MODIFY_SESSION({
      projectId: props.session.project?._id || "",
      session
    }))
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.show}
        onClose={() => props.setShow(false)}
      >
        <DialogTitle>
          Edit {props.session.session_type?.session_type_name} #{props.session.serial_number}
        </DialogTitle>
        <DialogContent>
          
          <DeclarationComponent checked={checked} setChecked={setChecked}></DeclarationComponent>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => props.setShow(false)} color="primary">
            <b>Cancel</b>
          </Button>
          <Button onClick={save} color="secondary" disabled={!checked}>
            <b>Confirm</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
})