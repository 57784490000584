import { RoleInterface } from "../../interfaces/schema/role.interface";
import { ROLE_ACTION_TYPES } from "./role.actions";



export interface RoleStateInterface {
  entities: RoleInterface[],
  count   : number
}

const initialState: RoleStateInterface = {
  entities: [],
  count   : 0
}

export function RoleReducer(
  state: RoleStateInterface = initialState, 
  action: {type: string, payload?: any}) 
{
  if (action.type === ROLE_ACTION_TYPES.FETCH_SUCCESS) {
    state.entities = action.payload?.results || [];
    state.count    = action.payload?.count   || 0;
  }

  else if (action.type === ROLE_ACTION_TYPES.SAVE_SUCCESS) {
    const entityIndex = state.entities.findIndex(entity => entity._id ==action.payload?.result?._id);
    if (entityIndex == -1) {
      state.count += 1;
      state.entities.push(action.payload?.result)
    } else {
      state.entities[entityIndex] = action.payload?.result;
    }
  }

  else if (action.type === ROLE_ACTION_TYPES.ARCHIVE_SUCCESS) {
    state.entities = state.entities.filter(doc => doc._id != action.payload.roleId);
    state.count--;
  }

  return state;
}