import React, { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { SessionTypeInputInterface } from "../../interfaces/schema/session-type-input.interface";
import { TextField, FormControlLabel, Checkbox, FormControl, Select, InputLabel, MenuItem, ListItemText } from '@material-ui/core';

export const EditSessionInputDialogComponent = forwardRef((props: {
  show: boolean,
  setShow: (show: boolean) => void,
  editingItem: SessionTypeInputInterface,
  setEditingItem: (editingItem: SessionTypeInputInterface) => void
}, ref) => {

  const [editingItem, setEditingItem] = useState({} as SessionTypeInputInterface)

  useImperativeHandle(ref, () => ({
    /**
     * Initial Record Data
     * 
     * @param record 
     */
    initialEditingItem(input: SessionTypeInputInterface) {
      setEditingItem(input)
    }
  }))

  useEffect(() => {
    console.info(props.editingItem);
    setEditingItem(props.editingItem);
    return () => {}
  }, [])

  /**
   * Emit Changes
   */
  const emitInput = () => {
    props.setEditingItem(editingItem);
    props.setShow(false);
  }

  /**
   * Determine Can Save
   */
  const canSave = () => {
    return editingItem.key && editingItem.label;
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.show}
        onClose={() => props.setShow(false)}
      >
        <DialogContent>


          <TextField
            autoFocus
            required={true}
            margin="dense"
            className="mb-3"
            label="Label"
            onChange = {(e) => {
              setEditingItem({
                ...editingItem,
                label: e.target.value || ""
              })
            }}
            value = {editingItem.label}
            fullWidth
          />
          
          <TextField
            autoFocus
            required={true}
            margin="dense"
            className="mb-3"
            label="Key"
            onChange = {(e) => {
              setEditingItem({
                ...editingItem,
                key: e.target.value || ""
              })
            }}
            value = {editingItem.key}
            fullWidth
          />
          
          <TextField
            autoFocus
            required={true}
            margin="dense"
            className="mb-3"
            label="Placeholder"
            onChange = {(e) => {
              setEditingItem({
                ...editingItem,
                placeholder: e.target.value || ""
              })
            }}
            value = {editingItem.placeholder}
            fullWidth
          />

          <FormControl className="col-12 mb-3">
            <InputLabel>Input Type</InputLabel>
            <Select
              value={editingItem.type}
              onChange={(evt) => {
                setEditingItem({
                  ...editingItem,
                  type: evt.target.value as string
                })
              }}
            >
              <MenuItem value={"decimal-pad"}>
                <ListItemText primary={"decimal-pad"} />  
              </MenuItem>
              <MenuItem value={"default"}>
                <ListItemText primary={"default"} />  
              </MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={editingItem.required}
                onChange={(e) => {
                  setEditingItem({
                    ...editingItem,
                    required: e.target.checked || false
                  })
                }}
                color="secondary"
              />
            }
            label={'Required'}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={editingItem.unique}
                onChange={(e) => {
                  setEditingItem({
                    ...editingItem,
                    unique: e.target.checked || false
                  })
                }}
                color="secondary"
              />
            }
            label={'Unique'}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={editingItem.scan_enabled}
                onChange={(e) => {
                  setEditingItem({
                    ...editingItem,
                    scan_enabled: e.target.checked || false
                  })
                }}
                color="secondary"
              />
            }
            label={'Enable Scan'}
          />

          <div className="my-3 d-flex">
            <Button onClick={() => props.setShow(false)} color="primary" className="ms-auto">
              Cancel
            </Button>
            <Button onClick={() => emitInput()} color="primary" disabled={!canSave()}>
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>)
});