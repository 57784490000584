import { SessionInterface } from '../../interfaces/schema/session.interface';
import store from '../store';

export const SessionGetter = {
  item   : (sessionId: string) => 
    store.getState().session.entities?.find((entity: SessionInterface) => entity._id == sessionId),

  listAll: () => 
    store.getState().session.entities,
    
  count  : () => 
    store.getState().session.count,
}