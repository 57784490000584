import store from '../store';

export const CompanyGetter = {
  item   : (companyId: string) => 
    store.getState().company.entities?.find(entity => entity._id == companyId),

  listAll: () => 
    store.getState().company.entities,
    
  count  : () => 
    store.getState().company.count,
}