import { Pagination } from "../../interfaces/common/pagination.interface"
import { CompanyInterface } from "../../interfaces/schema/company.interface"

function FETCH(pagination?: Pagination) {
  return {
    type: COMPANY_ACTION_TYPES.FETCH,
    payload: {
      pagination: pagination || {}
    }
  }
}

function FETCH_SUCCESS(results: CompanyInterface[], count: number) {
  return {
    type: COMPANY_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

function SAVE(payload: CompanyInterface, cb?: () => void) {
  return {
    type: COMPANY_ACTION_TYPES.SAVE,
    payload: {
      payload,
      cb
    }
  }
}

function SAVE_SUCCESS(result: CompanyInterface) {
  return {
    type: COMPANY_ACTION_TYPES.SAVE_SUCCESS,
    payload: {
      result
    }
  }
}

function ARCHIVE(companyId: string, cb: () => void) {
  return {
    type: COMPANY_ACTION_TYPES.ARCHIVE,
    payload: {
      cb,
      companyId
    }
  }
}

function ARCHIVE_SUCCESS(companyId: string) {
  return {
    type: COMPANY_ACTION_TYPES.ARCHIVE_SUCCESS,
    payload: {
      companyId
    }
  }
}

export const COMPANY_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  ARCHIVE,
  ARCHIVE_SUCCESS
}

export const COMPANY_ACTION_TYPES = {
  FETCH: '[COMPANY] FETCH',
  FETCH_SUCCESS: '[COMPANY] FETCH SUCCESS',
  SAVE: '[COMPANY] SAVE',
  SAVE_SUCCESS: '[COMPANY] SAVE SUCCESS',
  ARCHIVE: '[COMPANY] ARCHIVE',
  ARCHIVE_SUCCESS: '[COMPANY] ARCHIVE SUCCESS'
}