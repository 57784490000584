import { ImportedFileInterface } from "../../interfaces/schema/imported-file.interface";
import { GRADE_DATA_ACTION_TYPES } from "./grade-data.actions";

export interface GradeDataStateInterface {
  files: ImportedFileInterface[],
  count: number,
  fileCategories: string[],
  categoryFields: any
}

const initialState: GradeDataStateInterface = {
  files: [],
  count: 0,
  fileCategories: [],
  categoryFields: {}
}

export function GradeDataReducer(
  state: GradeDataStateInterface = initialState, 
  action: {type: string, payload?: any}) 
{
  if (action.type === GRADE_DATA_ACTION_TYPES.FETCH_SUCCESS) {
    state.files = action.payload?.results || [];
    state.count = action.payload?.count   || 0;
  }
  else if (action.type === GRADE_DATA_ACTION_TYPES.ARCHIVE_SUCCESS) {
    state.files = state.files.filter(file => file.process_id != action.payload.process_id)
    state.count--
  }
  else if (action.type === GRADE_DATA_ACTION_TYPES.UPDATE_SUCCESS) {
    const index = state.files.findIndex(file => file.process_id == action.payload.file.process_id);
    if (index >=0 )
      state.files[index] = action.payload.file;
  }
  else if (action.type === GRADE_DATA_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS) {
    state.fileCategories = action.payload.categories || [];
  }
  else if (action.type === GRADE_DATA_ACTION_TYPES.FETCH_CATEGORY_FIELDS_SUCCESS) {
    console.info(action.payload);
    state.categoryFields[action.payload.category] = action.payload.fields;
  }
  return state;
}