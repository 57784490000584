export const DATA_SPEC_SYSTEM_INPUTS = [
  {
    label: "Project No.",
    key: "system_project_no"
  },
  {
    label: "Session Type No.",
    key: "system_session_type_no"
  },
  {
    label: "Session No.",
    key: "system_session_no"
  },
  {
    label: "Operator",
    key: "system_operator_name"
  },
  {
    label: "Total Number of Records",
    key: "system_total_number_of_records"
  },
  {
    label: "Session Record Progress",
    key: "system_session_progress"
  },
  {
    label: "Session Plant Code",
    key: "system_plant_code"
  },
  {
    label: "Session Location",
    key: "system_location"
  },
  {
    label: "Session Started At",
    key: "system_session_started_at"
  },
  {
    label: "Record Date",
    key: "system_record_date"
  },
  {
    label: "Record Time",
    key: "system_record_time"
  },
  {
    label: "Comments",
    key: "system_comments"
  },
  {
    label: "Discarded",
    key: "system_mark_as_excluded"
  }
]