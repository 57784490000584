import { RequestService } from "../../services/common/request.service";
import { UtilsService } from "../../services/common/utils.service";
import { SESSION_RECORD_ACTION, SESSION_RECORD_ACTION_TYPES } from "./session-record.actions";
import * as qs from 'qs';

const {
  FETCH_SUCCESS,
  ARCHIVE_RECORD_SUCCESS,
  MODIFY_RECORD_SUCCESS
} = SESSION_RECORD_ACTION;

export const SessionRecordMiddleware = (store: any) => (next: any) => async (
  action: {
    type      : string, 
    payload   : any, 
    projectId : string,
    sessionId : string
  }
) => {
  const _http   = new RequestService();

  /** Fetch session types by project id */
  if (action.type === SESSION_RECORD_ACTION_TYPES.FETCH) {
    try {
      /** Check Internet connection */
      const {response} = await _http.get(`/projects/${action.projectId}/sessions/${action.sessionId}/records?` + qs.stringify(action.payload.pagination));
      return store.dispatch(FETCH_SUCCESS(response.results, response.count))
    } catch (error) {
      console.error(SESSION_RECORD_ACTION_TYPES.FETCH, error);
      return ;
    }
  }

  else if (action.type === SESSION_RECORD_ACTION_TYPES.ARCHIVE_RECORD) {
    await _http.delete(`/projects/${action.projectId}/sessions/${action.sessionId}/records/${action.payload}`);
    return store.dispatch(ARCHIVE_RECORD_SUCCESS(action.payload))
  }

  else if (action.type === SESSION_RECORD_ACTION_TYPES.MODIFY_RECORD) {
    const {response} = await _http.post(`/projects/${action.projectId}/sessions/${action.sessionId}/records/${action.payload._id}/modify`, {payload: action.payload});
    UtilsService.showToast("Record Updated");
    return store.dispatch(MODIFY_RECORD_SUCCESS(response.result))
  }

  /** Pass */
  else {
    return next(action);
  }
}