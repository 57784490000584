import { useEffect, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { IconButton, TextField, Tooltip, Icon } from '@material-ui/core';
import store from '../../../store/store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { GlobalStyleConstant } from '../../../constants/styles/global.const';
import { SessionTypeInterface } from '../../../interfaces/schema/session-type.interface';
import { SESSION_TYPE_ACTION } from '../../../store/session-type/session-type.actions';
import { SessionTypeGetter } from '../../../store/session-type/session-type.getters';
import { UtilsService } from '../../../services/common/utils.service';
import { Delete } from '@material-ui/icons';
import ConfirmDialog from '../../../dialogs/common/confirm.dialog';
import { EditSessionInputDialogComponent } from '../../../dialogs/session/edit-session-input.dialog';
import { SessionTypeInputInterface } from '../../../interfaces/schema/session-type-input.interface';
import { cloneDeep } from 'lodash';


/**
 * Project Setting Page
 * @returns {JSX.Element}
 */
 export default function SessionTypeComponent(props: {
   projectId?: string
 }) {

  const editInputDialogRef = useRef() as React.MutableRefObject<{initialEditingItem: (input: SessionTypeInputInterface) => void}>;

  const [showSessionTypeDialog, setShowSessionTypeDialog] = useState(false);
  const [editingSessionType, setEditingSessionType] = useState({} as SessionTypeInterface);
  const [sessionTypes, setSessionTypes] = useState([] as SessionTypeInterface[]);
  const [showConfirm, setShowConfirm] = useState(false as boolean)
  const [confirmMessage, setConfirmMessage] = useState("" as string)
  const [showEditInput, setShowEditInput] = useState(false as boolean)
  const [editingInput, setEditingInput] = useState({} as SessionTypeInputInterface)
  const [editingInputPosition, setEditingInputPosition] = useState({} as {is_meta?: boolean, input_index?: number | null})

  useEffect(() => {
    /** Fetch Projects */
    fetchSessionTypes();

    /** Subscribe to store changes */
    const unsubscribeStore = store.subscribe(() => {
      setSessionTypes(SessionTypeGetter.listAll())
    })

    return () => {
      unsubscribeStore();
    }
  }, [])

  /**
   * Fetch Project List
   */
  const fetchSessionTypes = () => {
    if (props.projectId)
      store.dispatch(SESSION_TYPE_ACTION.FETCH(props.projectId))
  }

  /**
   * User Confirm archive session type
   */
  const confirmArchiveSessionType = (sessionType: SessionTypeInterface) => {
    setEditingSessionType(sessionType);
    setShowConfirm(true);
    setConfirmMessage(`Archive Session Type ${sessionType.session_type_name} #${sessionType.serial_number}`);
  }

  /**
   * Do archive session type
   */
  const archiveSessionType = () => {
    if (editingSessionType._id && editingSessionType.project)
      store.dispatch(SESSION_TYPE_ACTION.ARCHIVE(editingSessionType.project, editingSessionType._id, archiveCallback));
  }

  /**
   * Hide Confirm Dialog
   */
  const archiveCallback = () => {
    setShowConfirm(false);
  }
  
  /**
   * Fetch roles 
   */
  // const fetchRoles = () => {
  //   store.dispatch(ROLE_ACTION.FETCH());
  // }

  /**
   * Edit Session Type
   */
  const editSessionType = (sessionType?: SessionTypeInterface) => {
    setShowSessionTypeDialog(true);
    setEditingSessionType(sessionType || {
      project: props.projectId
    });
  }

  /**
   * Edit or add session type input
   * 
   * @param input 
   */
  const editSessionInput = (input?: SessionTypeInputInterface, is_meta?: boolean, input_index?: number | null) => {
    setEditingInput(input || {});
    editInputDialogRef.current.initialEditingItem(input || {});
    setEditingInputPosition({
      is_meta,
      input_index
    });
    setShowEditInput(true);
  }

  /**
   * Save Session Type
   */
  const saveSessionType = () => {
    try {
      store.dispatch(SESSION_TYPE_ACTION.SAVE(editingSessionType, saveCallBack))
    } catch (error) {
      UtilsService.showToast(error.toString(), "bg-danger")
    }
  }

  /**
   * Close dialog after saved
   */
  const saveCallBack = () => {
    setShowSessionTypeDialog(false);
  }

  /** 
   * Validation
   */
  const canSave = () => {
    return !!editingSessionType.session_type_name;
  }

  return (
    <div>
      <ConfirmDialog
        show={showConfirm}
        setShow={setShowConfirm}
        message={confirmMessage}
        confirm={archiveSessionType}
      ></ConfirmDialog>
      <div className="d-flex mt-3">
        <span className="h5 mb-0 mt-1">Session Types</span>
        <Button variant="contained" color="primary" className="ms-auto" onClick={() => editSessionType()}>Create Session Type</Button>
      </div>
      <div className="my-2">
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Session No.</TableCell>
                <TableCell>Session Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessionTypes.map((sessionType, sessionIndex) => (
                <TableRow key={sessionIndex}>
                  <TableCell>
                    {sessionType.serial_number}
                  </TableCell>
                  <TableCell>
                    {sessionType.session_type_name}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Session Type">
                      <IconButton onClick={() => editSessionType(sessionType)} size="small" color="primary">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Archive Session Type">
                      <IconButton onClick={() => confirmArchiveSessionType(sessionType)} size="small" color="secondary">
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog 
        open={showSessionTypeDialog}
        maxWidth={'md'}
        onClose={() => setShowSessionTypeDialog(false)}
      >
        <DialogTitle>Edit Session Type</DialogTitle>
        <DialogContent
          style={{
            width: GlobalStyleConstant.dialogMd
          }}
        >
          <TextField
            autoFocus
            required={true}
            margin="dense"
            className="mb-3"
            label="Session Type Name"
            onChange = {(e) => {
              setEditingSessionType({
                ...editingSessionType,
                session_type_name: e.currentTarget.value
              })
            }}
            value = {editingSessionType.session_type_name}
            fullWidth
          />

          <b>Meta Data Inputs</b>
          <table className="w-100 mb-2">
            <thead>
              <tr className="bg-dark text-white">
                <th>Input</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                editingSessionType.session_meta_data_inputs?.map((input, index) => {
                  return (
                    <tr className="border" key={index}>
                      <td>
                        {input.label}
                      </td>
                      <td className="text-end">
                        <Icon
                          color="primary"
                          className="pointer me-2"
                          onClick={() => {
                            editSessionInput(input, true, index);
                          }}
                        >
                          create
                        </Icon>
                        <Icon
                          color="secondary"
                          className="pointer me-2"
                          onClick={() => {
                            const inputs = cloneDeep(editingSessionType.session_meta_data_inputs);
                            inputs?.splice(index, 1);
                            setEditingSessionType({
                              ...editingSessionType,
                              session_meta_data_inputs: inputs
                            })
                          }}
                        >
                          delete
                        </Icon>
                      </td>
                    </tr>
                  )
                }) || <></>
              }
            </tbody>
          </table>
          <Button color="primary" className="w-100 mb-3" size="small" variant="outlined" onClick={() => editSessionInput({}, true, null)}>
            Add Metadata Input
          </Button>

          <b>Session Inputs</b>
          <table className="w-100 mb-2">
            <thead>
              <tr className="bg-dark text-white">
                <th>Input Label</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                editingSessionType.session_inputs?.map((input, index) => {
                  return (
                    <tr className="border" key={index}>
                      <td>
                        {input.label}
                      </td>
                      <td className="text-end">
                        <Icon
                          color="primary"
                          className="pointer me-2"
                          onClick={() => {
                            editSessionInput(input, false, index);
                          }}
                        >
                          create
                        </Icon>
                        <Icon
                          color="secondary"
                          className="pointer me-2"
                          onClick={() => {
                            const inputs = cloneDeep(editingSessionType.session_inputs);
                            inputs?.splice(index, 1);
                            setEditingSessionType({
                              ...editingSessionType,
                              session_inputs: inputs
                            })
                          }}
                        >
                          delete
                        </Icon>
                      </td>
                    </tr>
                  )
                }) || <></>
              }
            </tbody>
          </table>
          <Button color="primary" className="w-100" size="small" variant="outlined" onClick={() => editSessionInput({}, false, null)}>
            Add Session Input
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSessionTypeDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={saveSessionType} disabled={!canSave()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <EditSessionInputDialogComponent
        show={showEditInput}
        ref={editInputDialogRef}
        setShow={setShowEditInput}
        editingItem={editingInput || {}}
        setEditingItem={(input) => {
          const newSessionType = cloneDeep(editingSessionType)

          setEditingInput(input);
          if (editingInputPosition.is_meta) 
            if (newSessionType.session_meta_data_inputs && editingInputPosition.input_index != null)
              newSessionType.session_meta_data_inputs[editingInputPosition.input_index as number] = input
            else 
              newSessionType?.session_meta_data_inputs?.push(input)
          
          else 
            if (newSessionType.session_inputs && editingInputPosition.input_index != null) 
              newSessionType.session_inputs[editingInputPosition.input_index as number] = input
            else
              newSessionType?.session_inputs?.push(input)

          setEditingSessionType(newSessionType);
        }}
      ></EditSessionInputDialogComponent>
    </div>
  );
}