import { PersonInterface } from "../../interfaces/schema/person.interface";
import { RequestService } from "../../services/common/request.service";
import { UtilsService } from "../../services/common/utils.service";
import { PersonService } from "../../services/store/person.service";
import { PERSON_ACTION, PERSON_ACTION_TYPES } from "./person.actions";

const {
  FETCH_SUCCESS,
  SAVE_SUCCESS,
  ARCHIVE_SUCCESS
} = PERSON_ACTION;

export const PersonMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any, projectId: string}) => {
  const _http   = new RequestService();
  /** 
   * Fetch persons
   */
  if (action.type === PERSON_ACTION_TYPES.FETCH) {
    try {
      const {results, count} = await PersonService.fetch();
      return store.dispatch(FETCH_SUCCESS(results, count));
    }  
    catch (error) {
      console.error(PERSON_ACTION_TYPES.FETCH, error);
      return ;
    }
  }
  /** 
   * Save person
   */
  else if (action.type === PERSON_ACTION_TYPES.SAVE) {
    try {
      const newPerson: PersonInterface = await PersonService.save(action.payload.payload);
      UtilsService.showToast("Saved");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(SAVE_SUCCESS(newPerson))
    } catch (error) {
      return console.error(PERSON_ACTION_TYPES.SAVE, error);
    }
  }
  /**
   * Archive person
   */
  else if (action.type === PERSON_ACTION_TYPES.ARCHIVE) {
    try {
      await PersonService.archive(action.payload.personId);
      UtilsService.showToast("Archived");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(ARCHIVE_SUCCESS(action.payload.personId))
    } catch (error) {
      return console.error(PERSON_ACTION_TYPES.ARCHIVE, error);
    }
  }
  else {
    return next(action);
  }
}