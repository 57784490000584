
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import store from '../../../../store/store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { cloneDeep } from 'lodash';
import { UtilsService } from '../../../../services/common/utils.service';
import { GlobalStyleConstant } from '../../../../constants/styles/global.const';
import { CompanyInterface } from '../../../../interfaces/schema/company.interface';
import { COMPANY_ACTION } from '../../../../store/company/company.actions';
import { CompanyGetter } from '../../../../store/company/company.getter';
import { Address } from '../../../../interfaces/common/address.interface';

/**
 * Companies Page
 * @returns {JSX.Element}
 */
export default function CompaniesPage() {

  const [companies, setCompanies] = useState([] as CompanyInterface[])
  const [showDialog, setShowDialog] = useState(false)
  const [editingItem, setEditingItem] = useState({} as CompanyInterface)

  useEffect(() => {
    store.dispatch(COMPANY_ACTION.FETCH());
    const unsubscribe = store.subscribe(() => {
      setCompanies(CompanyGetter.listAll())
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Pop up edit dialog
   * 
   * @param company 
   */
  const editCompany = (company?: CompanyInterface) => {
    setEditingItem(cloneDeep(company || {}))
    setShowDialog(true);
  }

  const save = () => {
    store.dispatch(COMPANY_ACTION.SAVE(editingItem, () => {
      setShowDialog(false);
      UtilsService.showToast("Saved");
    }));
  }

  /**
   * Get Company Primary Address
   * 
   * @param addresses 
   * @returns 
   */
  const primaryAddress = (addresses?: {is_primary: boolean, address: Address}[]) => {
    return addresses?.find(address => address.is_primary)?.address || (addresses && addresses[0]?.address)
  }

  return (
    <div>
      <div className="d-flex mb-3">
        <span className="h3">Companies</span>
        <Button variant="contained" color="primary" className="ms-auto" onClick={() => editCompany()}>Create Company</Button>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Postcode</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell component="th" scope="row">
                    {row.company_name}
                  </TableCell>

                <TableCell>
                  {primaryAddress(row.company_addresses)?.address_state || ""}
                </TableCell>
                <TableCell>
                  {primaryAddress(row.company_addresses)?.address_postcode || ""}
                </TableCell>

                  <TableCell align="right">
                    <Tooltip title="Edit Config">
                      <IconButton onClick={() => editCompany(row)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="lg">
        <DialogTitle className="pb-0">{editingItem._id ? "Edit" : "Create"} Company</DialogTitle>
        <DialogContent style={{width: GlobalStyleConstant.dialogMd}}>
          <TextField
            autoFocus
            margin="dense"
            label="Company Name"
            onChange={(e) => {
              setEditingItem({
                ...editingItem,
                company_name: e.target.value
              })
            }}
            value = {editingItem.company_name}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => save()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
