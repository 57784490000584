import { GlobalConfigInterface } from './../../interfaces/schema/global-config.interface';
import { Pagination } from "../../interfaces/common/pagination.interface"

function FETCH(pagination?: Pagination) {
  return {
    type: GLOBAL_CONFIG_ACTION_TYPES.FETCH,
    payload: {
      pagination: pagination || {}
    }
  }
}

function FETCH_SUCCESS(results: GlobalConfigInterface[], count: number) {
  return {
    type: GLOBAL_CONFIG_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}
function FETCH_ITEM(configSerialNumber: string, cb: (config: GlobalConfigInterface) => void) {
  return {
    type: GLOBAL_CONFIG_ACTION_TYPES.FETCH_ITEM,
    payload: configSerialNumber,
    cb
  }
}

function FETCH_ITEM_SUCCESS(result: GlobalConfigInterface[]) {
  return {
    type: GLOBAL_CONFIG_ACTION_TYPES.FETCH_ITEM_SUCCESS,
    payload: {
      result
    }
  }
}

function SAVE(payload: GlobalConfigInterface, cb?: () => void) {
  return {
    type: GLOBAL_CONFIG_ACTION_TYPES.SAVE,
    payload,
    cb
  }
}

function SAVE_SUCCESS(result: GlobalConfigInterface) {
  return {
    type: GLOBAL_CONFIG_ACTION_TYPES.SAVE_SUCCESS,
    payload: {
      result
    }
  }
}

export const GLOBAL_CONFIG_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_ITEM,
  FETCH_ITEM_SUCCESS,
  SAVE,
  SAVE_SUCCESS
}

export const GLOBAL_CONFIG_ACTION_TYPES = {
  FETCH: '[GLOBAL_CONFIG] FETCH',
  FETCH_SUCCESS: '[GLOBAL_CONFIG] FETCH SUCCESS',
  FETCH_ITEM: '[GLOBAL_CONFIG] FETCH ITEM',
  FETCH_ITEM_SUCCESS: '[GLOBAL_CONFIG] FETCH ITEM SUCCESS',
  SAVE: '[GLOBAL_CONFIG] SAVE',
  SAVE_SUCCESS: '[GLOBAL_CONFIG] SAVE SUCCESS'
}