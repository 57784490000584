import store from '../store';

export const CompanyPositionGetter = {
  item   : (companyPositionId: string) => 
    store.getState().companyPosition.entities?.find(entity => entity._id == companyPositionId),

  listAll: () => 
    store.getState().companyPosition.entities,
    
  count  : () => 
    store.getState().companyPosition.count,
}