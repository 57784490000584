import { SessionTypeInterface } from './../../interfaces/schema/session-type.interface';
import { Pagination } from "../../interfaces/common/pagination.interface"

function FETCH(projectId: string, pagination?: Pagination, cb?: (res: {results: SessionTypeInterface[], count: number}) => void) {
  return {
    type: SESSION_TYPE_ACTION_TYPES.FETCH,
    projectId,
    payload: {
      pagination: pagination || {},
      cb
    }
  }
}

function FETCH_SUCCESS(results: SessionTypeInterface[], count: number) {
  return {
    type: SESSION_TYPE_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

function SAVE(payload: SessionTypeInterface, cb?: () => void) {
  return {
    type: SESSION_TYPE_ACTION_TYPES.SAVE,
    payload: {
      payload,
      cb
    }
  }
}

function SAVE_SUCCESS(result: SessionTypeInterface) {
  return {
    type: SESSION_TYPE_ACTION_TYPES.SAVE_SUCCESS,
    payload: {
      result
    }
  }
}

function ARCHIVE(projectId: string, sessionTypeId: string, cb: () => void) {
  return {
    type: SESSION_TYPE_ACTION_TYPES.ARCHIVE,
    projectId,
    payload: {
      cb,
      sessionTypeId
    }
  }
}

function ARCHIVE_SUCCESS(sessionTypeId: string) {
  return {
    type: SESSION_TYPE_ACTION_TYPES.ARCHIVE_SUCCESS,
    payload: {
      sessionTypeId
    }
  }
}

export const SESSION_TYPE_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  ARCHIVE,
  ARCHIVE_SUCCESS
}

export const SESSION_TYPE_ACTION_TYPES = {
  FETCH: '[SESSION_TYPE] FETCH',
  FETCH_SUCCESS: '[SESSION_TYPE] FETCH SUCCESS',
  SAVE: '[SESSION_TYPE] SAVE',
  SAVE_SUCCESS: '[SESSION_TYPE] SAVE SUCCESS',
  ARCHIVE: '[SESSION_TYPE] ARCHIVE',
  ARCHIVE_SUCCESS: '[SESSION_TYPE] ARCHIVE SUCCESS'
}