import { Pagination } from "../../interfaces/common/pagination.interface"
import { PersonInterface } from "../../interfaces/schema/person.interface"

function FETCH(pagination?: Pagination) {
  return {
    type: PERSON_ACTION_TYPES.FETCH,
    payload: {
      pagination: pagination || {}
    }
  }
}

function FETCH_SUCCESS(results: PersonInterface[], count: number) {
  return {
    type: PERSON_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

function SAVE(payload: PersonInterface, cb?: () => void) {
  return {
    type: PERSON_ACTION_TYPES.SAVE,
    payload: {
      payload,
      cb
    }
  }
}

function SAVE_SUCCESS(result: PersonInterface) {
  return {
    type: PERSON_ACTION_TYPES.SAVE_SUCCESS,
    payload: {
      result
    }
  }
}

function ARCHIVE(personId: string, cb: () => void) {
  return {
    type: PERSON_ACTION_TYPES.ARCHIVE,
    payload: {
      cb,
      personId
    }
  }
}

function ARCHIVE_SUCCESS(personId: string) {
  return {
    type: PERSON_ACTION_TYPES.ARCHIVE_SUCCESS,
    payload: {
      personId
    }
  }
}

export const PERSON_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  ARCHIVE,
  ARCHIVE_SUCCESS
}

export const PERSON_ACTION_TYPES = {
  FETCH: '[PERSON] FETCH',
  FETCH_SUCCESS: '[PERSON] FETCH SUCCESS',
  SAVE: '[PERSON] SAVE',
  SAVE_SUCCESS: '[PERSON] SAVE SUCCESS',
  ARCHIVE: '[PERSON] ARCHIVE',
  ARCHIVE_SUCCESS: '[PERSON] ARCHIVE SUCCESS'
}