import { GlobalConfigInterface } from "../../interfaces/schema/global-config.interface";
import { GLOBAL_CONFIG_ACTION_TYPES } from "./global-config.actions";


export interface GlobalConfigStateInterface {
  entities: GlobalConfigInterface[],
  count   : number
}

const initialState: GlobalConfigStateInterface = {
  entities: [],
  count   : 0
}

export function GlobalConfigReducer(
  state: GlobalConfigStateInterface = initialState, 
  action: {type: string, payload?: any}) 
{
  if (action.type === GLOBAL_CONFIG_ACTION_TYPES.FETCH_SUCCESS) {
    state.entities = action.payload?.results || [];
    state.count    = action.payload?.count   || 0;
  }
  if (action.type === GLOBAL_CONFIG_ACTION_TYPES.FETCH_ITEM_SUCCESS) {
    const index = state.entities.findIndex(entity => entity.serial_number == action.payload.result.serial_number);
    if (index == -1) {
      state.entities.push(action.payload.result)
      state.count += 1;
    }
    else {
      state.entities[index] = action.payload.result
    }
  }

  else if (action.type === GLOBAL_CONFIG_ACTION_TYPES.SAVE_SUCCESS) {
    const entityIndex = state.entities.findIndex(entity => entity._id ==action.payload?.result?._id);
    if (entityIndex == -1) {
      state.count += 1;
      state.entities.push(action.payload?.result)
    } else {
      state.entities[entityIndex] = action.payload?.result;
    }
  }

  return state;
}