import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import store from '../../store/store';
import { AUTHENTICATION_ACTIONS } from '../../store/authentication/authentication.actions';
import { AuthenticationGetter } from '../../store/authentication/authentication.getters';
import { useHistory } from 'react-router';
import { PagePaths } from '../../enums/page-paths.enum';
import { NavigationGetter } from '../../store/navigation/navigation.getter';
import ForgotPasswordDialog from '../../components/dialogs/forgot-password.dialog'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 350,
    margin: "auto"
  }
});

/**
 * Login Page
 * @returns {JSX.Element}
 */
export default function LoginPage() {
  const classes = useStyles();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false as boolean);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (AuthenticationGetter.isLoggedIn() && NavigationGetter.listAvailable()[0]) {
        history.replace(NavigationGetter.listAvailable()[0]?.path || PagePaths.DASHBOARD);
      }
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Login if enter pressed
   */
  const handleKeyPress = (evt: any) => {
    if (evt.key == 'Enter') 
      login();
  }

  /**
   * Login request
   * 
   * @returns {void}
   */
     const login = () => {
      if (!password || !username) return;
      store.dispatch(AUTHENTICATION_ACTIONS.AUTHENTICATION_LOGIN({
        username: username,
        password: password
      }));
      
    };
  
    /**
     * Validate login input
     * 
     * @returns {boolean}
     */
    const canLogin = () => {
      return !!username && !!password;
    }

  return (
    <div className="d-flex vh-100">
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2" className="mb-2">
            Authentication
          </Typography>
          <TextField 
            label="Username / Email" 
            type="email" 
            className="w-100 mt-3"
            value={username}
            onChange={(evt) => setUsername(evt.target.value)}/>
          <TextField 
            label="Password" 
            type="password" 
            className="w-100 my-3"
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
            onKeyPress={handleKeyPress}
            onSubmit={login}/>
          <Button 
            variant   = "contained" 
            color     = "primary" 
            size      = "large" 
            className = "d-block w-100 mt-2"
            disabled  = {!canLogin()}
            onClick   = {login}>
            Login
          </Button>
        </CardContent>
        <CardActions>
          {/* <Button color="primary" size="small">Request Login</Button> */}
          <Button 
            color     = "primary" 
            className = "ms-auto" 
            size      = "small"
            onClick   = {() => setShowForgotPassword(true)}
          >
            Forgot Password
          </Button>
        </CardActions>
      </Card>
      <ForgotPasswordDialog
        setShowDialog = {setShowForgotPassword}
        showDialog    = {showForgotPassword} 
      ></ForgotPasswordDialog>
    </div>
  );
}
