import { CompanyInterface } from "../../interfaces/schema/company.interface";
import { COMPANY_ACTION_TYPES } from "./company.actions";

export interface CompanyStateInterface {
  entities: CompanyInterface[],
  count   : number
}

const initialState: CompanyStateInterface = {
  entities: [],
  count   : 0
}

export function CompanyReducer(
  state: CompanyStateInterface = initialState, 
  action: {type: string, payload?: any}) 
{
  if (action.type === COMPANY_ACTION_TYPES.FETCH_SUCCESS) {
    state.entities = action.payload?.results || [];
    state.count    = action.payload?.count   || 0;
  }

  else if (action.type === COMPANY_ACTION_TYPES.SAVE_SUCCESS) {
    const entityIndex = state.entities.findIndex(entity => entity._id ==action.payload?.result?._id);
    if (entityIndex == -1) {
      state.count += 1;
      state.entities.push(action.payload?.result)
    } else {
      state.entities[entityIndex] = action.payload?.result;
    }
  }

  else if (action.type === COMPANY_ACTION_TYPES.ARCHIVE_SUCCESS) {
    state.entities = state.entities.filter(doc => doc._id != action.payload.companyId);
    state.count--;
  }

  return state;
}