import store from '../store';

export const ResourceGetter = {
  item   : (resourceId: string) => 
    store.getState().resource.entities?.find(entity => entity._id == resourceId),

  listAll: () => 
    store.getState().resource.entities,
    
  count  : () => 
    store.getState().resource.count,
}