import React, { useEffect, useState } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { AuthenticationGetter } from "../../store/authentication/authentication.getters";
import { PersonInterface } from "../../interfaces/schema/person.interface";
import { Button, Icon } from '@material-ui/core';
import { cloneDeep } from "lodash";

export default function ObjectEditorComponent(props: {
  object: string,
  setObject: (object: string) => void
}) {

  /** Assign State Variables */
  const [keys, setKeys] = useState([] as string[]);
  const [editingItem, setEditingItem] = useState({} as any);

  useEffect(() => {
    setEditingItem(JSON.parse(props.object));
    setKeys(Object.keys(JSON.parse(props.object)));
  }, [])

  /**
   * Fire setObject()
   */
  const emitObject = (newItem: any = editingItem) => {
    try {  
      props.setObject(JSON.stringify(newItem, null, 2));
    } catch (error) {
      console.warn("Unable to parse new object: ", error);
    }
  }

  return <React.Fragment>
    <table className="w-100 my-3">
      <thead>
        <tr className="bg-dark text-white">
          <th>Key</th>
          <th>Value</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          keys.map((key: string, index: number) => {

            return (<tr key={index}>
              <th>
                <input 
                  className = "w-100 border"
                  type      = "text"
                  value     = {key}
                  onChange  = {(evt) => {
                    const newKey  = evt.target.value;
                    if (key == newKey || !newKey) return;
                    const newItem  = cloneDeep(editingItem);
                    const newKeys  = cloneDeep(keys);
                    newKeys[index] = newKey;

                    if (newKey)
                      newItem[newKey] = editingItem[key];
                    delete newItem[key];
                    setEditingItem(newItem);
                    setKeys(newKeys);
                    emitObject(newItem);
                  }}
                />
              </th>
              <td>
                <input 
                  className = "w-100 border"
                  type      = "value"
                  value     = {editingItem[key]}
                  onChange  = {(evt) => {
                    const newValue = evt.target.value;
                    const newItem = cloneDeep(editingItem);
                    if (key)
                      newItem[key] = newValue;
                    setEditingItem(newItem);
                    emitObject(newItem)
                  }}
                />
              </td>
              <td className="text-center">
                <Icon 
                  fontSize="small" 
                  color="secondary"
                  className="pointer"
                  onClick={() => {
                    const newKeys = cloneDeep(keys);
                    const newItem = cloneDeep(editingItem);

                    newKeys.splice(index, 1);
                    setKeys(newKeys);
                    delete newItem[key];
                    setEditingItem(newItem);
                    emitObject(newItem);
                  }}
                >delete</Icon>
              </td>
            </tr>)
          })
        }
      </tbody>
    </table>


    <Button color="primary" size="small" variant="outlined" className="w-100 mb-4" onClick={() => {
      const newKeys = cloneDeep(keys);
      const newItem = cloneDeep(editingItem);

      newKeys.push("New Key Name");
      setKeys(newKeys);
      newItem["New Key Name"] = "New Value";
      setEditingItem(newItem);
      emitObject(newItem);

    }}>
      <b>Add Key</b>
    </Button>

  </React.Fragment>
}