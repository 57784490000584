import { RequestService } from "../../services/common/request.service";
import { PROJECT_ACTION, PROJECT_ACTION_TYPES } from "./project.actions";
import { CompanyRoleItem } from '../../interfaces/page-state/dashboard-page-state.interface';
import * as qs from 'qs';
import { StorageService } from "../../services/common/storage.service";
import { UtilsService } from "../../services/common/utils.service";
import { ProjectInterface } from "../../interfaces/schema/project.interface";
import { ProjectService } from "../../services/store/project.service";

const {
  FETCH_SUCCESS,
  SAVE_SUCCESS,
  ARCHIVE_SUCCESS
} = PROJECT_ACTION;

export const ProjectMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any, request: boolean}) => {
  const _http   = new RequestService();
  const storage = new StorageService();
  /** Fetch project by company and role */
  if (action.type === PROJECT_ACTION_TYPES.FETCH) {
    try {
      /** Get current role */
      const companyRole  : CompanyRoleItem = store.getState()?.authentication?.role?.role;
      const { response } = await _http.get(`/projects?${qs.stringify(action.payload)}`, {
        headers: {
          'x-role-id'   : companyRole?.role?._id,
          'x-company-id': companyRole?.company?._id
        }
      });
      /** Save to cache */
      storage.set(PROJECT_ACTION_TYPES.FETCH, response);
      if (action.payload.cb) action.payload.cb(response);
      return store.dispatch(FETCH_SUCCESS(response.results, response.count));
    } catch (error) {
      return console.error(PROJECT_ACTION_TYPES.FETCH, error);
    } finally {
      UtilsService.hideLoading();
    }
  }
  else if (action.type === PROJECT_ACTION_TYPES.SAVE) {
    try {
      const newSessionType: ProjectInterface = await ProjectService.save(action.payload.payload);
      UtilsService.showToast("Saved");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(SAVE_SUCCESS(newSessionType))
    } catch (error) {
      return console.error(PROJECT_ACTION_TYPES.SAVE, error);
    }
  }
  else if (action.type === PROJECT_ACTION_TYPES.ARCHIVE) {
    try {
      await ProjectService.archive(action.payload.projectId);
      UtilsService.showToast("Archived");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(ARCHIVE_SUCCESS(action.payload.sessionTypeId))
    } catch (error) {
      return console.error(PROJECT_ACTION_TYPES.ARCHIVE, error);
    }
  }
  else {
    return next(action);
  }
}