import { Button, Snackbar, ThemeProvider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ActionNames } from './enums/action-names.enum';
import './App.css';
import {Helmet} from "react-helmet";
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/indigo';
import { createTheme } from '@material-ui/core/styles';

/** Pages */
import DashboardPage from './pages/dashboard/dashboard.page';
import LoginPage     from './pages/login/login.page';
import { UtilsService } from './services/common/utils.service';
import store from './store/store';
import { AUTHENTICATION_ACTIONS } from './store/authentication/authentication.actions';

/**
 * Page Outlet
 * 
 * @returns {JSX.Element}
 */
export default function App() {
  /** Assign Variables */
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState({message: '', panelClass: ''});

  const theme = createTheme({
    palette: {
      primary: {
        main: indigo[600],
      },
      secondary: {
        main: red[800],
      },
    },
  });

  /** Set up listeners */
  useEffect(() => {
    const subscription = UtilsService.getCommunicate().subscribe(message => {
      const loaderEl = document.getElementById("loader");

      if (message.action == ActionNames.SHOW_TOAST) {
        setShowToast(true);
        setToastData(message.data)
      }
      else if (message.action == ActionNames.SHOW_LOADER) {
        if (loaderEl != null)
          loaderEl.style.display="unset";
      }
      else if (message.action == ActionNames.HIDE_LOADER) {
        if (loaderEl != null)
          loaderEl.style.display="none";
      }
    })

    /** Initial App Storage */
    store.dispatch(AUTHENTICATION_ACTIONS.AUTHENTICATION_INITIAL_APP())

    return () => {  
      subscription.unsubscribe();
    }
  }, [])
  
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <div className="vh-100 vw-100 position-fixed loader-backdrop" style={{display: "none"}} id="loader">
          <div className="el-center">
            <div className="loader"></div>
          </div>
        </div>
        <Helmet>
          <title>Meat Research</title>
        </Helmet>
        <Router>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/">
              <DashboardPage />
            </Route>
          </Switch>
        </Router>
        <Snackbar
          className       = {toastData.panelClass || "bg-success"}
          autoHideDuration= {4000}
          onClose         = {() => setShowToast(false)}
          open            = {showToast}
          message         = {toastData.message}
          action          = {
            <Button size="small" className="text-white" onClick={() => setShowToast(false)}>
              DISMISS
            </Button>
          }
        />
      </ThemeProvider>
    </div>
  );
}