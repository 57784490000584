import { Pagination } from "../../interfaces/common/pagination.interface"
import { CompanyPositionInterface } from "../../interfaces/schema/company-position.interface"

function FETCH(pagination?: Pagination) {
  return {
    type: COMPANY_POSITION_ACTION_TYPES.FETCH,
    payload: {
      pagination: pagination || {}
    }
  }
}

function FETCH_SUCCESS(results: CompanyPositionInterface[], count: number) {
  return {
    type: COMPANY_POSITION_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

function SAVE(payload: CompanyPositionInterface, cb?: () => void) {
  return {
    type: COMPANY_POSITION_ACTION_TYPES.SAVE,
    payload: {
      payload,
      cb
    }
  }
}

function SAVE_SUCCESS(result: CompanyPositionInterface) {
  return {
    type: COMPANY_POSITION_ACTION_TYPES.SAVE_SUCCESS,
    payload: {
      result
    }
  }
}

function ARCHIVE(positionId: string, cb: () => void) {
  return {
    type: COMPANY_POSITION_ACTION_TYPES.ARCHIVE,
    payload: {
      cb,
      positionId
    }
  }
}

function ARCHIVE_SUCCESS(positionId: string) {
  return {
    type: COMPANY_POSITION_ACTION_TYPES.ARCHIVE_SUCCESS,
    payload: {
      positionId
    }
  }
}

export const COMPANY_POSITION_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  ARCHIVE,
  ARCHIVE_SUCCESS
}

export const COMPANY_POSITION_ACTION_TYPES = {
  FETCH: '[COMPANY POSITION] FETCH',
  FETCH_SUCCESS: '[COMPANY POSITION] FETCH SUCCESS',
  SAVE: '[COMPANY POSITION] SAVE',
  SAVE_SUCCESS: '[COMPANY POSITION] SAVE SUCCESS',
  ARCHIVE: '[COMPANY POSITION] ARCHIVE',
  ARCHIVE_SUCCESS: '[COMPANY POSITION] ARCHIVE SUCCESS'
}