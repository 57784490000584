import { ResourceInterface } from "../../interfaces/schema/resource.interface";
import { RequestService } from "../common/request.service";

export const ResourceService = {
  /**
   * Fetch resource
   */ 
   fetch() {
    const _http   = new RequestService();
    return _http.get(`/admin/resources`).then(res => res.response);
  },

  /**
   * Insert or update resource
   * @param payload 
   */
   save(payload: ResourceInterface) {
    const _http   = new RequestService();
    return _http.post(`/admin/resources`, payload).then(res => res.response?.result);
  },

  /**
   * Archive resource
   * @param resourceId 
   */
  archive(resourceId: string) {
    const _http = new RequestService();
    return _http.delete(`/admin/resources/${resourceId}`).then(res => res.response);
  }
}