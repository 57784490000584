import store from './../store';

export const GlobalConfigGetter = {
  item   : (GlobalConfigId: number) => 
    store.getState().globalConfig.entities?.find(entity => entity.serial_number == GlobalConfigId),

  listAll: () => 
    store.getState().globalConfig.entities,
    
  count  : () => 
    store.getState().globalConfig.count,
}