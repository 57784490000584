import { NavigationList } from '../../constants/system/navigation-list.const';
import store from './../store';

export const NavigationGetter = {
  listAvailable: () => {
    return listAvailable(store.getState()?.authentication?.resources);
  },
  isPermitted: () => {
    return listAvailable(store.getState()?.authentication?.resources)
      ?.some(navigation => navigation.path == location.pathname.split('/').slice(0,2).join("/"));
  }
}

function listAvailable(resources: any[]) {
  return NavigationList.filter(navigationItem => {
    return navigationItem.released != false 
      && navigationItem.hide != true 
      && resources?.some((resource: any) => {
        return navigationItem.resources?.some(r => 
          r.name == resource.resource_name && r.permission.some(
            permission => (permission == "*" && (resource.can_view || resource.can_create || resource.can_delete || resource.can_edit)) || resource[permission]
          )
        )
      });
  })
}