import { KILLDataMiddleware } from './kill-data/kill-data.middleware';
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { AuthenticationMiddleware } from './authentication/authentication.middleware';
import { AuthenticationReducer } from './authentication/authentication.reducer';
import { GradeDataMiddleware } from './grade-data/grade-data.middleware';
import { GradeDataReducer } from './grade-data/grade-data.reducer';
import { ProjectMiddleware } from './project/project.middleware';
import { ProjectReducer } from './project/project.reducer';
import { SessionRecordMiddleware } from './session-record/session-record.middleware';
import { SessionRecordReducer } from './session-record/session-record.reducer';
import { SessionTypeMiddleware } from './session-type/session-type.middleware';
import { SessionTypeReducer } from './session-type/session-type.reducer';
import { SessionMiddleware } from './session/session.middleware';
import { SessionReducer } from './session/session.reducer';
import { KillDataReducer } from './kill-data/kill-data.reducer';
import { GlobalConfigReducer } from './global-config/global-config.reducer';
import { GlobalConfigMiddleware } from './global-config/global-config.middleware';
import { RoleReducer } from './role/role.reducer';
import { RoleMiddleware } from './role/role.middleware';
import { ResourceMiddleware } from './resource/resource.middleware';
import { ResourceReducer } from './resource/resource.reducer';
import { LoginReducer } from './logins/logins.reducer';
import { LoginMiddleware } from './logins/logins.middleware';
import { PersonReducer } from './person/person.reducer';
import { PersonMiddleware } from './person/person.middleware';
import { CompanyPositionMiddleware } from './company-position/company-position.middleware';
import { CompanyPositionReducer } from './company-position/company-position.reducer';
import { CompanyReducer } from './company/company.reducer';
import { CompanyMiddleware } from './company/company.middleware';
import { KillDataConfigReducer } from './kill-data-config/kill-data-config.reducer';
import { KillDataConfigMiddleware } from './kill-data-config/kill-data-config.middleware';

const reducer = combineReducers({
  authentication  : AuthenticationReducer,
  session         : SessionReducer,
  sessionRecord   : SessionRecordReducer,
  project         : ProjectReducer,
  sessionType     : SessionTypeReducer,
  gradeData       : GradeDataReducer,
  killData        : KillDataReducer,
  globalConfig    : GlobalConfigReducer,
  role            : RoleReducer,
  resource        : ResourceReducer,
  login           : LoginReducer,
  person          : PersonReducer,
  companyPosition : CompanyPositionReducer,
  company         : CompanyReducer,
  killDataConfig  : KillDataConfigReducer
});

const store = configureStore({
  middleware: [
    AuthenticationMiddleware,
    SessionMiddleware,
    SessionRecordMiddleware,
    SessionTypeMiddleware,
    ProjectMiddleware,
    GradeDataMiddleware,
    KILLDataMiddleware,
    GlobalConfigMiddleware,
    RoleMiddleware,
    ResourceMiddleware,
    LoginMiddleware,
    PersonMiddleware,
    CompanyMiddleware,
    CompanyPositionMiddleware,
    KillDataConfigMiddleware
  ],
  reducer,

})
export default store;
