import { RequestService } from "../../services/common/request.service";
import { CompanyRoleItem } from '../../interfaces/page-state/dashboard-page-state.interface';
import * as qs from 'qs';
import { StorageService } from "../../services/common/storage.service";
import { SESSION_TYPE_ACTION, SESSION_TYPE_ACTION_TYPES } from "./session-type.actions";
import { SessionTypeService } from "../../services/store/session-type.service";
import { SessionTypeInterface } from "../../interfaces/schema/session-type.interface";
import { UtilsService } from "../../services/common/utils.service";

const {
  FETCH_SUCCESS,
  SAVE_SUCCESS,
  ARCHIVE_SUCCESS
} = SESSION_TYPE_ACTION;

export const SessionTypeMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any, projectId: string}) => {
  const _http   = new RequestService();
  const storage = new StorageService();
  const cacheKey= `${action.type}-${action.projectId}`;
  /** Fetch session types by project id */
  if (action.type === SESSION_TYPE_ACTION_TYPES.FETCH) {
    try {
        /** Get current role */
        const companyRole  : CompanyRoleItem = store.getState()?.authentication?.role?.role;
        const { response } = await _http.get(`/projects/${action.projectId}/session-types?${qs.stringify(action.payload)}`);
        if (action.payload.cb) action.payload.cb(response)
        /** Save to cache */
        storage.set(cacheKey, response);
        return store.dispatch(FETCH_SUCCESS(response.results, response.count));
    }  
    catch (error) {
      console.error(SESSION_TYPE_ACTION_TYPES.FETCH, error);
      return ;
    }
  }
  else if (action.type === SESSION_TYPE_ACTION_TYPES.SAVE) {
    try {
      const newSessionType: SessionTypeInterface = await SessionTypeService.save(action.payload.payload);
      UtilsService.showToast("Saved");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(SAVE_SUCCESS(newSessionType))
    } catch (error) {
      return console.error(SESSION_TYPE_ACTION_TYPES.SAVE, error);
    }
  }
  else if (action.type === SESSION_TYPE_ACTION_TYPES.ARCHIVE) {
    try {
      await SessionTypeService.archive(action.projectId, action.payload.sessionTypeId);
      UtilsService.showToast("Archived");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(ARCHIVE_SUCCESS(action.payload.sessionTypeId))
    } catch (error) {
      return console.error(SESSION_TYPE_ACTION_TYPES.ARCHIVE, error);
    }
  }
  else {
    return next(action);
  }
}