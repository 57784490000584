import { RequestService } from "../../services/common/request.service";
import { CompanyRoleItem } from '../../interfaces/page-state/dashboard-page-state.interface';
import * as qs from 'qs';
import { StorageService } from "../../services/common/storage.service";
import { GLOBAL_CONFIG_ACTION, GLOBAL_CONFIG_ACTION_TYPES } from "./global-config.actions";

const {
  FETCH_ITEM_SUCCESS,
  FETCH_SUCCESS
} = GLOBAL_CONFIG_ACTION;

export const GlobalConfigMiddleware = (store: any) => (next: any) => async (action: {cb: any, type: string, payload: any, projectId: string}) => {
  const _http   = new RequestService();
  /** Fetch session types by project id */
  if (action.type === GLOBAL_CONFIG_ACTION_TYPES.FETCH) {
    try {
        /** Get current role */
        const { response } = await _http.get(`/admin/global-configs/summary?${qs.stringify(action.payload)}`);

        return store.dispatch(FETCH_SUCCESS(response.results, response.count));
    }  
    catch (error) {
      console.error(GLOBAL_CONFIG_ACTION_TYPES.FETCH, error);
      return ;
    }
  }
  else if (action.type === GLOBAL_CONFIG_ACTION_TYPES.FETCH_ITEM) {
    try {
        /** Get current role */
        const { response } = await _http.get(`/admin/global-configs/${action.payload}`);
        if (action.cb)
          action.cb(response.result)
        return store.dispatch(FETCH_ITEM_SUCCESS(response.result));
    }  
    catch (error) {
      console.error(GLOBAL_CONFIG_ACTION_TYPES.FETCH, error);
      return ;
    }
  }
  else if (action.type === GLOBAL_CONFIG_ACTION_TYPES.SAVE) {
    try {
      /** Get current role */
      const { response } = await _http.post(`/admin/global-configs`, action.payload);
      if (action.cb)
        action.cb();
      return store.dispatch(FETCH_ITEM_SUCCESS(response.result));
    }  
    catch (error) {
      console.error(GLOBAL_CONFIG_ACTION_TYPES.FETCH, error);
      return ;
    }
  }
  else {
    return next(action);
  }
}