import store from '../store';

export const PersonGetter = {
  item   : (personId: string) => 
    store.getState().person.entities?.find(entity => entity._id == personId),

  listAll: () => 
    store.getState().person.entities,
    
  count  : () => 
    store.getState().person.count,
}