import React, { useEffect, useRef, useState } from "react";
import { SessionRecordInterface } from "../../../../interfaces/schema/session-record.interface";
import { SessionInterface } from "../../../../interfaces/schema/session.interface";
import { SESSION_RECORD_ACTION } from "../../../../store/session-record/session-record.actions";
import { SessionRecordGetter } from "../../../../store/session-record/session-record.getters";
import store from "../../../../store/store";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import ConfirmDialog from "../../../../dialogs/common/confirm.dialog";
import { SESSION_ACTION } from "../../../../store/session/session.actions";
import {EditSessionRecordDialog} from "../../../../dialogs/session/edit-session-record.dialog";
import {EditSessionDialog} from "../../../../dialogs/session/edit-session.dialog";

enum ConfirmTypes {
  ARCHIVE_SESSION = "archive session",
  ARCHIVE_SESSION_RECORD = "archive session record"
}

export default function SessionRecordsComponent(props: {
  row: SessionInterface,
  archive_mode: boolean
}) {

  const editSessionRecordDialogRef = useRef() as React.MutableRefObject<{initialEditingItem: (row: SessionRecordInterface) => void}>;
  const editingSessionDialogRef = useRef() as React.MutableRefObject<{initialEditingItem: (row: SessionInterface) => void}>;

  /** 
   * Initial state variables
   */
  const [sessionRecords, setSessionRecords] = useState([] as SessionRecordInterface[])
  const [showConfirm,    setShowConfirm]    = useState(false as boolean)
  const [confirmMessage, setConfirmMessage] = useState("" as string)
  const [confirmType,    setConfirmType]    = useState(ConfirmTypes.ARCHIVE_SESSION as ConfirmTypes)
  const [selectedSessionRecord, setSelectedSessionRecord] = useState({} as SessionRecordInterface)
  const [showEditSession,       setShowEditSession]       = useState(false as boolean)
  const [showEditSessionRecord, setShowEditSessionRecord] = useState(false as boolean)
  
  /** 
   * Initial Hooks
   */
  useEffect(() => {
    fetchSessionRecords();

    const unsubscribe = store.subscribe(() => {
      setSessionRecords(SessionRecordGetter.listAll())
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Fetch session records
   */
  const fetchSessionRecords = () => {
    if (props.row.project?._id) {
      store.dispatch(SESSION_RECORD_ACTION.FETCH({
        projectId: props.row.project._id as string,
        sessionId: props.row._id as string,
        pagination: {
          archive_mode: props.archive_mode
        }
      }))
    }
  }

  /**
   * Confirm archive session
   */
  const confirmArchiveSession = () => {
    setShowConfirm(true);
    setConfirmType(ConfirmTypes.ARCHIVE_SESSION);
    setConfirmMessage(`Archive ${props.row.session_type?.session_type_name} #${props.row.serial_number}`);
  }

  /**
   * Confirm archive session
   */
  const confirmArchiveSessionRecord = (record: SessionRecordInterface) => {
    setShowConfirm(true);
    setConfirmType(ConfirmTypes.ARCHIVE_SESSION_RECORD);
    setSelectedSessionRecord(record);
    setConfirmMessage(`Archive ${props.row.session_type?.session_type_name} #${props.row.serial_number} Record No. ${record.session_progress}`);
  }

  /** 
   * Handle confirm by user
   */
  const handelConfirm = () => {
    if (confirmType == ConfirmTypes.ARCHIVE_SESSION) {
      store.dispatch(SESSION_ACTION.ARCHIVE_SESSION({
        projectId: props.row.project?._id || "",
        sessionId: props.row._id || ""
      }));
    }
    else if (confirmType == ConfirmTypes.ARCHIVE_SESSION_RECORD) {
      store.dispatch(SESSION_RECORD_ACTION.ARCHIVE_RECORD({
        projectId: props.row.project?._id || "",
        sessionId: props.row._id || "",
        recordId : selectedSessionRecord._id || ""
      }));
    }
  }

  return <React.Fragment>
    <div className="w-100">
      <EditSessionRecordDialog
        ref={editSessionRecordDialogRef}
        record={selectedSessionRecord}
        show={showEditSessionRecord}
        setShow={setShowEditSessionRecord}
        session={props.row}
      ></EditSessionRecordDialog>

      <EditSessionDialog
        show={showEditSession}
        ref={editingSessionDialogRef}
        setShow={setShowEditSession}
        session={props.row}
      ></EditSessionDialog>
      <ConfirmDialog
        show={showConfirm}
        setShow={setShowConfirm}
        message={confirmMessage}
        confirm={handelConfirm}
      ></ConfirmDialog>
      <TableContainer component={Paper}>
        <Table className="w-100" size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              {
                !!sessionRecords?.length && 
                Object.keys(sessionRecords[0].session_record_data || {}).filter(key => key).sort((a, b) => b > a ? -1 : 1).map(key => (
                  <TableCell key={key} component="th" scope="row">
                    {key.split("_").join(" ")}
                  </TableCell>
                ))
              }
              {
                !props.archive_mode && 
                <TableCell>Actions</TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {sessionRecords.map((row: SessionRecordInterface) => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {row.session_progress}
                </TableCell>
                {
                  Object.keys(sessionRecords[0].session_record_data || {})
                  .filter(key => key)
                  .sort((a, b) => b > a ? -1 : 1)
                  .map((key: any, index: number) => (
                    <TableCell key={index} scope="row">
                      {row.session_record_data[key] || ""}
                    </TableCell>
                  ))
                }
                {!props.archive_mode && <TableCell component="th" scope="row">
                  <IconButton 
                    color="primary" 
                    size="small" 
                    className="mx-2" 
                    onClick={() => {
                      editSessionRecordDialogRef.current?.initialEditingItem(row)
                      setSelectedSessionRecord(row)
                      setShowEditSessionRecord(true)
                    }}
                  >
                    <CreateIcon />
                  </IconButton>
                  <IconButton 
                    color="secondary" 
                    size="small" 
                    className="mx-2"
                    onClick={() => confirmArchiveSessionRecord(row)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="d-flex">
        {/* <Button variant="contained" color="primary" className="ms-auto me-3 my-2" onClick={() => {
          editSessionRecordDialogRef.current?.initialEditingItem(props.row)
          setShowEditSession(true)}}
        >
          Modify Session
        </Button> */}

        {!props.archive_mode &&<Button variant="contained" color="secondary" className="ms-auto my-2" onClick={confirmArchiveSession}>
          Archive Session
        </Button>}
      </div>
    </div>
  </React.Fragment>
}