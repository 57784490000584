import { LoginInterface } from "../../interfaces/schema/login.interface";
import { RequestService } from "../common/request.service";

export const LoginService = {
  /**
   * Fetch login
   */ 
   fetch() {
    const _http   = new RequestService();
    return _http.get(`/logins`).then(res => res.response);
  },

  /**
   * Insert or update login
   * @param payload 
   */
   save(payload: LoginInterface) {
    const _http   = new RequestService();
    return _http.post(`/logins`, payload).then(res => res.response?.result);
  },

  /**
   * Archive login
   * @param loginId 
   */
  archive(loginId: string) {
    const _http = new RequestService();
    return _http.delete(`/logins/${loginId}`).then(res => res.response);
  },

  /**
   * Send password reset notification
   * 
   * @param email 
   */
  sendPasswordResetEmail(email: string) {
    const _http = new RequestService();
    return _http.post(`/logins/send-password-reset-email`, {email}).then(res => res.response);
  },

  /**
   * Send password reset notification
   * 
   * @param email 
   */
  updatePassword(password: string) {
    const _http = new RequestService();
    return _http.post(`/logins/reset-password`, {password}).then(res => res.response);
  }
}