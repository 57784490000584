import { Pagination } from "../../interfaces/common/pagination.interface"
import { KillDataConfigSummaryInterface } from "../../interfaces/schema/kill-data-config-summary.interface"
import { KillDataConfigInterface } from "../../interfaces/schema/kill-data-config.interface"

function FETCH(pagination?: Pagination) {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.FETCH,
    payload: {
      pagination: pagination || {}
    }
  }
}

function FETCH_SUCCESS(results: KillDataConfigInterface[], count: number) {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

function SAVE(payload: KillDataConfigInterface, cb?: () => void) {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.SAVE,
    payload: {
      payload,
      cb
    }
  }
}

function SAVE_SUCCESS(result: KillDataConfigInterface) {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.SAVE_SUCCESS,
    payload: {
      result
    }
  }
}

function FETCH_ITEM(killDataConfigId: string, cb?: (result: KillDataConfigInterface) => void) {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.FETCH_ITEM,
    payload: {
      killDataConfigId,
      cb
    }
  }
}

function FETCH_ITEM_SUCCESS(result: KillDataConfigInterface) {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.FETCH_ITEM_SUCCESS,
    payload: {
      result
    }
  }
}

function ARCHIVE(killDataConfigId: string, cb: () => void) {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.ARCHIVE,
    payload: {
      cb,
      killDataConfigId
    }
  }
}

function ARCHIVE_SUCCESS(killDataConfigId: string) {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.ARCHIVE_SUCCESS,
    payload: {
      killDataConfigId
    }
  }
}

function FETCH_SUMMARY() {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.FETCH_SUMMARY,
  }
}

function FETCH_SUMMARY_SUCCESS(summary: KillDataConfigSummaryInterface[]) {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.FETCH_SUMMARY_SUCCESS,
    payload: {
      summary
    }
  }
}

function FETCH_AVAILABLE() {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.FETCH_AVAILABLE,
  }
}

function FETCH_AVAILABLE_SUCCESS(results: KillDataConfigSummaryInterface[], count: number) {
  return {
    type: KILL_DATA_CONFIG_ACTION_TYPES.FETCH_AVAILABLE_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

export const KILL_DATA_CONFIG_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_AVAILABLE,
  FETCH_AVAILABLE_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  ARCHIVE,
  ARCHIVE_SUCCESS,
  FETCH_ITEM,
  FETCH_ITEM_SUCCESS,
  FETCH_SUMMARY,
  FETCH_SUMMARY_SUCCESS
}

export const KILL_DATA_CONFIG_ACTION_TYPES = {
  FETCH_AVAILABLE: '[KILL_DATA_CONFIG] FETCH AVAILABLE',
  FETCH_AVAILABLE_SUCCESS: '[KILL_DATA_CONFIG] FETCH AVAILABLE SUCCESS',
  FETCH: '[KILL_DATA_CONFIG] FETCH',
  FETCH_SUCCESS: '[KILL_DATA_CONFIG] FETCH SUCCESS',
  SAVE: '[KILL_DATA_CONFIG] SAVE',
  SAVE_SUCCESS: '[KILL_DATA_CONFIG] SAVE SUCCESS',
  ARCHIVE: '[KILL_DATA_CONFIG] ARCHIVE',
  ARCHIVE_SUCCESS: '[KILL_DATA_CONFIG] ARCHIVE SUCCESS',
  FETCH_ITEM: '[KILL_DATA_CONFIG] FETCH ITEM',
  FETCH_ITEM_SUCCESS: '[KILL_DATA_CONFIG] FETCH ITEM SUCCESS',
  FETCH_SUMMARY: '[KILL_DATA_CONFIG] FETCH SUMMARY',
  FETCH_SUMMARY_SUCCESS: '[KILL_DATA_CONFIG] FETCH SUMMARY SUCCESS',
}