import { Pagination } from "../../interfaces/common/pagination.interface"
import { ImportedFileInterface } from "../../interfaces/schema/imported-file.interface"

function UPLOAD(file: any, cb?: () => void) {
  return {
    type: GRADE_DATA_ACTION_TYPES.UPLOAD,
    payload: {
      file,
      cb
    }
  }
}

function FETCH(pagination?: Pagination, cb?: (...args: any) => void) {
  return {
    type: GRADE_DATA_ACTION_TYPES.FETCH,
    payload: {
      pagination,
      cb
    }
  }
}

function FETCH_SUCCESS(results: ImportedFileInterface[], count: number) {
  return {
    type: GRADE_DATA_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

function ARCHIVE(process_id: string, cb?: (...args: any) => void) {
  return {
    type: GRADE_DATA_ACTION_TYPES.ARCHIVE,
    payload: {
      process_id,
      cb
    }
  }
}

function ARCHIVE_SUCCESS(process_id: string) {
  return {
    type: GRADE_DATA_ACTION_TYPES.ARCHIVE_SUCCESS,
    payload: { process_id }
  }
}

function UPDATE(process_id: string, file: ImportedFileInterface, cb?: (...args: any) => void) {
  return {
    type: GRADE_DATA_ACTION_TYPES.UPDATE,
    payload: {
      process_id,
      file,
      cb
    }
  }
}

function UPDATE_SUCCESS(file: ImportedFileInterface) {
  return {
    type: GRADE_DATA_ACTION_TYPES.UPDATE_SUCCESS,
    payload: {
      file
    }
  }
}


function FETCH_CATEGORIES() {
  return {
    type: GRADE_DATA_ACTION_TYPES.FETCH_CATEGORIES,
  }
}

function FETCH_CATEGORIES_SUCCESS(categories: string[]) {
  return {
    type: GRADE_DATA_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS,
    payload: {
      categories
    }
  }
}

function FETCH_CATEGORY_FIELDS(category: string) {
  return {
    type: GRADE_DATA_ACTION_TYPES.FETCH_CATEGORY_FIELDS,
    payload: {
      category
    }
  }
}

function FETCH_CATEGORY_FIELDS_SUCCESS(category: string, fields: string[]) {
  return {
    type: GRADE_DATA_ACTION_TYPES.FETCH_CATEGORY_FIELDS_SUCCESS,
    payload: {
      category,
      fields
    }
  }
}

export const GRADE_DATA_ACTIONS = {
  UPLOAD,
  FETCH,
  FETCH_SUCCESS,
  ARCHIVE,
  ARCHIVE_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORY_FIELDS,
  FETCH_CATEGORY_FIELDS_SUCCESS
}

export const GRADE_DATA_ACTION_TYPES = {
  UPLOAD: '[GRADE DATA] UPLOAD',
  FETCH: '[GRADE DATA] FETCH',
  FETCH_SUCCESS: '[GRADE DATA] FETCH SUCCESS',
  ARCHIVE: '[GRADE DATA] ARCHIVE',
  ARCHIVE_SUCCESS: '[GRADE DATA] ARCHIVE SUCCESS',
  UPDATE: '[GRADE DATA] UPDATE',
  UPDATE_SUCCESS: '[GRADE DATA] UPDATE SUCCESS',
  FETCH_CATEGORIES: '[GRADE DATA] FETCH CATEGORIES',
  FETCH_CATEGORIES_SUCCESS: '[GRADE DATA] FETCH CATEGORIES SUCCESS',
  FETCH_CATEGORY_FIELDS: '[GRADE DATA] FETCH CATEGORY FIELDS',
  FETCH_CATEGORY_FIELDS_SUCCESS: '[GRADE DATA] FETCH CATEGORY FIELDS SUCCESS'
}