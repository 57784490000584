import { SessionTypeInterface } from './../../interfaces/schema/session-type.interface';
import { Pagination } from "../../interfaces/common/pagination.interface"
import { SessionInterface } from '../../interfaces/schema/session.interface';

function FETCH(projectId: string, pagination?: Pagination) {
  return {
    type: SESSION_ACTION_TYPES.FETCH,
    projectId,
    payload: {
      pagination: pagination || {}
    }
  }
}

function FETCH_SUCCESS(results: SessionInterface[], count: number, append: boolean = true) {
  return {
    type: SESSION_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      append,
      results,
      count
    }
  }
}

function SAVE(projectId: string, payload: SessionInterface) {
  return {
    type: SESSION_ACTION_TYPES.SAVE,
    projectId,
    payload: payload
  }
}

function SAVE_SUCCESS(result: SessionInterface) {
  return {
    type: SESSION_ACTION_TYPES.SAVE_SUCCESS,
    payload: {
      result
    }
  }
}

function FETCH_VIEW(pagination: Pagination) {
  return {
    type: SESSION_ACTION_TYPES.FETCH_VIEW,
    payload: pagination
  }
}

function FETCH_VIEW_SUCCESS(res: {results: SessionInterface[], count: number}) {
  return {
    type: SESSION_ACTION_TYPES.FETCH_VIEW_SUCCESS,
    payload: {
      results: res.results,
      count: res.count
    }
  }
}

function RESUME_SESSION() {
  return {
    type: SESSION_ACTION_TYPES.RESUME_SESSION
  }
}

function ARCHIVE_SESSION(options: {
  projectId: string,
  sessionId: string
}) {
  return {
    type: SESSION_ACTION_TYPES.ARCHIVE_SESSION,
    projectId: options.projectId,
    payload: options.sessionId
  }
}

function ARCHIVE_SESSION_SUCCESS(sessionId: string) {
  return {
    type: SESSION_ACTION_TYPES.ARCHIVE_SESSION_SUCCESS,
    payload: sessionId

  }
}

function MODIFY_SESSION(options: {
  projectId: string
  session: SessionInterface
}) {
  return {
    type: SESSION_ACTION_TYPES.MODIFY_SESSION,
    projectId: options.projectId,
    payload: options.session
  }
}

function MODIFY_SESSION_SUCCESS(session: SessionInterface) {
  return {
    type: SESSION_ACTION_TYPES.MODIFY_SESSION_SUCCESS,
    payload: session
  }
}

function MODIFY_META_DATA(options: {
  projectId: string
  sessionId: string
  session : SessionInterface
}) {
  return {
    type: SESSION_ACTION_TYPES.MODIFY_META_DATA,
    projectId: options.projectId,
    sessionId: options.sessionId,
    payload: options.session
  }
}

export const SESSION_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_VIEW,
  FETCH_VIEW_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  RESUME_SESSION,
  ARCHIVE_SESSION,
  ARCHIVE_SESSION_SUCCESS,
  MODIFY_SESSION,
  MODIFY_SESSION_SUCCESS,
  MODIFY_META_DATA
}

export const SESSION_ACTION_TYPES = {
  FETCH: '[SESSION] FETCH',
  FETCH_SUCCESS: '[SESSION] FETCH SUCCESS',
  SAVE: '[SESSION] SAVE',
  SAVE_SUCCESS: '[SESSION] SAVE SUCCESS',
  RESUME_SESSION: '[SESSION] RESUME SESSION',
  
  FETCH_VIEW: '[SESSION] FETCH VIEW',
  FETCH_VIEW_SUCCESS: '[SESSION] FETCH_VIEW_SUCCESS',

  ARCHIVE_SESSION: '[SESSION] ARCHIVE SESSION',
  ARCHIVE_SESSION_SUCCESS: '[SESSION] ARCHIVE SESSION SUCCESS',

  MODIFY_SESSION: '[SESSION] MODIFY SESSION',
  MODIFY_SESSION_SUCCESS: '[SESSION] MODIFY SESSION SUCCESS',

  MODIFY_META_DATA         : '[SESSION] MODIFY META DATA',
  MODIFY_META_DATA_SUCCESS : '[SESSION] MODIFY META DATA SUCCESS',
}