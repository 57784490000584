
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import store from '../../../../store/store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { cloneDeep } from 'lodash';
import { UtilsService } from '../../../../services/common/utils.service';
import { GlobalStyleConstant } from '../../../../constants/styles/global.const';
import { CompanyPositionInterface } from '../../../../interfaces/schema/company-position.interface';
import { COMPANY_POSITION_ACTION } from '../../../../store/company-position/company-position.actions';
import { CompanyPositionGetter } from '../../../../store/company-position/company-position.getter';

/**
 * Company Positions Page
 * @returns {JSX.Element}
 */
export default function CompanyPositionsPage() {

  const [positions, setPositions] = useState([] as CompanyPositionInterface[])
  const [showDialog, setShowDialog] = useState(false)
  const [editingItem, setEditingItem] = useState({} as CompanyPositionInterface)

  useEffect(() => {
    store.dispatch(COMPANY_POSITION_ACTION.FETCH());
    const unsubscribe = store.subscribe(() => {
      setPositions(CompanyPositionGetter.listAll())
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Pop up edit dialog
   * 
   * @param position 
   */
  const editPosition = (position?: CompanyPositionInterface) => {
    setEditingItem(cloneDeep(position || {}))
    setShowDialog(true);
  }

  const save = () => {
    store.dispatch(COMPANY_POSITION_ACTION.SAVE(editingItem, () => {
      setShowDialog(false);
      UtilsService.showToast("Saved");
    }));
  }

  return (
    <div>
      <div className="d-flex mb-3">
        <span className="h3">Company Positions</span>
        <Button variant="contained" color="primary" className="ms-auto" onClick={() => editPosition()}>Create Position</Button>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Position Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {positions.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell component="th" scope="row">
                    {row.position_name}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Config">
                      <IconButton onClick={() => editPosition(row)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="lg">
        <DialogTitle className="pb-0">{editingItem._id ? "Edit" : "Create"} Company Position</DialogTitle>
        <DialogContent style={{width: GlobalStyleConstant.dialogMd}}>
          <TextField
            autoFocus
            margin="dense"
            label="Position Name"
            value={editingItem.position_name}
            onChange={(e) => {
              setEditingItem({
                ...editingItem,
                position_name: e.target.value
              })
            }}
            fullWidth
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => save()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
