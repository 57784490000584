import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import store from '../../../store/store';

/** Table lib */
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import { Tooltip, IconButton, Switch, FormControlLabel, TextField, Button, Icon } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { SessionInterface } from '../../../interfaces/schema/session.interface';
import { Pagination } from '../../../interfaces/common/pagination.interface';
import { SESSION_ACTION } from '../../../store/session/session.actions';
import { SessionGetter } from '../../../store/session/session.getters';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { SESSION_TYPE_ACTION } from '../../../store/session-type/session-type.actions';
import { PROJECT_ACTION } from '../../../store/project/project.actions';
import { ProjectInterface } from '../../../interfaces/schema/project.interface';
import { SessionTypeInterface } from '../../../interfaces/schema/session-type.interface';
import { SessionTypeGetter } from '../../../store/session-type/session-type.getters';
import { ProjectGetter } from '../../../store/project/project.getters';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SessionRecordsComponent from './session-records/session-records.component';
import { GRADE_DATA_ACTIONS } from '../../../store/grade-data/grade-data.actions';
import { UtilsService } from '../../../services/common/utils.service';
import { EditSessionMetaDataDialog } from '../../../dialogs/session/edit-session-meta-data.dialog';

function SessionRow(props: {
  rowIndex: number, 
  row: SessionInterface,
  openedRow: number,
  archiveMode: boolean,
  setOpenedRow: (index: number) => void
}) {
  const row = props.row;
  const editSessionMetaDataDialogRef = useRef() as React.MutableRefObject<{initialEditingItem: (row: SessionInterface) => void}>;
  const [showEditSessionMetaDataDialog, setShowEditSessionMetaDataDialog] = useState(false as boolean)

  return <React.Fragment>
    <TableRow key={row._id}>
      <TableCell component="th" scope="row">
        <span>{row.serial_number}</span>
      </TableCell>
      <TableCell>
        <span>{row.session_type?.session_type_name}</span>
      </TableCell>
      <TableCell>{row.company?.company_name}</TableCell>
      <TableCell>{row.project?.project_name}</TableCell>
      <TableCell>{row.person_name}</TableCell>
      <TableCell>{row.session_records?.length || 0} / {row.total_number_of_records}</TableCell>
      <TableCell>{row.started_at}</TableCell>
      <TableCell align="right">{row.time_spent || '< 1'}</TableCell>
      <TableCell align="right">
        <Tooltip title="View Records">
          <IconButton 
            onClick={
              () => props.setOpenedRow(props.openedRow == props.rowIndex ? -1 : props.rowIndex)
            } 
            size="small"
          >
            {props.openedRow == props.rowIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={99}>
        <Collapse in={props.openedRow == props.rowIndex} timeout="auto" unmountOnExit>
          <Box>
            <h6 className="mt-3 mb-1">Meta Data</h6>
            <TableContainer component={Paper}>
              <Table className="w-100" size="small">
                <TableHead>
                  <TableRow>
                    {
                      Object.keys(row.session_meta_data).map((key: string, keyIndex: number) => (
                        <TableCell key={keyIndex}>
                          {key?.split("_").join(" ")}
                        </TableCell>
                      ))
                    }
                    <TableCell>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {
                      Object.keys(row.session_meta_data).map((key: string, keyIndex: number) => (
                        <TableCell key={keyIndex}>
                          {row.session_meta_data[key]}
                        </TableCell>
                      ))
                    }
                    <TableCell>
                      <IconButton 
                        color="primary" 
                        size="small" 
                        className="mx-2" 
                        onClick={() => {
                          setShowEditSessionMetaDataDialog(true);
                          editSessionMetaDataDialogRef.current.initialEditingItem(props.row);
                        }}
                      >
                        <Icon>create</Icon>
                    </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <EditSessionMetaDataDialog
              ref={editSessionMetaDataDialogRef}
              show={showEditSessionMetaDataDialog}
              setShow={setShowEditSessionMetaDataDialog}
              session={props.row}
            ></EditSessionMetaDataDialog>  
            <h6 className="mt-3 mb-1">Records</h6>
            <SessionRecordsComponent row={row} archive_mode={props.archiveMode} />
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
}

/**
 * Data Export Page
 * 
 * @returns {JSX.Element}
 */
export default function DataExportPage() {

  const [rows, setRows]             = useState([] as SessionInterface[]);
  const [openedRow, setOpenedRow]   = useState(-1 as number);
  const [count, setCount]           = useState(0);
  const [testMode, setTestMode]         = useState(false);
  const [archiveMode, setArchiveMode]   = useState(false)
  const [projects, setProjects]         = useState([] as ProjectInterface[]);
  const [sessionTypes, setSessionTypes] = useState([] as SessionTypeInterface[]);
  const [selectedProject, setSelectedProject] = useState("" as string);
  const [selectedSessionType, setSelectedSessionType] = useState(null as string | null);
  const [pagination, setPagination] = useState({
    index: 0,
    size: 25,
    direction: -1,
    sort: 'serial_number',
    test_mode: false,
    archive_mode: false
  } as Pagination)

  useEffect(() => {
    fetchSessions(pagination);
    fetchProjects();

    const unsubscribe = store.subscribe(() => {
      setCount(SessionGetter.count());
      setRows(SessionGetter.listAll());
      
      setSessionTypes(SessionTypeGetter.listAll());
      setProjects(ProjectGetter.listAll());
    })

    return () => {
      unsubscribe();
    }
  }, []);

  /**
   * Fetch session types
   */
  const fetchSessionTypes = (projectId: string) => {
    if (projectId)
      store.dispatch(SESSION_TYPE_ACTION.FETCH(projectId))
  }

  /**
   * Fetch session types
   */
  const fetchProjects = () => {
    store.dispatch(PROJECT_ACTION.FETCH())
  }

  /**
   * Fetch session data
   * 
   * @param newPagination 
   */
  const fetchSessions = (newPagination: Pagination) => {
    store.dispatch(SESSION_ACTION.FETCH_VIEW(newPagination));
    setPagination(newPagination);
  }

  /**
   * Switch test mode
   * 
   */
  const switchTestMode = () => {
    fetchSessions({
      ...pagination,
      test_mode: !testMode,
      archive_mode: archiveMode
    })
    setTestMode(!testMode);
  }

  const toggleArchiveMode = () => {
    fetchSessions({
      ...pagination,
      test_mode: testMode,
      archive_mode: !archiveMode
    })
    setArchiveMode(!archiveMode);
  }

  return (
    <div>
      <div className="d-flex">
        <span className="h3">Sessions</span>
        <div className="ms-auto">
          <FormControlLabel
            className="mx-2"
            label="Test Mode"
            control={<Switch
              color="secondary"
              checked={testMode}
              onChange={switchTestMode}
            />}
          />
          <FormControlLabel
            className="mx-2"
            label="Archived Sessions"
            control={<Switch
              color="secondary"
              checked={archiveMode}
              onChange={toggleArchiveMode}
            />}
          />
        </div>
      </div>

      <div className="mb-4 mt-2">
        <Accordion className="d-none">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <SearchIcon />
              <b className="ms-2">Advanced Filters</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="d-block">
            <div className="mb-3">
              <TextField className="me-3" label="Session ID" />

              <FormControl className="me-3" style={{width: 300}}>
                <InputLabel id="advanced-search-project-label">Project</InputLabel>
                <Select
                  labelId="advanced-search-project-label"
                  value={selectedProject}
                  onChange={(e) => {
                    if (e.target.value)
                      setSelectedProject(e.target.value as string);
                    fetchSessionTypes(e.target.value as string);
                  }}
                >
                  {
                    projects.map(project => (
                      <MenuItem key={project._id} value={project._id}>
                        {project.serial_number}  - {project.project_name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

              {
                !!selectedProject && (
                  <FormControl className="me-3" style={{width: 300}}>
                  <InputLabel id="advanced-search-session-type-label">Session Type</InputLabel>
                  <Select
                    labelId="advanced-search-session-type-label"
                    value={selectedSessionType}
                    onChange={(e) => {
                      if (e.target.value)
                        setSelectedSessionType(e.target.value as string);
                    }}
                  >
                    {
                      sessionTypes.map(sessionType => (
                        <MenuItem key={sessionType._id} value={sessionType._id}>
                          {sessionType.serial_number}  - {sessionType.session_type_name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                )
              }
            </div>

            
            <div>
              <Button variant="contained" className="me-3 bg-danger text-white">
                Reset
              </Button>
              <Button variant="contained" color="primary">
                Search
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      
      <div>
        <div>
          <TableContainer component={Paper}>
            <Table className="w-100" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Session ID</TableCell>
                  <TableCell>Session Type</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell>Operator</TableCell>
                  <TableCell>Record Count</TableCell>
                  <TableCell>Started At (UTC)</TableCell>
                  <TableCell align="right">Time Spent (Minutes)</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { 
                  rows.map(
                    (row: SessionInterface, rowIndex: number) => 
                      <SessionRow 
                        archiveMode={archiveMode} 
                        openedRow={openedRow} 
                        setOpenedRow={setOpenedRow} 
                        rowIndex={rowIndex} 
                        row={row} 
                        key={row._id}
                      />
                    )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={count}
            component="div"
            rowsPerPage={pagination.size || 25}
            page={pagination.index || 0}
            onPageChange={(e, newIndex) => {
              fetchSessions({
                ...pagination,
                index: newIndex
              });
            }}
            onRowsPerPageChange={(e) => {
              fetchSessions({
                ...pagination,
                size: Number(e.target.value)
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}
