import store from '../store';

export const KillDataConfigGetter = {
  item      : (killDataConfigId: string) => 
    store.getState().killDataConfig.entities?.find(entity => entity._id == killDataConfigId),

  listAll   : () => 
    store.getState().killDataConfig.entities,
    
  count     : () => 
    store.getState().killDataConfig.count,

  summary   : () =>
    store.getState().killDataConfig.summary,

  formatText: (text: string) =>
    text.split('#{index}').join("1")
}