import React, { useEffect, useState } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { AuthenticationGetter } from "../../store/authentication/authentication.getters";
import { PersonInterface } from "../../interfaces/schema/person.interface";

export default function DeclarationComponent(props: {
  checked: boolean,
  setChecked: (checked: boolean) => void
}) {

  const [currentOperator, setCurrentOperator] = useState({} as PersonInterface);

  useEffect(() => {
    
    const operator = AuthenticationGetter.currentOperator() as PersonInterface
    setCurrentOperator(operator);

    return () => {
      
    }
  }, [])

  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            onChange={(e) => {
              props.setChecked(e.target.checked);
            }}
            color="secondary"
          />
        }
        label={`I, ${currentOperator.person_first_name} ${currentOperator.person_last_name} hereby declare that all the above information is correct and accurate.`}
      />
    </React.Fragment>
  )
}