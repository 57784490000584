import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import store from '../../../store/store';
import { useHistory } from 'react-router';


/**
 * API Credential Page
 * @returns {JSX.Element}
 */
export default function ApiCredentialPage() {

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      
    })

    return () => {
      unsubscribe();
    }
  }, [])

  return (
    <div>
      
    </div>
  );
}
