import { KILL_DATA_ACTION_TYPES } from "./kill-data.actions";



export interface KillDataStateInterface {
  records: string[][],
  headers: string[],
  summary: {
    source: string,
    count: number
  }[]
  nestedRecords: any[]
}

const initialState: KillDataStateInterface = {
  headers: [],
  records: [],
  summary: [],
  nestedRecords: []
}

export function KillDataReducer(
  state: KillDataStateInterface = initialState, 
  action: {type: string, payload?: any}) 
{
  if (action.type == KILL_DATA_ACTION_TYPES.FETCH_SUCCESS) {
    state.headers = action.payload.headers;
    state.records = action.payload.records;
    state.summary = action.payload.summary;
    state.nestedRecords = action.payload.nestedRecords;
  }
  return state;
}