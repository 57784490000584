import { Button, Chip, IconButton, Paper, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import { PagePaths } from '../../../enums/page-paths.enum';
import store from '../../../store/store';
import { KILL_DATA_CONFIG_ACTION } from '../../../store/kill-data-config/kill-data-config.actions';
import { KillDataConfigGetter } from '../../../store/kill-data-config/kill-data-config.getter';
import { KillDataConfigInterface } from '../../../interfaces/schema/kill-data-config.interface';
import { Delete, Edit, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { UtilsService } from '../../../services/common/utils.service';
import ConfirmDialog from '../../../dialogs/common/confirm.dialog';

/**
 * Config row with expandable preview
 * 
 * @param props 
 * @returns 
 */
 function ConfigRow(props: {
  rowIndex: number, 
  row: KillDataConfigInterface,
  openedRow: number,
  edit: (project: KillDataConfigInterface) => void,
  archive: (project: KillDataConfigInterface) => void,
  setOpenedRow: (index: number) => void
}) {
  const row = props.row;

  return <React.Fragment>
    <TableRow key={row._id}>
      <TableCell component="th" scope="row">
        {row.config_name}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.config_columns.length}
      </TableCell>
      <TableCell component="th" scope="row">
        {
          row.projects?.map((project, projectIndex) => {
            return <Chip className="me-1" key={projectIndex} label={project.project_name}/>
          })
        }
      </TableCell>
      <TableCell align="right">
        <Tooltip title="Edit Config">
          <IconButton onClick={() => props.edit(row)} size="small" color="primary">
            <Edit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Archive Config">
          <IconButton onClick={() => props.archive(row)} size="small" color="secondary">
            <Delete />
          </IconButton>
        </Tooltip>

        {/* <Tooltip title="Preview Columns">
          <IconButton 
            onClick={
              () => props.setOpenedRow(props.openedRow == props.rowIndex ? -1 : props.rowIndex)
            } 
            size="small"
          >
            {props.openedRow == props.rowIndex ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Tooltip> */}
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={99}>
        <Collapse in={props.openedRow == props.rowIndex} timeout="auto" unmountOnExit>
          <Box className="py-3">
            <TableContainer component={Paper}>
              <Table className="w-100" size="small">
                <TableHead>
                  <TableRow>
                    {
                      row.config_columns?.map((col, colIndex) => (
                        <TableCell key={colIndex}>{KillDataConfigGetter.formatText(col.text || "")}</TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
}

/**
 * Kill Data Config Page
 * @returns {JSX.Element}
 */
 export default function KillDataConfigPage() {
  /** Initial Variables */
  const history = useHistory();
  const [count, setCount] = useState(0 as number)
  const [openedRow, setOpenedRow] = useState(-1 as number)
  const [configs, setConfigs] = useState([] as KillDataConfigInterface[])
  const [showConfirm, setShowConfirm] = useState(false as boolean)
  const [archiveSpec, setArchiveSpec] = useState({} as KillDataConfigInterface)
  
  /** Initial Listener */
  useEffect(() => {
    store.dispatch(KILL_DATA_CONFIG_ACTION.FETCH())
    
    const unsubscribe = store.subscribe(() => {
      setCount(KillDataConfigGetter.count());
      setConfigs(KillDataConfigGetter.listAll());
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Edit config
   * 
   * @param config 
   */
  const edit = (config: KillDataConfigInterface) => {
    history.push(PagePaths.KILL_DATA_CONFIG + `/${config._id}/edit`)
  }

  /**
   * Archive config
   * 
   * @param config 
   */
  const archive = (config: KillDataConfigInterface) => {
    setArchiveSpec(config);
    setShowConfirm(true);
  }

  /**
   * Archive data spec
   */
  const archiveDataSpec = () => {
    if (archiveSpec._id)
      store.dispatch(KILL_DATA_CONFIG_ACTION.ARCHIVE(archiveSpec._id, () => {
        UtilsService.showToast("Archived")
      }))
  }

  return (
    <div>
      <ConfirmDialog
        show={showConfirm}
        setShow={setShowConfirm}
        message={`Are you sure you want to archive ${archiveSpec.config_name}?`}
        confirm={archiveDataSpec}
      ></ConfirmDialog>
      <div className="d-flex">
        <span className="h3">Data Specs</span>

        <Button className="ms-auto" variant="contained" color="primary" onClick={() => {
          history.push(PagePaths.KILL_DATA_CONFIG + '/add')
        }}>
          Add Config
        </Button>
      </div>
      <div className="mt-4">
        <TableContainer component={Paper}>
            <Table className="w-100" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Spec Name</TableCell>
                  <TableCell>Column Count</TableCell>
                  <TableCell>Linked Projects</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  configs.map((config, configIndex) => (
                    <ConfigRow
                      key          = {configIndex}
                      rowIndex     = {configIndex}
                      row          = {config}
                      openedRow    = {openedRow}
                      edit         = {edit}
                      archive      = {archive}
                      setOpenedRow = {setOpenedRow}
                    />
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    </div>
  )
 }