import { AUTHENTICATION_ACTION_TYPES } from './authentication.actions';
import { PersonInterface } from '../../interfaces/schema/person.interface';
import { ResourceInterface } from '../../interfaces/schema/resource.interface';
import { CompanyRoleItem } from '../../interfaces/page-state/dashboard-page-state.interface';
import { LoginInterface } from '../../interfaces/schema/login.interface';

export interface AuthenticationStateInterface {
  login     : LoginInterface,
  operators : PersonInterface[],
  operator  : PersonInterface,
  role      : CompanyRoleItem,
  roles     : CompanyRoleItem[],
  resources : ResourceInterface[]
  isLoggedIn: boolean,
  loading   : boolean
  tokens    : {
    token       : string
    refreshToken: string
  }
}

export const authenticationInitialState: AuthenticationStateInterface = {
  login     : {},
  role      : {},
  roles     : [],
  resources : [], 
  operator  : {},
  operators : [],
  isLoggedIn: false,
  loading   : false,
  tokens    : {
    token       : "",
    refreshToken: ""
  }
}

export function AuthenticationReducer(
  state: AuthenticationStateInterface = authenticationInitialState, 
  action: {type: string, payload?: any}) 
{

  if (action.type === AUTHENTICATION_ACTION_TYPES.SHOW_LOADING) {
    state.loading = true;
  }

  else if (action.type === AUTHENTICATION_ACTION_TYPES.SIGNUP_FAILED
    || action.type === AUTHENTICATION_ACTION_TYPES.LOGIN_FAILED) {
    state.loading = false;
  }

  else if (action.type === AUTHENTICATION_ACTION_TYPES.LOGIN_SUCCESS) {

    return {
      loading     : false,
      isLoggedIn  : true,
      login       : action.payload.login,
      operators   : action.payload.operators,
      resources   : action.payload.resources,
      operator    : action.payload.operator   || action.payload.operators[0],
      role        : action.payload.defaultRole || action.payload.login.role || action.payload.login.roles[0],
      roles       : action.payload.login.roles,
      tokens      : {
                      token: action.payload.token,
                      refreshToken: action.payload.refreshToken
                    }
    }
  }
  else if (action.type === AUTHENTICATION_ACTION_TYPES.LOAD_RESOURCES_SUCCESS) {
    state.resources = action.payload || [];
  }
  else if (action.type === AUTHENTICATION_ACTION_TYPES.LOGOUT_SUCCESS) {
    return authenticationInitialState;
  }
  else if (action.type == AUTHENTICATION_ACTION_TYPES.UPDATE_CURRENT_LOGIN_SUCCESS) {
    state.login = action.payload;
  }
  else if (action.type === AUTHENTICATION_ACTION_TYPES.UPDATE_CURRENT_OPERATOR_SUCCESS) {
    const selectedOperatorIndex = state.operators.findIndex(operator => operator._id == action.payload._id);
    if (selectedOperatorIndex >= 0) 
      state.operators[selectedOperatorIndex] = action.payload;
    state.operator = action.payload;
  }
  else if (action.type === AUTHENTICATION_ACTION_TYPES.INITIAL_APP_COMPLETED) {
    return {
      ...state,
      ...action.payload
    };
  }
  return state;
}