import { KillDataConfigInterface } from "../../interfaces/schema/kill-data-config.interface";
import { RequestService } from "../../services/common/request.service";
import { UtilsService } from "../../services/common/utils.service";
import { KillDataConfigService } from "../../services/store/kill-data-config.service";
import { KILL_DATA_CONFIG_ACTION, KILL_DATA_CONFIG_ACTION_TYPES } from "./kill-data-config.actions";

const {
  FETCH_SUCCESS,
  SAVE_SUCCESS,
  FETCH_SUMMARY_SUCCESS,
  FETCH_AVAILABLE_SUCCESS,
  ARCHIVE_SUCCESS,
  FETCH_ITEM_SUCCESS
} = KILL_DATA_CONFIG_ACTION;

export const KillDataConfigMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any, projectId: string}) => {
  const _http   = new RequestService();
  /** 
   * Fetch killDataConfigs
   */
  if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.FETCH) {
    try {
      const {results, count} = await KillDataConfigService.fetch();
      return store.dispatch(FETCH_SUCCESS(results, count));
    }  
    catch (error) {
      console.error(KILL_DATA_CONFIG_ACTION_TYPES.FETCH, error);
      return ;
    }
  }
  /** 
   * Fetch available kill data configs
   */
     if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.FETCH_AVAILABLE) {
      try {
        const {results, count} = await KillDataConfigService.fetchAvailable();
        return store.dispatch(FETCH_AVAILABLE_SUCCESS(results, count));
      }  
      catch (error) {
        console.error(KILL_DATA_CONFIG_ACTION_TYPES.FETCH_AVAILABLE, error);
        return ;
      }
    }
  /** 
   * Fetch killDataConfigs
   */
     if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.FETCH_ITEM) {
      try {
        const { result } = await KillDataConfigService.fetchItem(action.payload.killDataConfigId);
        if (action.payload.cb) action.payload.cb(result);
        return store.dispatch(FETCH_ITEM_SUCCESS(result));
      }  
      catch (error) {
        console.error(KILL_DATA_CONFIG_ACTION_TYPES.FETCH_ITEM_SUCCESS, error);
        return ;
      }
    }
  /** 
   * Save killDataConfig
   */
  else if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.SAVE) {
    try {
      const newKillDataConfig: KillDataConfigInterface = await KillDataConfigService.save(action.payload.payload);
      UtilsService.showToast("Saved");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(SAVE_SUCCESS(newKillDataConfig))
    } catch (error) {
      return console.error(KILL_DATA_CONFIG_ACTION_TYPES.SAVE, error);
    }
  }
  /**
   * Archive killDataConfig
   */
  else if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.ARCHIVE) {
    try {
      await KillDataConfigService.archive(action.payload.killDataConfigId);
      UtilsService.showToast("Archived");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(ARCHIVE_SUCCESS(action.payload.killDataConfigId))
    } catch (error) {
      return console.error(KILL_DATA_CONFIG_ACTION_TYPES.ARCHIVE, error);
    }
  }
  /**
   * fetch summary
   */
  else if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.FETCH_SUMMARY) {
    try {
      const summary = await KillDataConfigService.fetchSummary();
      return store.dispatch(FETCH_SUMMARY_SUCCESS(summary))
    } catch (error) {
      return console.error(KILL_DATA_CONFIG_ACTION_TYPES.ARCHIVE, error);
    }
  }
  else {
    return next(action);
  }
}