import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeclarationComponent from "../../components/common/declaration.component";

export default function ConfirmDialog(props: {
  show: boolean,
  setShow: (show: boolean) => void,
  confirm: () => void,
  message: string
}) {

  const [checked, setChecked] = useState(false as boolean)

  return (
    <React.Fragment>
      <Dialog
        open={props.show}
        onClose={() => props.setShow(false)}
      >
        <DialogTitle>
          Confirmation
        </DialogTitle>
        <DialogContent>
          <p>
            <b className="text-danger">  
              {props.message}
            </b>
          </p>
          <DeclarationComponent checked={checked} setChecked={setChecked}></DeclarationComponent>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => props.setShow(false)} color="primary">
            <b>Cancel</b>
          </Button>
          <Button onClick={props.confirm} color="secondary" disabled={!checked}>
            <b>Confirm</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}