import { KILL_DATA_ACTIONS, KILL_DATA_ACTION_TYPES } from './kill-data.actions';
import { RequestService } from "../../services/common/request.service";
import * as qs from 'qs';
import download from 'downloadjs';
import { UtilsService } from '../../services/common/utils.service';

export const KILLDataMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any}) => {
  const _http = new RequestService();
  
  /** Fetch project by company and role */
  if (action.type === KILL_DATA_ACTION_TYPES.FETCH) {
    const res = await _http.get('/data/kill-data?' + qs.stringify(action.payload));
    
    if (!action.payload.format) {
      return store.dispatch(KILL_DATA_ACTIONS.FETCH_SUCCESS({
        records: res.response.results,
        headers: res.response.headers,
        summary: res.response.summary,
        nestedRecords: res.response.subConfigResults
      }));
    }

    res.response.results = res.response.results
      .sort((rowA: string[], rowB: string) => {
        let aValue = rowA[action.payload.orderBy] as any;
        let bValue = rowB[action.payload.orderBy] as any;
        if (!isNaN(Number(aValue))) aValue = Number(aValue);
        if (!isNaN(Number(bValue))) bValue = Number(bValue);
        const sorted = aValue > bValue ? -1 : 1;
        return sorted * (action.payload.order == 'asc' ? -1 : 1);
      })

    if (action.payload.format == 'csv') {
      const headers = res.response.headers.join(",");
      const content = res.response.results
      .map((row: string[]) => 
        row?.map(
          col => col && typeof col == 'string' && /(\r\n)|(\r)|(\n)|(\t)(,)/gim.test(col) ? `"${col}"` : col
        )?.join(",")
      )?.join("\r\n");  
      download(
        "data:text/plain," + headers + '\r\n' + content, 
        `Kill_Data_From_${action.payload.kill_date_from}_To_${action.payload.kill_date_to}.csv`,
        "data:text/plain"
      )
    }
    else if (action.payload.format == 'json') {
      const data = res.response.results.map((row: string[]) => {
        const item: any = {};
        res.response.headers.forEach((key: string, i: number) => item[key] = row[i]);
        return item;
      })
      download(
        JSON.stringify(data, null, 2),
        `Kill_Data_From_${action.payload.kill_date_from}_To_${action.payload.kill_date_to}.json`,
        "data:text/plain"
      )
    }
  }
  else if (action.type === KILL_DATA_ACTION_TYPES.FETCH_SUMMARY) {
    const res = await _http.get('/data/kill-data?' + qs.stringify({
      ...action.payload,
      summary: true
    }));

    if (!res.response.results?.length) {
      return UtilsService.showToast("No Record Found", "bg-info")
    }
    const content = res.response.summary.map((item: {source: string, count: number}) => 
      ([item.source, item.count])
    )?.join("\r\n")
    download(
      "data:text/plain," + content, 
      `Kill_Data_Summary_From_${action.payload.kill_date_from}_To_${action.payload.kill_date_to}.csv`,
      "data:text/plain"
    )
    
    return store.dispatch(KILL_DATA_ACTIONS.FETCH_SUMMARY_SUCCESS({
      records: res.response.results,
      headers: res.response.headers,
    }));
  }
  else if (action.type === KILL_DATA_ACTION_TYPES.FLUSH_CACHE) {
    await _http.delete('/data/kill-data/flush-cache?' + qs.stringify(action.payload || {}))
    UtilsService.showToast("Cache flushed")
  }
  else {
    return next(action);
  }
}