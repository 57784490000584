
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Chip, FormControl, IconButton, Input, InputLabel, ListItemText, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import store from '../../../../store/store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { cloneDeep } from 'lodash';
import { UtilsService } from '../../../../services/common/utils.service';
import { GlobalStyleConstant } from '../../../../constants/styles/global.const';
import { PersonInterface } from '../../../../interfaces/schema/person.interface';
import { PERSON_ACTION } from '../../../../store/person/person.actions';
import { PersonGetter } from '../../../../store/person/person.getter';
import { CompanyPositionInterface } from '../../../../interfaces/schema/company-position.interface';
import { COMPANY_POSITION_ACTION } from '../../../../store/company-position/company-position.actions';
import { CompanyPositionGetter } from '../../../../store/company-position/company-position.getter';
import { CompanyInterface } from '../../../../interfaces/schema/company.interface';
import { AuthenticationGetter } from '../../../../store/authentication/authentication.getters';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ProjectInterface } from '../../../../interfaces/schema/project.interface';
import { PROJECT_ACTION } from '../../../../store/project/project.actions';
import { ProjectGetter } from '../../../../store/project/project.getters';

/**
 * People Page
 * @returns {JSX.Element}
 */
export default function PeoplePage() {

  const [people, setPeople] = useState([] as PersonInterface[])
  const [showDialog, setShowDialog] = useState(false)
  const [editingItem, setEditingItem] = useState({} as PersonInterface)
  const [positions, setPositions] = useState([] as CompanyPositionInterface[])
  const [currentCompany, setCurrentCompany] = useState({} as CompanyInterface)
  const [projects, setProjects] = useState([] as ProjectInterface[])

  useEffect(() => {
    store.dispatch(PERSON_ACTION.FETCH());
    store.dispatch(COMPANY_POSITION_ACTION.FETCH());
    store.dispatch(PROJECT_ACTION.FETCH());

    setCurrentCompany(AuthenticationGetter.currentCompany());

    const unsubscribe = store.subscribe(() => {
      setPeople(PersonGetter.listAll());
      setProjects(ProjectGetter.listAll());
      setPositions(CompanyPositionGetter.listAll());
      setCurrentCompany(AuthenticationGetter.currentCompany());
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Pop up edit dialog
   * 
   * @param person 
   */
  const editPerson = (person?: PersonInterface) => {
    setEditingItem(cloneDeep(person || {}))
    setShowDialog(true);
  }

  const save = () => {
    store.dispatch(PERSON_ACTION.SAVE(editingItem, () => {
      setShowDialog(false);
      UtilsService.showToast("Saved");
    }));
  }

  /**
   * List of positions
   */
  const selectedPositions = () => {
    const ids = editingItem.workplaces?.filter(item => item.company == currentCompany?._id)?.map(item => item.position) || [];
    return ids.filter(o => o).map(positionId => positions.find(position => position._id == positionId)?.position_name || "Unknown Position")
  }

  /**
   * Selection Checkbox
   * @param positionId 
   */
  const positionSelected = (positionId: string) => {
    return editingItem.workplaces?.some(item => item.company == currentCompany?._id && item.position == positionId) || false;
  }

  /**
   * get position name by id
   * @param positionId 
   * @returns 
   */
  const getPositionName = (positionId: string) => {
    return positions.find(position => position._id == positionId)?.position_name || "Unknown Position"
  }

  return (
    <div>
      <div className="d-flex mb-3">
        <span className="h3">People</span>
        <Button variant="contained" color="primary" className="ms-auto" onClick={() => editPerson()}>Create People</Button>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Company Position</TableCell>
                <TableCell>Attached Logins</TableCell>
                <TableCell>Involved Projects</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {people.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell >
                    {row.serial_number}
                  </TableCell>
                  <TableCell>
                    {row.person_first_name} {row.person_last_name}
                  </TableCell>
                  <TableCell >
                    {row.phone}
                  </TableCell>
                  <TableCell >
                    {
                      row.workplaces?.map((workplace, workplaceIndex) => {
                        return workplace.position && <Chip key={workplaceIndex} label={getPositionName(workplace.position)}/>
                      })
                    }
                  </TableCell>
                  <TableCell >
                    {
                      row.logins?.map((login, loginIndex) => {
                        return login?.login && <Chip key={loginIndex} label={(login.login as any)?.username}/>
                      })
                    }
                  </TableCell>
                  <TableCell >
                    {
                      row.projects?.map((project, projectIndex) => {
                        return <Chip className="me-1" key={projectIndex} label={project.project_name}/>
                      })
                    }
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Config">
                      <IconButton onClick={() => editPerson(row)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="lg">
        <DialogTitle className="pb-0">{editingItem._id ? "Edit" : "Create"} Person</DialogTitle>
        <DialogContent style={{width: GlobalStyleConstant.dialogMd}}>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            required
            value = {editingItem.person_first_name}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            label="Last Name"
            required
            value = {editingItem.person_last_name}
            fullWidth
          />

          <TextField
            autoFocus
            margin="dense"
            label="Phone"
            required
            value = {editingItem.phone}
            fullWidth
          />

        <FormControl className="w-100">
          <InputLabel>Company Position</InputLabel>
          <Select
            multiple
            value={editingItem.workplaces?.filter(item => item.company == currentCompany?._id).map(item => item.position) || []}
            onChange={(e: any) => {
              const value: string[] = e?.target?.value?.filter((o: string) => o) || [];
              const newPayload: PersonInterface = cloneDeep(editingItem);

              newPayload.workplaces = newPayload.workplaces?.filter(item => !item.position || item.company != currentCompany._id);
              newPayload.workplaces?.push(
                ...value.map(
                  positionId => ({
                    company: currentCompany._id as string,
                    position: positionId
                  })
                )
              );
              
              setEditingItem(newPayload);
            }}
            input={<Input />}
            renderValue={selectedPositions}
          >
            {positions.map((position, positionIndex) => (
              <MenuItem key={positionIndex} value={position._id}>
                <Checkbox checked={positionSelected(position._id as string)} />
                <ListItemText primary={position.position_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Autocomplete
          fullWidth
          multiple
          renderInput       = {(params) => <TextField {...params} label="Involved Projects" />}
          options           = {projects.filter(project => !editingItem.projects?.some(item => item._id == project._id))}
          getOptionLabel    = {(option: ProjectInterface) => option.project_name || ""}
          value             = {editingItem.projects || []}
          onChange          = {(event: any, newValue: ProjectInterface[]) => {
            setEditingItem({
              ...editingItem,
              projects: newValue
            })
          }}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => save()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
