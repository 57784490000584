import { LoginInterface } from "../../interfaces/schema/login.interface";
import { RequestService } from "../../services/common/request.service";
import { UtilsService } from "../../services/common/utils.service";
import { LoginService } from "../../services/store/login.service";
import { LOGINS_ACTION, LOGINS_ACTION_TYPES } from "./logins.actions";

const {
  FETCH_SUCCESS,
  SAVE_SUCCESS,
  ARCHIVE_SUCCESS
} = LOGINS_ACTION;

export const LoginMiddleware = (store: any) => (next: any) => async (action: {type: string, payload: any, projectId: string}) => {
  const _http   = new RequestService();
  /** 
   * Fetch logins
   */
  if (action.type === LOGINS_ACTION_TYPES.FETCH) {
    try {
      const {results, count} = await LoginService.fetch();
      return store.dispatch(FETCH_SUCCESS(results, count));
    }  
    catch (error) {
      console.error(LOGINS_ACTION_TYPES.FETCH, error);
      return ;
    }
  }
  /** 
   * Save login
   */
  else if (action.type === LOGINS_ACTION_TYPES.SAVE) {
    try {
      const newLogin: LoginInterface = await LoginService.save(action.payload.payload);
      UtilsService.showToast("Saved");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(SAVE_SUCCESS(newLogin))
    } catch (error) {
      return console.error(LOGINS_ACTION_TYPES.SAVE, error);
    }
  }
  /**
   * Archive login
   */
  else if (action.type === LOGINS_ACTION_TYPES.ARCHIVE) {
    try {
      await LoginService.archive(action.payload.loginId);
      UtilsService.showToast("Archived");
      if (action.payload.cb) action.payload.cb();
      return store.dispatch(ARCHIVE_SUCCESS(action.payload.loginId))
    } catch (error) {
      return console.error(LOGINS_ACTION_TYPES.ARCHIVE, error);
    }
  }
  /** 
   * Send password reset email
   */
  else if (action.type === LOGINS_ACTION_TYPES.SEND_RESET_PASSWORD_EMAIL) {
    try {
      await LoginService.sendPasswordResetEmail(action.payload.email);
      UtilsService.showToast("Sent");
      if (action.payload.cb) action.payload.cb();
      return ;
    } catch (error) {
      return console.error(LOGINS_ACTION_TYPES.SAVE, error);
    }
  }
  /** 
   * Reset Password
   */
  else if (action.type === LOGINS_ACTION_TYPES.UPDATE_PASSWORD) {
    try {
      await LoginService.updatePassword(action.payload.password);
      UtilsService.showToast("Password updated");
      if (action.payload.cb) action.payload.cb();
      return ;
    } catch (error) {
      return console.error(LOGINS_ACTION_TYPES.SAVE, error);
    }
  }
  else {
    return next(action);
  }
}