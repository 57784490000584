import store from './../store';

export const KillDataGetter = {
  
  listAllRecords: () => 
    store.getState().killData?.records || [],

    
  listAllHeaders: () => 
    store.getState().killData?.headers || [],

  summary: () => 
    store.getState().killData?.summary || [],

  listAllNestedRecords: () =>
    store.getState().killData?.nestedRecords || []
}