import store from './../store';

export const SessionTypeGetter = {
  item   : (SessionTypeId: string) => 
    store.getState().sessionType.entities?.find(entity => entity._id == SessionTypeId),

  listAll: () => 
    store.getState().sessionType.entities,
    
  count  : () => 
    store.getState().sessionType.count,
}