import { SessionTypeInterface } from "../../interfaces/schema/session-type.interface";
import { RequestService } from "../common/request.service";

export const SessionTypeService = {

  /**
   * Insert or update session type
   * @param payload 
   */
  save(payload: SessionTypeInterface) {
    const _http   = new RequestService();
    return _http.post(`/projects/${payload.project}/session-types`, payload).then(res => res.response?.result);
  },

  /**
   * Archive session type
   * @param sessionTypeId 
   */
  archive(projectId: string, sessionTypeId: string) {
    const _http = new RequestService();
    return _http.delete(`/projects/${projectId}/session-types/${sessionTypeId}`).then(res => res.response);
  }
}