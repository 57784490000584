import store from '../store';

export const LoginGetter = {
  item   : (loginId: string) => 
    store.getState().login.entities?.find(entity => entity._id == loginId),

  listAll: () => 
    store.getState().login.entities,
    
  count  : () => 
    store.getState().login.count,
}