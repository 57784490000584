import { Button, Card, CardActions, Paper, CardContent, FormControl, Icon, InputLabel, MenuItem, Select, TextField, Switch, Table, IconButton, Tooltip, ListItemText, Chip, NativeSelect } from '@material-ui/core';
import { cloneDeep } from 'lodash';

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { KillDataConfigColumnInterface } from '../../../interfaces/schema/kill-data-config-column.interface';
import { KillDataConfigInterface } from '../../../interfaces/schema/kill-data-config.interface';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import store from '../../../store/store';
import { KILL_DATA_CONFIG_ACTION } from '../../../store/kill-data-config/kill-data-config.actions';
import { UtilsService } from '../../../services/common/utils.service';
import { KillDataConfigGetter } from '../../../store/kill-data-config/kill-data-config.getter';
import { GlobalStyleConstant } from '../../../constants/styles/global.const';
import { KillDataConfigColumnDataSource } from '../../../enums/kill-data-config-column-data-source.enum';
import { PROJECT_ACTION } from '../../../store/project/project.actions';
import { ProjectInterface } from '../../../interfaces/schema/project.interface';
import { SESSION_TYPE_ACTION } from '../../../store/session-type/session-type.actions';
import { SessionTypeInterface } from '../../../interfaces/schema/session-type.interface';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SessionTypeInputInterface } from '../../../interfaces/schema/session-type-input.interface';
import { DATA_SPEC_SYSTEM_INPUTS } from '../../../constants/system/data-spec-system-inputs.const';
import { GRADE_DATA_ACTIONS } from '../../../store/grade-data/grade-data.actions';
import { GradeDataGetter } from '../../../store/grade-data/grade-data.getters';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KillDataConfigSummaryInterface } from '../../../interfaces/schema/kill-data-config-summary.interface';
import { ProjectGetter } from '../../../store/project/project.getters';
import { KillDataConfigService } from '../../../services/pages/kill-data-config.service';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


const column: KillDataConfigColumnInterface = {
  text              : "",
  value_key         : "",
  anchor_key        : "",
  data_source       : KillDataConfigColumnDataSource.SESSION_UPLOAD,
  file_name_pattern : "",
  project           : "",
  session_type      : "",
  not_null          : false,
  kill_date_column  : false
}

/**
 * Edit Kill Data Config Page
 * @returns {JSX.Element}
 */
 export default function EditKillDataConfigPage() {

  /** Initial Variables */
  const history = useHistory();
  const [projects, setProjects] = useState([] as ProjectInterface[])
  const [parentAnchorColumns, setParentAnchorColumns] = useState([] as {column_id: string, text: string}[])
  const [projectSessionTypes, setProjectSessionTypes] = useState({} as any)
  const [enableDrag, setEnableDrag] = useState(false as boolean)
  const [specSummary, setSpecSummary] = useState([] as KillDataConfigSummaryInterface[])
  const [fileCategories, setFileCategories] = useState([] as string[])
  const [fileInspectionData, setFileInspectionData] = useState({} as {
    file              ?: File,
    column_prefix     ?: string,
    column_suffix     ?: string,
    file_category     ?: string,
    accept_range_to   ?: number,
    accept_range_from ?: number,
    insert_after_column_order ?: number
  })
  const [showFileMetaData, setShowFileMetaData] = useState(false as boolean)
  const [editingItem, setEditingItem] = useState({
    config_columns: [],
    config_name: "",
    parent_anchor_columns: [],
    parent_config: ""
  } as KillDataConfigInterface)


  /** 
   * Load Config ID
   */
  const { id } = useParams() as {
    id?: string
  };

  /**
   * Set up listener
   */
  useEffect(() => {
    store.dispatch(KILL_DATA_CONFIG_ACTION.FETCH_SUMMARY())
    store.dispatch(GRADE_DATA_ACTIONS.FETCH_CATEGORIES())
    store.dispatch(PROJECT_ACTION.FETCH({}, (res: {results: ProjectInterface[], count: number}) => {
      setProjects(res.results);
      res.results.forEach(project => {
        if (!projectSessionTypes[project._id as string])
          fetchSessionsByProject(project._id as string)
      })
    }));

    if (id) {
      const config = KillDataConfigGetter.item(id);
      if (!config)
        store.dispatch(KILL_DATA_CONFIG_ACTION.FETCH_ITEM(id));
    }
    let initialed = false

    const unsubscribe = store.subscribe(() => {
      setProjects(ProjectGetter.listAll());
      setFileCategories(GradeDataGetter.categories());
      setSpecSummary(KillDataConfigGetter.summary());
      
      if (!initialed && id) {
        const config = KillDataConfigGetter.item(id);
        if (config && GradeDataGetter.categories().length && KillDataConfigGetter.summary().length) {
          initialed = true;
          initialEditingItem(config);
        }
      }
    })

    return () => {
      unsubscribe();
    }
  }, [])

  const initialEditingItem = (item: KillDataConfigInterface) => {
    if (!item._id) return;
    setEditingItem(item);
    if (item.parent_config) {
      setParentAnchorColumns(getParentAnchorColumns(item.parent_config))
    }
  }

  /**
   * Get list of session type inputs
   * 
   * @param projectId 
   * @param sessionTypeId 
   */
  const sessionTypeInputs = (projectId: string, sessionTypeId:string) => {
    const sessionType: SessionTypeInterface = projectSessionTypes[projectId]?.find((sessionType: SessionTypeInterface) => sessionType._id == sessionTypeId)
    return (sessionType?.session_inputs || [])
      .concat(
        /** Append meta data inputs */
        sessionType?.session_meta_data_inputs || [], 
        /** APpend system fields */
        DATA_SPEC_SYSTEM_INPUTS
      );
  }

  /**
   * Fetch session types by project id
   */
  const fetchSessionsByProject = (projectId: string) => {
    if (!projectSessionTypes[projectId]?.length)
      store.dispatch(SESSION_TYPE_ACTION.FETCH(projectId, {}, (res) => {
        setProjectSessionTypes({
          ...projectSessionTypes,
          [projectId]: res.results 
        });

      }))
  }

  /** 
   * Save
   */
  const save = () => {
    store.dispatch(KILL_DATA_CONFIG_ACTION.SAVE(editingItem, () => {
      back();
      UtilsService.showToast("Saved");
    }))
  }

  /**
   * Back to previous page
   */
  const back = () => {
    history.goBack();
  }

  /**
   * Determine save button disabled
   */
  const canSave = () => {
    return editingItem.config_name && editingItem.config_columns.length;
  }

  /**
   * Remove column
   */
  const removeColumn = (colIndex: number) => {
    const newPayload = cloneDeep(editingItem);
    newPayload.config_columns.splice(colIndex, 1);
    setEditingItem(newPayload)
  }

  /**
   * Clone column
   * 
   * @param colIndex
   */
  const cloneColumn = (column: KillDataConfigColumnInterface) => {
    const newPayload = cloneDeep(editingItem);
    const newColumn = cloneDeep(column);
    delete newColumn.column_id;
    newColumn.text += "_COPY" ;
    newColumn.column_order = editingItem.config_columns.length + 1;
    newPayload.config_columns.push(newColumn);
    setEditingItem(newPayload)
  }

  /**
   * update editingItem
   * 
   * @param colIndex 
   * @param value 
   */
  const handelChange = (colIndex: number, value: any, key: string) => {
    const newPayload = cloneDeep(editingItem);
    const column = newPayload.config_columns[colIndex]
    newPayload.config_columns[colIndex] = {
      ...column,
      [key]: value
    }
    setEditingItem(newPayload)
  }

  /**
   * Lookup value keys if not exist
   * 
   * @param category 
   */
  const lookupPossibleValueKeys = (category: string) => {
    if (!GradeDataGetter.categoryFields(category)?.length) {
      store.dispatch(GRADE_DATA_ACTIONS.FETCH_CATEGORY_FIELDS(category))
    }
  }

  /**
   * get list of possible fields
   * 
   * @param category 
   * @returns 
   */
  const categoryFields = (category: string) => {
    return GradeDataGetter.categoryFields(category);
  }

  /**
   * get list of anchor columns
   * 
   * @returns 
   */
  const getParentAnchorColumns = (parent_config: string) => {
    return KillDataConfigGetter.summary().find(summary => summary._id == parent_config)?.config_columns || []
  }

  /**
   * get anchor key text by id
   * @param id 
   */
  const parentAnchorKeyText = (id: string) => {
    return parentAnchorColumns.find(col => col.column_id == id)?.text || ""
  }

  /**
   * Inspect file
   * 
   * @param evt 
   */
  const inspectFile = (evt: Event) => {
    const el    = evt.target as HTMLInputElement;
    const files = el.files;
    if (files?.item(0)) {
      const file = files.item(0) as File;
      if (!/(text\/)(csv|xml)/i.test(file.type) && !file.type.includes("ms-excel"))
        return UtilsService.showToast("System only accept text/csv and text/xml file", 'bg-danger');
      setShowFileMetaData(true);
      setFileInspectionData({
        file
      });
    }
  }

  const processInspectFile = async () => {
    if (fileInspectionData.file && fileInspectionData.file_category) {
      const columns: KillDataConfigColumnInterface[] = await KillDataConfigService.readFile(fileInspectionData as any);
      setShowFileMetaData(false);

      const newColumns  = cloneDeep(editingItem.config_columns);
      let   targetIndex;
      if (fileInspectionData.insert_after_column_order)
        targetIndex = editingItem.config_columns.findIndex(col => col.column_order == fileInspectionData.insert_after_column_order);
      if (!targetIndex || targetIndex == -1)
        targetIndex = editingItem.config_columns.length - 1

      newColumns.splice(targetIndex + 1, 0, ...columns);
      newColumns.forEach((col, i) => col.column_order = i + 1);

      setEditingItem({
        ...editingItem,
        config_columns: newColumns
      })
    }
  }

  return (
    <div>
      <Dialog
        open={showFileMetaData}
        style={{
        }}
        onClose={() => setShowFileMetaData(false)}
      >
        <DialogTitle>
          Set File Meta Data
        </DialogTitle>
        <DialogContent className="w-100 px-4" style={{minWidth: 500}}>

          <TextField 
            label="File Category"
            required
            fullWidth
            className="d-block w-100"
            value={fileInspectionData.file_category}
            onChange={(evt) => {
              setFileInspectionData({
                ...fileInspectionData,
                file_category: evt.target.value
              })
            }} />

          <TextField 
            label="Column Prefix"
            fullWidth
            className="d-block w-100"
            value={fileInspectionData.column_prefix}
            onChange={(evt) => {
              setFileInspectionData({
                ...fileInspectionData,
                column_prefix: evt.target.value
              })
            }} />
          <TextField
            label="Column Suffix"
            className="d-block w-100"
            fullWidth
            value={fileInspectionData.column_suffix}
            onChange={(evt) => {
              setFileInspectionData({
                ...fileInspectionData,
                column_suffix: evt.target.value
              })
            }} />
          <TextField
              label="Insert After Column Order"
              fullWidth
              type="number"
              className="d-block w-100"
              value={fileInspectionData.insert_after_column_order}
              onChange={(evt) => {
                setFileInspectionData({
                  ...fileInspectionData,
                  insert_after_column_order: evt.target.value ? Number(evt.target.value) : undefined
                })
              }} />
          <TextField
              label="Accept Range From"
              type="number"
              fullWidth
              className="d-block w-100"
              value={fileInspectionData.accept_range_from}
              onChange={(evt) => {
                setFileInspectionData({
                  ...fileInspectionData,
                  accept_range_from: evt.target.value ? Number(evt.target.value) : undefined
                })
              }} />
            <TextField
              label="Accept Range To"
              type="number"
              fullWidth
              className="d-block w-100"
              value={fileInspectionData.accept_range_to}
              onChange={(evt) => {
                setFileInspectionData({
                  ...fileInspectionData,
                  accept_range_to: evt.target.value ? Number(evt.target.value) : undefined
                })
              }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFileMetaData(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={processInspectFile} color="primary" autoFocus>
            Process
          </Button>
        </DialogActions>
      </Dialog>
      <div className="d-flex mb-3">
        <div className="pointer mt-4" onClick={back}>
          <Icon className="me-2">keyboard_arrow_left</Icon>
        </div>

        <TextField 
          style={{
            minWidth: GlobalStyleConstant.textFieldWidthLg
          }}
          label="Data Spec Name"
          required
          value={editingItem.config_name}
          onChange={(evt) => {
            setEditingItem({
              ...editingItem,
              config_name: evt.target.value
            })
          }} />

        <FormControlLabel
          className="ms-auto"
          control={
            <Switch
              checked={enableDrag}
              onChange={(evt:  React.ChangeEvent<HTMLInputElement>) => {
                setEnableDrag(evt.target.checked)
              }}
              color="primary"
            />
          }
          label="Enable Drag Drop Reorder"
        />
        <div className="pt-2">
          <Button 
            variant="contained" 
            className="ms-2" 
            color="primary"
            onClick={() => {
              document.getElementById("inspectFileInput")?.click()
            }}>
            Inspect File
          </Button>

          <input type="file" id="inspectFileInput" className="d-none" accept=".csv,.xml" onChange={inspectFile as any} />

          <Button className="ms-2" variant="contained" color="primary" onClick={() => {
            const newColumn = cloneDeep(column);
            newColumn.column_order = editingItem.config_columns.length + 1;
            setEditingItem({
              ...editingItem,
              config_columns: [
                ...editingItem.config_columns,
                newColumn
              ]
            })
          }}>
            Add Column
          </Button>
          
          <Button variant="contained" className="ms-2" disabled={!canSave()} color="primary" onClick={save}>
            Save
          </Button>
        </div>
      </div>
      <div>
        <Card className="px-3 py-3 my-3">

          <h6 className="my-1">
            Configurations
          </h6>

          <TextField 
            style={{
              minWidth: GlobalStyleConstant.textFieldWidthLg
            }}
            label="Kill Date From"
            required
            className="me-2"
            value={editingItem.kill_date_range?.from || ""}
            onChange={(evt) => {
              setEditingItem({
                ...editingItem,
                kill_date_range: {
                  to: editingItem.kill_date_range?.to || "",
                  from: evt.target.value || ""
                }
              })
            }} />

          <TextField 
            style={{
              minWidth: GlobalStyleConstant.textFieldWidthLg
            }}
            label="Kill Date To"
            className="me-2"
            required
            value={editingItem.kill_date_range?.to || ""}
            onChange={(evt) => {
              setEditingItem({
                ...editingItem,
                kill_date_range: {
                  from: editingItem.kill_date_range?.from || "",
                  to: evt.target.value || ""
                }
              })
            }} />

          <Autocomplete
            fullWidth
            multiple
            className         = "mt-3"
            renderInput       = {(params) => <TextField {...params} label="Linked Projects" />}
            options           = {projects.filter(project => !editingItem.projects?.some(item => item._id == project._id))}
            getOptionLabel    = {(option: ProjectInterface) => option.project_name || ""}
            value             = {editingItem.projects || []}
            onChange          = {(event: any, newValue: ProjectInterface[]) => {
              setEditingItem({
                ...editingItem,
                projects: newValue
              })  
            }}
          />

        </Card>
        <Card className="px-3 py-3 my-3">

          <h6 className="my-1">
            Parent Spec Linkage
          </h6>

          <FormControl className="col-12 col-sm-6 col-md-3 col-xl-2">
            <InputLabel>Parent Spec</InputLabel>
            <NativeSelect
              value={editingItem.parent_config}
              onChange={(evt) => {
                console.info(evt.target.value);
                setParentAnchorColumns(getParentAnchorColumns(evt.target.value as string));
                setEditingItem({
                  ...editingItem,
                  parent_config: evt.target.value as string,
                  parent_anchor_columns: []
                })
              }}
            >
              <option value={""}></option>
              {
                specSummary.map((summary, summaryIndex) => (
                  <option key={summary._id} value={summary._id}>{summary.config_name}</option>
                ))
              }
            </NativeSelect>
          </FormControl>
          {editingItem.parent_config && <FormControl className="col-12 mt-3">
            <InputLabel>Parent Anchor Columns</InputLabel>
            <Select
              multiple={true}
              value={editingItem.parent_anchor_columns}
              renderValue={(selected) => (selected as string[]).map((chip, index) => ( <Chip key={index} label={parentAnchorKeyText(chip)}/> ))}
              onChange={(evt) => {
                setEditingItem({
                  ...editingItem,
                  parent_anchor_columns: evt.target.value as string[]
                })
              }}
            >
              {
                parentAnchorColumns.map((col, colIndex) => (
                  <MenuItem key={colIndex} value={col.column_id}>
                    <Checkbox color="primary" checked={(editingItem.parent_anchor_columns || []).includes(col.column_id as never)} />
                    <ListItemText primary={col.text} />  
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>}
        </Card>
      </div>
      {
        !enableDrag && <TableContainer component={Paper}>
          <Table className="w-100 strip-table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Data Source</TableCell>
                <TableCell>File Category</TableCell>
                <TableCell>Project</TableCell>
                <TableCell>Session Type</TableCell>
                <TableCell>Value Key</TableCell>
                <TableCell>Display Text</TableCell>
                <TableCell>Anchor Key</TableCell>
                <TableCell>Merge Key</TableCell>
                <TableCell>Parent Anchor Key</TableCell>
                <TableCell>Not Null</TableCell>
                <TableCell>Key Attribute</TableCell>
                <TableCell>Kill Date Column</TableCell>
                <TableCell>Hidden</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                editingItem.config_columns.map((column, colIndex) => (
                  <TableRow key={colIndex}>
                    <TableCell>
                      <input type="text" value={column.column_order} onChange={(evt) => handelChange(colIndex, evt.target.value, 'column_order')} style={{
                        width: GlobalStyleConstant.textFieldWidthSm
                      }} />
                    </TableCell>
                    <TableCell>
                      <select value={column.data_source} onChange={(evt) => handelChange(colIndex, evt.target.value, 'data_source')}>
                        <option value={KillDataConfigColumnDataSource.MANUAL_IMPORT}>{KillDataConfigColumnDataSource.MANUAL_IMPORT}</option>
                        <option value={KillDataConfigColumnDataSource.SESSION_UPLOAD}>{KillDataConfigColumnDataSource.SESSION_UPLOAD}</option>
                      </select>
                    </TableCell>
                    <TableCell>
                      {
                        /** Select File Category */
                        column.data_source == KillDataConfigColumnDataSource.MANUAL_IMPORT 
                        && <select value={column.file_name_pattern} onChange={(evt) => {
                          /** Update Editing Column */
                          handelChange(colIndex, evt.target.value, 'file_name_pattern');
                          /** Lookup Possible Value Keys */
                          lookupPossibleValueKeys(evt.target.value)
                        }}>
                          <option value=""></option>
                          {
                            fileCategories.map((category, categoryIndex) => (
                              <option key={categoryIndex} value={category}>{category}</option>
                            ))
                          }
                        </select>
                        // <input type="text" value={column.file_name_pattern} onChange={(evt) => handelChange(colIndex, evt.target.value, 'file_name_pattern')}/>
                      }
                    </TableCell>
                    <TableCell>
                      {
                        /** Select Project */
                        column.data_source == KillDataConfigColumnDataSource.SESSION_UPLOAD 
                        && <select value={column.project} onChange={(evt) => {
                          fetchSessionsByProject(evt.target.value as string);
                          handelChange(colIndex, evt.target.value, 'project')
                        }}>
                          {
                            projects.map((project, projectIndex) => (
                              <option key={projectIndex} value={project._id}>{project.project_name}</option>
                            ))
                          }
                        </select>
                      }
                    </TableCell>
                    <TableCell>
                      {column.project 
                      && <select value={column.session_type} onChange={(evt) => handelChange(colIndex, evt.target.value, 'session_type')} >
                        {
                          (column.project && projectSessionTypes[column.project] || [])
                            .map((sessionType: SessionTypeInterface, index: number) => (
                              <option key={index} value={sessionType._id}>{sessionType.session_type_name}</option>
                            ))
                        }
                      </select>}
                    </TableCell>
                    <TableCell>
                      {
                        column.data_source == KillDataConfigColumnDataSource.MANUAL_IMPORT &&
                        column.file_name_pattern &&
                        <Autocomplete
                          className="px-2"
                          options={categoryFields(column.file_name_pattern as string) || []}
                          getOptionLabel={(option: string) => option}
                          value={column.value_key}
                          onChange={(evt, newValue) => handelChange(colIndex, newValue, 'value_key')}
                          renderInput={(params: any) => <TextField className="px-0 py-0" margin="none"  size="small" {...params} />}
                        />
                      }
                      {
                        column.data_source == KillDataConfigColumnDataSource.SESSION_UPLOAD &&
                        <select value={column.value_key} onChange={(evt) => {
                          const attribute = sessionTypeInputs(column.project as string, column.session_type as string)
                            ?.find((attr: SessionTypeInputInterface) => attr.key == evt.target.value);
                          const newPayload = cloneDeep(editingItem);
                          newPayload.config_columns[colIndex] = {
                            ...column,
                            value_key: evt.target.value as string,
                            text: attribute?.label
                          }
                          setEditingItem(newPayload)
                        }}>
                          <option value="">Please select a key...</option>
                          {
                            column.project && column.session_type && 
                            (sessionTypeInputs(column.project, column.session_type) || [])
                              .map((input: SessionTypeInputInterface, inputIndex: number) => (
                              <option key={inputIndex} value={input.key}>{input.key}</option>
                            ))
                          }
                        </select>
                      }
                    </TableCell>
                    <TableCell>
                      <input type="text" value={column.text} onChange={(evt) => handelChange(colIndex, evt.target.value, 'text')} />
                    </TableCell>
                    <TableCell>
                      <input type="text" value={column.anchor_key} onChange={(evt) => handelChange(colIndex, evt.target.value, 'anchor_key')} />
                    </TableCell>
                    <TableCell>
                      <input type="text" value={column.merge_key} onChange={(evt) => handelChange(colIndex, evt.target.value, 'merge_key')} />
                    </TableCell>
                    <TableCell>
                      <select value={column.parent_anchor_key} onChange={(evt) => handelChange(colIndex, evt.target.value, 'parent_anchor_key')}>
                        <option value=""></option>
                        {
                          editingItem.parent_anchor_columns && editingItem.parent_anchor_columns.map(col => (
                            <option value={col} key={col}>{parentAnchorKeyText(col)}</option>
                          ))
                        }
                      </select>
                    </TableCell>
                    <TableCell>
                      <Checkbox 
                        color="primary"
                        checked={column.not_null}
                        onChange={(evt) => {
                          const newPayload = cloneDeep(editingItem);
                          newPayload.config_columns[colIndex] = {
                            ...column,
                            not_null: evt.target.checked
                          }
                          setEditingItem(newPayload)
                        }} />
                    </TableCell>
                    <TableCell>
                      <Checkbox 
                        color="primary"
                        checked={column.is_key_attribute}
                        onChange={(evt) => {
                          const newPayload = cloneDeep(editingItem);
                          newPayload.config_columns[colIndex] = {
                            ...column,
                            is_key_attribute: evt.target.checked
                          }
                          setEditingItem(newPayload)
                        }} />
                    </TableCell>
                    <TableCell>
                      <Checkbox 
                        color="primary"
                        checked={column.kill_date_column}
                        onChange={(evt) => {
                          const newPayload = cloneDeep(editingItem);
                          newPayload.config_columns[colIndex] = {
                            ...column,
                            kill_date_column: evt.target.checked
                          }
                          setEditingItem(newPayload)
                        }} />
                    </TableCell>
                    <TableCell>
                      <Checkbox 
                        color="primary"
                        checked={column.hidden}
                        onChange={(evt) => {
                          const newPayload = cloneDeep(editingItem);
                          newPayload.config_columns[colIndex] = {
                            ...column,
                            hidden: evt.target.checked
                          }
                          setEditingItem(newPayload)
                        }} />
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Clone Column">
                        <IconButton onClick={() => {
                          cloneColumn(column)
                        }} size="small" color="primary">
                          <Icon>file_copy</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove Column">
                        <IconButton onClick={() => {
                          removeColumn(colIndex)
                        }} size="small" color="secondary">
                          <Icon>delete</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      }

      {enableDrag && <div>
        <DragDropContext onDragEnd={(evt) => {
          const from = evt.source.index;
          const to = evt.destination?.index;

          if (typeof to == 'number') {
            const payload = cloneDeep(editingItem);
            const fromItem = payload.config_columns.splice(from, 1);
            payload.config_columns.splice(to, 0, fromItem[0]);

            payload.config_columns.forEach((col, i) => {
              col.column_order = i + 1
            });
            
            setEditingItem(payload)
          }

        }}>
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {
                  editingItem.config_columns.map((column, colIndex) => (
                    <Draggable draggableId={colIndex.toString()} index={colIndex} key={colIndex}>
                      {(provided, snapshot) => (
                        <Card 
                          ref={provided.innerRef} 
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          variant="outlined" 
                          className="mb-2"
                        >
                          <CardContent className="px-4">
                            <p className="h6">
                              <b>Column {colIndex + 1}</b>
                            </p>

                            <TextField 
                              label="Order"
                              type="number"
                              value={column.column_order}
                              style={{
                                width: GlobalStyleConstant.textFieldWidthSm
                              }}
                              onChange={(evt) => {
                                const newPayload = cloneDeep(editingItem);
                                newPayload.config_columns[colIndex] = {
                                  ...column,
                                  column_order: Number(evt.target.value)
                                }
                                setEditingItem(newPayload)
                              }} />
                            
                            <FormControl className="ms-3" required style={{
                              width: GlobalStyleConstant.textFieldWidthMd
                            }}>
                              <InputLabel id="data-source-label">Data Source</InputLabel>
                              <Select
                                labelId="data-source-label"
                                id="data-source"
                                required
                                value={column.data_source}
                                onChange={(evt) => {
                                  const newPayload = cloneDeep(editingItem);
                                  newPayload.config_columns[colIndex] = {
                                    ...column,
                                    data_source: evt.target.value as KillDataConfigColumnDataSource,
                                    project: "",
                                    session_type: "",
                                    file_name_pattern: ""
                                  }
                                  setEditingItem(newPayload)
                                }}
                              >
                                <MenuItem value={KillDataConfigColumnDataSource.MANUAL_IMPORT}>{KillDataConfigColumnDataSource.MANUAL_IMPORT}</MenuItem>
                                <MenuItem value={KillDataConfigColumnDataSource.SESSION_UPLOAD}>{KillDataConfigColumnDataSource.SESSION_UPLOAD}</MenuItem>
                              </Select>
                            </FormControl>

                            {
                              column.data_source == KillDataConfigColumnDataSource.MANUAL_IMPORT ? 
                                <TextField 
                                  label="File Category"
                                  className="ms-3"
                                  required
                                  value={column.file_name_pattern}
                                  onChange={(evt) => {
                                    const newPayload = cloneDeep(editingItem);
                                    newPayload.config_columns[colIndex] = {
                                      ...column,
                                      file_name_pattern: evt.target.value
                                    }
                                    setEditingItem(newPayload)
                                  }} 
                                />
                                : <></>
                            }

                            {
                              column.data_source == KillDataConfigColumnDataSource.SESSION_UPLOAD && projects.length ?  
                              <FormControl className="ms-3" required style={{
                                width: GlobalStyleConstant.textFieldWidthMd
                              }}>
                                <InputLabel id="data-source-label">Project</InputLabel>
                                <Select
                                  labelId="data-source-label"
                                  id="data-source"
                                  required
                                  value={column.project}
                                  onChange={(evt) => {
                                    fetchSessionsByProject(evt.target.value as string);
                                    const newPayload = cloneDeep(editingItem);
                                    newPayload.config_columns[colIndex] = {
                                      ...column,
                                      project: evt.target.value as string
                                    }
                                    setEditingItem(newPayload)
                                  }}
                                >
                                  {
                                    projects.map((project, projectIndex) => (
                                      <MenuItem key={projectIndex} value={project._id}>{project.project_name}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl> : <></>
                            }

                            {
                              column.project && projectSessionTypes[column.project]?.length ? 
                              <FormControl className="ms-3" required style={{
                                width: GlobalStyleConstant.textFieldWidthMd
                              }}>
                                <InputLabel id="data-source-label">Session Type</InputLabel>
                                <Select
                                  labelId="data-source-label"
                                  id="data-source"
                                  required
                                  value={column.session_type}
                                  onChange={(evt) => {
                                    const newPayload = cloneDeep(editingItem);
                                    newPayload.config_columns[colIndex] = {
                                      ...column,
                                      session_type: evt.target.value as string
                                    }
                                    setEditingItem(newPayload)
                                  }}
                                >
                                  {
                                    (projectSessionTypes[column.project] || []).map((session: SessionTypeInterface, sessionIndex: number) => (
                                      <MenuItem key={sessionIndex} value={session._id}>{session.session_type_name}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl> : <></>
                            }

                            {
                              column.project && column.session_type ?
                              <FormControl className="ms-3" required style={{
                                width: GlobalStyleConstant.textFieldWidthMd
                              }}>
                                <InputLabel>Attribute</InputLabel>
                                <Select
                                  required
                                  value={column.value_key}
                                  onChange={(evt) => {
                                    const attribute = sessionTypeInputs(column.project as string, column.session_type as string)
                                      ?.find((attr: SessionTypeInputInterface) => attr.key == evt.target.value);
                                    const newPayload = cloneDeep(editingItem);
                                    newPayload.config_columns[colIndex] = {
                                      ...column,
                                      value_key: evt.target.value as string,
                                      text: attribute?.label
                                    }
                                    setEditingItem(newPayload)
                                  }}
                                >
                                  {
                                    (sessionTypeInputs(column.project, column.session_type) || [])
                                      .map((input: SessionTypeInputInterface, inputIndex: number) => (
                                      <MenuItem key={inputIndex} value={input.key}>{input.key}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                              : 
                              <TextField 
                                label="Value Key"
                                className="ms-3"
                                required
                                value={column.value_key}
                                onChange={(evt) => {
                                  const newPayload = cloneDeep(editingItem);
                                  newPayload.config_columns[colIndex] = {
                                    ...column,
                                    value_key: evt.target.value
                                  }
                                  setEditingItem(newPayload)
                                }} />
                            }

                            <TextField 
                              label="Display Text" 
                              required
                              className="ms-3"
                              value={column.text}
                              style={{
                                width: GlobalStyleConstant.textFieldWidthLg
                              }}
                              onChange={(evt) => {
                                const newPayload = cloneDeep(editingItem);
                                newPayload.config_columns[colIndex] = {
                                  ...column,
                                  text: evt.target.value
                                }
                                setEditingItem(newPayload)
                              }} />

                            <TextField 
                              label="Anchor Key"
                              className="ms-3"
                              value={column.anchor_key}
                              onChange={(evt) => {
                                const newPayload = cloneDeep(editingItem);
                                newPayload.config_columns[colIndex] = {
                                  ...column,
                                  anchor_key: evt.target.value
                                }
                                setEditingItem(newPayload)
                              }} />

                          </CardContent>
                          <CardActions>
                            <FormControlLabel
                              label="Not Null" 
                              className="ms-2"
                              control={
                                <Checkbox 
                                  color="primary"
                                  checked={column.not_null}
                                  onChange={(evt) => {
                                    const newPayload = cloneDeep(editingItem);
                                    newPayload.config_columns[colIndex] = {
                                      ...column,
                                      not_null: evt.target.checked
                                    }
                                    setEditingItem(newPayload)
                                  }} />
                              } />
                            <FormControlLabel
                              label="Hidden" 
                              className="ms-2"
                              control={
                                <Checkbox 
                                  color="primary"
                                  checked={column.hidden}
                                  onChange={(evt) => {
                                    const newPayload = cloneDeep(editingItem);
                                    newPayload.config_columns[colIndex] = {
                                      ...column,
                                      hidden: evt.target.checked
                                    }
                                    setEditingItem(newPayload)
                                  }} />
                              } />
                              
                            <FormControlLabel
                              label="Key Attribute" 
                              className="ms-2"
                              control={
                                <Checkbox 
                                  color="primary"
                                  checked={column.is_key_attribute}
                                  onChange={(evt) => {
                                    const newPayload = cloneDeep(editingItem);
                                    newPayload.config_columns[colIndex] = {
                                      ...column,
                                      is_key_attribute: evt.target.checked
                                    }
                                    setEditingItem(newPayload)
                                  }} />
                              } />

                            <FormControlLabel
                              label="Is Kill Date Column" 
                              className="ms-2"
                              control={
                                <Checkbox 
                                  color="primary"
                                  checked={column.kill_date_column}
                                  onChange={(evt) => {
                                    const newPayload = cloneDeep(editingItem);
                                    newPayload.config_columns[colIndex] = {
                                      ...column,
                                      kill_date_column: evt.target.checked
                                    }
                                    setEditingItem(newPayload)
                                  }} />
                              } />

                            <Button size="small" variant="outlined" className="ms-auto me-2" color="primary" onClick={() => {
                              cloneColumn(column)
                            }}>
                              <Icon fontSize="small" className="me-1">file_copy</Icon>
                              Clone Column
                            </Button>
                            
                            <Button size="small" variant="outlined" className="ms-2 me-2" color="secondary" onClick={() => {
                              removeColumn(colIndex)
                            }}>
                              <Icon fontSize="small" className="me-1">delete</Icon>
                              Remove Column
                            </Button>
                          </CardActions>
                        </Card>
                      )}
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>}

      <div className="d-flex">
        <Button className="ms-auto mt-2" variant="contained" color="primary" onClick={() => {
          const newColumn = cloneDeep(column);
          newColumn.column_order = editingItem.config_columns.length + 1;
          setEditingItem({
            ...editingItem,
            config_columns: [
              ...editingItem.config_columns,
              newColumn
            ]
          })
        }}>
          Add Column
        </Button>
        <Button variant="contained" className="ms-2 mt-2" disabled={!canSave()} color="primary" onClick={save}>
          Save
        </Button>
      </div>
    </div>
  )
 }