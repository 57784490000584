import { SESSION_RECORD_ACTION_TYPES } from './session-record.actions';
import { SessionRecordInterface } from '../../interfaces/schema/session-record.interface';


export interface SessionRecordStateInterface {
  entities         : SessionRecordInterface[],
  count            : number,

}

const initialState: SessionRecordStateInterface = {
  entities         : [],
  count            : 0
}

export function SessionRecordReducer(
  state: SessionRecordStateInterface = initialState,
  action: {type: string, payload?: any}) 
{
  if (action.type === SESSION_RECORD_ACTION_TYPES.FETCH_SUCCESS) {
    state.entities = action.payload?.results || [];
    state.count    = action.payload?.count   || 0;
  }
  else if (action.type === SESSION_RECORD_ACTION_TYPES.ARCHIVE_RECORD_SUCCESS) {
    state.entities = state.entities.filter(entity => entity._id != action.payload);
    state.count--
  }
  else if (action.type === SESSION_RECORD_ACTION_TYPES.MODIFY_RECORD_SUCCESS) {
    const index = state.entities.findIndex(entity => entity._id == action.payload?._id);
    if (index != -1) state.entities[index] = action.payload;
  }
  return state;
}