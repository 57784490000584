import { PersonInterface } from "../../interfaces/schema/person.interface";
import { RequestService } from "../common/request.service";

export const PersonService = {
  /**
   * Fetch people
   */ 
     fetch() {
      const _http   = new RequestService();
      return _http.get(`/operators`).then(res => res.response);
    },
  
    /**
     * Insert or update person
     * @param payload 
     */
     save(payload: PersonInterface) {
      const _http   = new RequestService();
      return _http.post(`/operators`, payload).then(res => res.response?.result);
    },
  
    /**
     * Archive person
     * @param personId 
     */
    archive(personId: string) {
      const _http = new RequestService();
      return _http.delete(`/operators/${personId}`).then(res => res.response);
    }
}