import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Input, Button, Dialog, DialogTitle, FormHelperText } from "@material-ui/core";
import store from "../../store/store";
import { LOGINS_ACTION } from "../../store/logins/logins.actions";
import DeclarationComponent from './../common/declaration.component';

export default function UpdatePasswordDialog(props: {
  showDialog    : boolean,
  setShowDialog : (value: boolean) => void
}) {

  const [password, setPassword] = useState('' as string);
  const [confirmPassword, setConfirmPassword] = useState('' as string);
  const [confirmed, setConfirmed] = useState(false as boolean);

  /**
   * Determine password valid or not
   * 
   * @returns {boolean}
   */
  const canSave = () => {
    return password && password == confirmPassword;
  }

  /**
   * Update Password
   */
  const submit = () => {
    store.dispatch(LOGINS_ACTION.UPDATE_PASSWORD(password));
    props.setShowDialog(false);
  }

  return (
    <Dialog onClose={() => {props.setShowDialog(false)}} aria-labelledby="simple-dialog-title" open={props.showDialog}>
      <DialogTitle id="simple-dialog-title">Update Password</DialogTitle>
      <div className="px-4 pb-4" style={{
        minWidth: 500
      }}>
        <FormControl className="w-100 mb-3">
          <InputLabel>New Password</InputLabel>
          <Input value={password} type="password" required onChange={(evt) => {
            setPassword(evt.target.value)
          }} />
        </FormControl>

        <FormControl className="w-100">
          <InputLabel>Confirm Password</InputLabel>
          <Input value={confirmPassword} type="password" required onChange={(evt) => {
            setConfirmPassword(evt.target.value)
          }} />
          {
            confirmPassword && !canSave() && <FormHelperText className="text-danger">* Please double check your password.</FormHelperText>
          }
        </FormControl>

        {
          canSave() && <div className="mt-4">
            <DeclarationComponent
              checked = {confirmed}
              setChecked = {setConfirmed}
            ></DeclarationComponent>
          </div> || ""
        }

        <div className="d-flex pt-4">
          <Button color="primary" onClick={() => props.setShowDialog(false)}>
            Close
          </Button>
          <Button 
            className = "ms-auto" 
            color     = "primary" 
            variant   = "contained"
            disabled  = {!canSave() || !confirmed}
            onClick   = {submit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  )
}