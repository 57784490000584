import { StorageService } from "./storage.service";
import jwtDecode, { JwtPayload } from "jwt-decode";
import moment from 'moment';
import { UtilsService } from "./utils.service";

interface RequestOptionsInterface {
  headers?: any, 
  baseUrl?: string, 
  responseType?: 'json' | 'text',
  noToken?: boolean,
  doNotHandleError?: boolean,
  hideLoading?: boolean
}

const defaultRequestOptions: RequestOptionsInterface = {
  headers: {},
  noToken: false,
  responseType: 'json',
  baseUrl: process.env.REACT_APP_API_URL,
  doNotHandleError: false,
  hideLoading: true
}

export class RequestService {
  private storage: StorageService;

  constructor() {
    this.storage = new StorageService();
  }

  jwtIsExpired(token: string): boolean {
    const payload = jwtDecode<JwtPayload>(token);
    return moment().isAfter(moment(payload.exp, 'X', true));
  }

  async checkToken() {
    try {
      const token = await this.storage.get('token');
      const refreshToken = await this.storage.get('refreshToken');
      if (!token) throw "Token Not Found";
      if (this.jwtIsExpired(token)) {
        // Verify Refresh Token
        if (!refreshToken) throw "Refresh Token Not Found";
        if (!this.jwtIsExpired(refreshToken)) {
          const {response: newTokens} = await this.post('/auth/login/refresh-token', {
            token,
            refreshToken,
            hideLoading: false
          }, {
            baseUrl: process.env.REACT_APP_API_URL,
            noToken: true
          })
          const {refreshToken: newRefreshToken, token: newToken} = newTokens;
          await Promise.all([
            this.storage.set('token', newToken),
            this.storage.set('refreshToken', newRefreshToken)
          ])
          return newToken;
        } else {
          throw "Refresh Token Expired";
        }
      } else {
        return token
      }
    } catch (error) {
      console.info('[Verify Token Error]: ', error);
      throw error;
    }
  }

  async getHeader(options: RequestOptionsInterface = defaultRequestOptions): Promise<any> {
    const header: any = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    try {
      const operator = await this.storage.get('operator');
      const role = await this.storage.get('role');
      if (operator)
        header['x-operator'] = operator._id;
      if (role?.role)
        header['x-role'] = role.role._id;
      if (role?.company)
        header['x-company'] = role.company._id;
        
      if (!options.noToken) {
        const token = await this.checkToken();
        header['Authorization'] = `Bearer ${token}`;
      }
      const keys = Object.keys(options?.headers || {});
      keys.forEach(key => header[key] = options.headers[key]);
    } catch (error) {
      console.info("Unable to wrap external options to header: ", error);
    }
    return header;
  }

  async get(url: string, options: RequestOptionsInterface = defaultRequestOptions): Promise<any> {
    UtilsService.showLoading();
    try {
      const headers = await this.getHeader(options || defaultRequestOptions);
      const requestUrl: string = (options.baseUrl || defaultRequestOptions.baseUrl) + url;
      console.info(`[GET] ${requestUrl}`);
      const rawResponse = await fetch(requestUrl, {
        method: 'GET',
        headers,
      });
      let res: any = rawResponse;
      
      if (options.responseType == 'text') res = await rawResponse.text();
      else res = (await rawResponse.json())?.message || {};

      if (!rawResponse.ok) throw res;

      return {
        response: res,
        statusCode: res?.code || rawResponse.status
      };
    } catch (error) {
      this.handleError(options, error);
      throw error;
    } finally {
      if (options.hideLoading) UtilsService.hideLoading();
    }
  }

  async delete(url: string, options: RequestOptionsInterface = defaultRequestOptions): Promise<any> {
    UtilsService.showLoading();
    try {
      const headers = await this.getHeader(options || defaultRequestOptions);
      const requestUrl: string = (options.baseUrl || defaultRequestOptions.baseUrl) + url;
      console.info(`[DELETE] ${requestUrl}`);
      const rawResponse = await fetch(requestUrl, {
        method: 'DELETE',
        headers,
      });
      let res: any = rawResponse;
      
      if (options.responseType == 'text') res = await rawResponse.text();
      else res = (await rawResponse.json())?.message || {};

      if (!rawResponse.ok) throw res;

      return {
        response: res,
        statusCode: res?.code || rawResponse.status
      };
    } catch (error) {
      this.handleError(options, error);
      throw error;
    } finally {
      if (options.hideLoading) UtilsService.hideLoading();
    }
  }

  async post(url: string, body: any = {}, options: RequestOptionsInterface = defaultRequestOptions): Promise<any> {
    UtilsService.showLoading();
    try {
      const headers = await this.getHeader(options || defaultRequestOptions);
      const requestUrl: string = (options.baseUrl || defaultRequestOptions.baseUrl) + url;
      console.info(`[POST] ${requestUrl}`);

      const rawResponse = await fetch(requestUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });
      let res: any = rawResponse;
      if (options.responseType == 'text') res = await rawResponse.text();
      else res = (await rawResponse.json())?.message || {};

      if (!rawResponse.ok) throw res;

      return {
        response: res,
        statusCode: res?.code || rawResponse.status
      };
    } catch (error) {
      this.handleError(options, error);
      throw error;
    } finally {    
      if (options.hideLoading) UtilsService.hideLoading();
    }
  }

  async patch(url: string, body: any = {}, options: RequestOptionsInterface = defaultRequestOptions): Promise<any> {
    UtilsService.showLoading();
    try {
      const headers = await this.getHeader(options || defaultRequestOptions);
      const requestUrl: string = (options.baseUrl || defaultRequestOptions.baseUrl) + url;
      console.info(`[PATCH] ${requestUrl}`);

      const rawResponse = await fetch(requestUrl, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(body)
      });
      let res: any = rawResponse;
      if (options.responseType == 'text') res = await rawResponse.text();
      else res = (await rawResponse.json())?.message || {};

      if (!rawResponse.ok) throw res;

      return {
        response: res,
        statusCode: res?.code || rawResponse.status
      };
    } catch (error) {
      this.handleError(options, error);
      throw error;
    } finally {    
      if (options.hideLoading) UtilsService.hideLoading();
    }
  }


  async postFile(url: string, file: File, options: RequestOptionsInterface = defaultRequestOptions): Promise<any> {
    UtilsService.showLoading();
    try {
      const headers = await this.getHeader(options || defaultRequestOptions);
      delete headers["Content-Type"];
      delete headers["Accept"];

      const requestUrl: string = (options.baseUrl || defaultRequestOptions.baseUrl) + url;
      console.info(`[POST FILE] ${requestUrl}`);

      const formData = new FormData();
      formData.append("file", file);

      const rawResponse = await fetch(requestUrl, {
        method: 'POST',
        headers,
        body: formData
      });

      let res: any = rawResponse;
      if (options.responseType == 'text') res = await rawResponse.text();
      else res = (await rawResponse.json())?.message || {};

      if (!rawResponse.ok) throw res;

      return {
        response: res,
        statusCode: res?.code || rawResponse.status
      };
    } catch (error) {
      this.handleError(options, error);
      throw error;
    } finally {    
      UtilsService.hideLoading();
    }
  }

  handleError(options: RequestOptionsInterface, error: any) {
    console.info("Request Failed: ", error);
    if (options.doNotHandleError) return ;
    UtilsService.showToast(error.toString(), "bg-danger")
  }
  
}