import { SessionRecordInterface } from '../../interfaces/schema/session-record.interface';
import store from '../store';

export const SessionRecordGetter = {
  item   : (sessionRecordId: string) => 
    store.getState().sessionRecord.entities?.find((entity: SessionRecordInterface) => entity._id == sessionRecordId),

  listAll: () => 
    store.getState().sessionRecord.entities,
    
  count  : () => 
    store.getState().sessionRecord.count,

}