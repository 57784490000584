import { Pagination } from "../../interfaces/common/pagination.interface"
import { LoginInterface } from "../../interfaces/schema/login.interface"

function FETCH(pagination?: Pagination) {
  return {
    type: LOGINS_ACTION_TYPES.FETCH,
    payload: {
      pagination: pagination || {}
    }
  }
}

function FETCH_SUCCESS(results: LoginInterface[], count: number) {
  return {
    type: LOGINS_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

function SAVE(payload: LoginInterface, cb?: () => void) {
  return {
    type: LOGINS_ACTION_TYPES.SAVE,
    payload: {
      payload,
      cb
    }
  }
}

function SAVE_SUCCESS(result: LoginInterface) {
  return {
    type: LOGINS_ACTION_TYPES.SAVE_SUCCESS,
    payload: {
      result
    }
  }
}

function ARCHIVE(loginId: string, cb: () => void) {
  return {
    type: LOGINS_ACTION_TYPES.ARCHIVE,
    payload: {
      cb,
      loginId
    }
  }
}

function ARCHIVE_SUCCESS(loginId: string) {
  return {
    type: LOGINS_ACTION_TYPES.ARCHIVE_SUCCESS,
    payload: {
      loginId
    }
  }
}

function UPDATE_PASSWORD(password: string) {
  return {
    type: LOGINS_ACTION_TYPES.UPDATE_PASSWORD,
    payload: {
      password
    }
  }
}


function SEND_RESET_PASSWORD_EMAIL(email: string) {
  return {
    type: LOGINS_ACTION_TYPES.SEND_RESET_PASSWORD_EMAIL,
    payload: {
      email
    }
  }
}

export const LOGINS_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  ARCHIVE,
  ARCHIVE_SUCCESS,
  UPDATE_PASSWORD,
  SEND_RESET_PASSWORD_EMAIL
}

export const LOGINS_ACTION_TYPES = {
  FETCH: '[LOGINS] FETCH',
  FETCH_SUCCESS: '[LOGINS] FETCH SUCCESS',
  SAVE: '[LOGINS] SAVE',
  SAVE_SUCCESS: '[LOGINS] SAVE SUCCESS',
  ARCHIVE: '[LOGINS] ARCHIVE',
  ARCHIVE_SUCCESS: '[LOGINS] ARCHIVE SUCCESS',
  UPDATE_PASSWORD: '[LOGINS] UPDATE PASSWORD',
  SEND_RESET_PASSWORD_EMAIL: '[LOGINS] SEND RESET PASSWORD EMAIL'
}