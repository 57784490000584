import React, { useEffect, useState } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, InputLabel, Input, Button, Dialog, DialogTitle } from "@material-ui/core";
import store from "../../store/store";
import { LOGINS_ACTION } from "../../store/logins/logins.actions";

export default function ForgotPasswordDialog(props: {
  showDialog: boolean,
  setShowDialog: (value: boolean) => void
}) {

  const [email, setEmail] = useState('' as string)

  /**
   * Determine can save or not
   * 
   * @returns 
   */
  const canSave = () => {
    return !!email;
  }

  /** 
   * 
  */
  const submit = () => {
    store.dispatch(LOGINS_ACTION.SEND_RESET_PASSWORD_EMAIL(email));
    props.setShowDialog(false);
  }

  return (
    <Dialog onClose={() => {props.setShowDialog(false)}} aria-labelledby="simple-dialog-title" open={props.showDialog}>
      <DialogTitle id="simple-dialog-title">Forgot Password</DialogTitle>
      <div className="px-4 pb-4" style={{
        minWidth: 500
      }}>
        <FormControl className="w-100">
          <InputLabel>Email Address</InputLabel>
          <Input value={email} onChange={(evt) => {
            setEmail(evt.target.value);
          }} />
        </FormControl>
        <div className="d-flex pt-4">
          <Button color="primary" onClick={() => props.setShowDialog(false)}>
            Close
          </Button>
          <Button 
            className = "ms-auto" 
            color     = "primary" 
            variant   = "contained" 
            disabled  = {!canSave()}
            onClick   = {submit}>
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  )
}