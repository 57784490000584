import { ProjectInterface } from '../../interfaces/schema/project.interface';
import store from './../store';

export const ProjectGetter = {
  item   : (projectId: string) => 
    store.getState().project.entities?.find((entity: ProjectInterface) => entity._id == projectId),

  listAll: () => 
    store.getState().project.entities,
    
  count  : () => 
    store.getState().project.count,
}