export const GlobalStyleConstant = {
  /**
   * Border Rounding
   */
  roundedLg: 15,
  rounded: 10,
  roundedSm: 5,

  /**
   * Padding + Margin
   */
  pmSm: 5,
  pmMd: 10,
  pmLg: 15,
  pmXl: 30,

  /**
   * Dialog + Card
   */
  dcWidthSm: 150,
  dcWidthMd: 250,
  dcWidthLg: 350,


  /**
   * Dialog + Card
   */
   textFieldWidthSm: 50,
   textFieldWidthMd: 150,
   textFieldWidthLg: 250,


  /**
   * Dialog + Card
   */
   dialogSm: 250,
   dialogMd: 650,
   dialogLg: 1280,

  /**
   * Font size
   */
  textXs: 12,
  textSm: 16,
  textMd: 24,
  textLg: 36,

  /**
   * Icon Size
   */
  iconXl: 80,
  iconLg: 60,
  iconMd: 40,

  /**
   * Font Color
   */
  muted: '#828282',
  primary: '#2196f3',
  accent: '#50ba55',
  success: '#51c973',
  warning: '#ff951c',
  danger: '#dc3545',
  white: '#fff',
  black: '#000',
  bgPrimary: '#2196f3',
  bgLight: '#f4f4f4',
  lightgrey: 'rgba(0,0,0,.07)',
  bgDark: 'rgba(0,0,0,.7)',
  lightDanger: '#f25c5c'
}