import { CompanyPositionInterface } from "../../interfaces/schema/company-position.interface";
import { RequestService } from "../common/request.service";

export const CompanyPositionService = {
  /**
   * Fetch position
   */ 
   fetch() {
    const _http   = new RequestService();
    return _http.get(`/company-positions`).then(res => res.response);
  },

  /**
   * Insert or update position
   * @param payload 
   */
   save(payload: CompanyPositionInterface) {
    const _http   = new RequestService();
    return _http.post(`/company-positions`, payload).then(res => res.response?.result);
  },

  /**
   * Archive position
   * @param positionId 
   */
  archive(positionId: string) {
    const _http = new RequestService();
    return _http.delete(`/company-positions/${positionId}`).then(res => res.response);
  }
}