export enum PagePaths {
  DASHBOARD         = '/',
  LOGIN             = '/login',
  SESSION_DATA      = '/session-data',
  GLOBAL_CONFIG     = '/global-config',
  PROJECT_SETTING   = '/project-setting',
  API_CREDENTIAL    = '/api-credential',
  API_SWAGGER       = 'http://api.meatresearch.com.au/docs',
  COMPANIES         = '/companies',
  COMPANY_POSITIONS = '/company-positions',
  COMPANY_DETAIL    = '/company-detail',
  IMPORTED_FILES    = '/imported-files',

  KILL_DATA              = '/kill-data',
  KILL_DATA_CONFIG       = '/kill-data-config',

  ROLES     = '/roles',
  PEOPLE    = '/people',
  LOGINS    = '/logins',
  RESOURCES = '/resources',
  SETTINGS  = '/settings'
}