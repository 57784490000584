import { RoleInterface } from "../../interfaces/schema/role.interface";
import { RequestService } from "../common/request.service";

export const RoleService = {

  /**
   * Fetch roles
   */ 
  fetch() {
    const _http   = new RequestService();
    return _http.get(`/roles`).then(res => res.response);
  },

  /**
   * Insert or update project
   * @param payload 
   */
   save(payload: RoleInterface) {
    const _http   = new RequestService();
    return _http.post(`/roles`, payload).then(res => res.response?.result);
  },

  /**
   * Archive project
   * @param projectId 
   */
  archive(roldId: string) {
    const _http = new RequestService();
    return _http.delete(`/roles/${roldId}`).then(res => res.response);
  }
}