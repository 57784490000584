import { Pagination } from "../../interfaces/common/pagination.interface"
import { ProjectInterface } from "../../interfaces/schema/project.interface"

function FETCH(pagination?: Pagination, cb?: (...args:any) => void) {
  return {
    type: PROJECT_ACTION_TYPES.FETCH,
    payload: {
      pagination: pagination || {},
      cb
    }
  }
}

function FETCH_SUCCESS(results: ProjectInterface[], count: number) {
  return {
    type: PROJECT_ACTION_TYPES.FETCH_SUCCESS,
    payload: {
      results,
      count
    }
  }
}

function SAVE(payload: ProjectInterface, cb?: () => void) {
  return {
    type: PROJECT_ACTION_TYPES.SAVE,
    payload: {
      payload,
      cb
    }
  }
}

function SAVE_SUCCESS(result: ProjectInterface) {
  return {
    type: PROJECT_ACTION_TYPES.SAVE_SUCCESS,
    payload: {
      result
    }
  }
}

function ARCHIVE(projectId: string) {
  return {
    type: PROJECT_ACTION_TYPES.ARCHIVE,
    payload: {
      projectId
    }
  }
}

function ARCHIVE_SUCCESS(projectId: string) {
  return {
    type: PROJECT_ACTION_TYPES.ARCHIVE_SUCCESS,
    payload: {
      projectId
    }
  }
}

export const PROJECT_ACTION = {
  FETCH,
  FETCH_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  ARCHIVE,
  ARCHIVE_SUCCESS
}

export const PROJECT_ACTION_TYPES = {
  FETCH: '[PROJECT] FETCH',
  FETCH_SUCCESS: '[PROJECT] FETCH SUCCESS',
  SAVE: '[PROJECT] SAVE',
  SAVE_SUCCESS: '[PROJECT] SAVE SUCCESS',
  ARCHIVE: '[PROJECT] ARCHIVE',
  ARCHIVE_SUCCESS: '[PROJECT] ARCHIVE SUCCESS'
}