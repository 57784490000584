import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import store from '../../../store/store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ProjectInterface } from '../../../interfaces/schema/project.interface';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { PROJECT_ACTION } from '../../../store/project/project.actions';
import { ProjectGetter } from '../../../store/project/project.getters';
import { GlobalStyleConstant } from '../../../constants/styles/global.const';
import SessionTypeComponent from './session-type-setting.component';
import Delete from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../dialogs/common/confirm.dialog';

import DeclarationComponent from "../../../components/common/declaration.component";
import ObjectEditorComponent from "../../../components/common/object-editor.component";
import { cloneDeep } from 'lodash';

/**
 * List of session types
 * 
 * @param props 
 * @returns 
 */
function ProjectRow(props: {
  rowIndex: number, 
  row: ProjectInterface,
  openedRow: number,
  editProject: (project: ProjectInterface) => void,
  archiveProject: (project: ProjectInterface) => void,
  setOpenedRow: (index: number) => void
}) {
  const row = props.row;

  return <React.Fragment>
    <TableRow key={row._id}>
      <TableCell component="th" scope="row">
        {row.serial_number}
      </TableCell>
      <TableCell>
        {row.project_name}
      </TableCell>
      <TableCell>
      </TableCell>
      <TableCell align="right">
        <Tooltip title="Edit Project">
          <IconButton onClick={() => props.editProject(row)} size="small" color="primary">
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Archive Project">
          <IconButton onClick={() => props.archiveProject(row)} size="small" color="secondary">
            <Delete />
          </IconButton>
        </Tooltip>

        <Tooltip title="View Session Types">
          <IconButton 
            onClick={
              () => props.setOpenedRow(props.openedRow == props.rowIndex ? -1 : props.rowIndex)
            } 
            size="small"
          >
            {props.openedRow == props.rowIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={99}>
        <Collapse in={props.openedRow == props.rowIndex} timeout="auto" unmountOnExit>
          <Box>
            <SessionTypeComponent
              projectId={row._id}
            />
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
}


/**
 * Project Setting Page
 * @returns {JSX.Element}
 */
export default function ProjectSettingPage() {

  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [editingProject, setEditingProject] = useState({} as ProjectInterface);
  const [projects, setProjects] = useState([] as ProjectInterface[]);
  const [openedRow, setOpenedRow] = useState(-1);
  const [showConfirm,    setShowConfirm]    = useState(false as boolean)
  const [confirmMessage, setConfirmMessage] = useState("" as string)
  const [projectConfigs, setProjectConfigs] = useState("{}" as string)
  const [confirmed, setConfirmed] = useState(false as boolean)

  useEffect(() => {
    /** Fetch Projects */
    fetchProjects();

    /** Subscribe to store changes */
    const unsubscribeStore = store.subscribe(() => {
      setProjects(ProjectGetter.listAll)
    })

    return () => {
      unsubscribeStore();
    }
  }, [])

  /**
   * Fetch Project List
   */
  const fetchProjects = () => {
    store.dispatch(PROJECT_ACTION.FETCH())
  }

  /**
   * Archive Project
   * 
   * @param project 
   */
  const archiveProject = () => {
    setShowConfirm(false);
    if (editingProject._id)
      store.dispatch(PROJECT_ACTION.ARCHIVE(editingProject._id))
  }

  const confirmArchiveProject = (project: ProjectInterface) => {
    setEditingProject(project);
    setShowConfirm(true);
    setConfirmMessage(`Archive Project ${project.project_name} #${project.serial_number}`);
  }
  
  /**
   * Fetch roles 
   */
  // const fetchRoles = () => {
  //   store.dispatch(ROLE_ACTION.FETCH());
  // }

  /**
   * Edit Project
   */
  const editProject = (project?: ProjectInterface) => {
    setShowProjectDialog(true);
    setEditingProject(project ? cloneDeep(project) : {});
    if (project?.project_configs) {
      setProjectConfigs(JSON.stringify(project.project_configs, null, 2))
    }
  }

  /**
   * Determine User Can Save
   * 
   * @returns {boolean}
   */
  const canSave = () => {
    return editingProject.project_name;
  }

  /**
   * Save Project
   */
  const saveProject = () => {
    store.dispatch(PROJECT_ACTION.SAVE(editingProject, () => {
      setShowProjectDialog(false);
    }));
  }

  return (
    <div>
      <div className="d-flex mb-3">
        <span className="h3">Projects</span>
        <Button variant="contained" color="primary" className="ms-auto" onClick={() => editProject()}>Create Project</Button>
      </div>
      <div>

        <ConfirmDialog
          show={showConfirm}
          setShow={setShowConfirm}
          message={confirmMessage}
          confirm={archiveProject}
        ></ConfirmDialog>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Project No.</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Enabled Roles</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project, projectIndex) => (
                <ProjectRow 
                  key            = {projectIndex}
                  rowIndex       = {projectIndex}
                  row            = {project}
                  openedRow      = {openedRow}
                  editProject    = {editProject}
                  setOpenedRow   = {setOpenedRow}
                  archiveProject = {confirmArchiveProject}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog 
        open={showProjectDialog} 
        onClose={() => setShowProjectDialog(false)}
      >
        <DialogTitle>Edit Project</DialogTitle>
        <DialogContent
        style={{
          width: GlobalStyleConstant.dcWidthLg
        }}>
          <TextField
            autoFocus
            margin="dense"
            label="Project Name"
            value = {editingProject.project_name}
            fullWidth
          />
          
          <b>Project Configs</b>
          <ObjectEditorComponent
            object={projectConfigs}
            setObject={(evt: string) => {
              setProjectConfigs(evt)
              try {
                const project_configs = JSON.parse(evt);
                setEditingProject({
                  ...editingProject,
                  project_configs
                })
              } catch (error) {
                
              }
            }}
          ></ObjectEditorComponent>
        {
          canSave() && <div className="mt-4">
            <DeclarationComponent
              checked = {confirmed}
              setChecked = {setConfirmed}
            ></DeclarationComponent>
          </div> || ""
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowProjectDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => saveProject()} color="primary" disabled={!canSave() || !confirmed}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}