import { KillDataConfigSummaryInterface } from "../../interfaces/schema/kill-data-config-summary.interface";
import { KillDataConfigInterface } from "../../interfaces/schema/kill-data-config.interface";
import { KILL_DATA_CONFIG_ACTION_TYPES } from "./kill-data-config.actions";

export interface KillDataConfigStateInterface {
  entities: KillDataConfigInterface[],
  count   : number,
  summary : KillDataConfigSummaryInterface[]
}

const initialState: KillDataConfigStateInterface = {
  entities: [],
  summary : [],
  count   : 0
}

export function KillDataConfigReducer(
  state: KillDataConfigStateInterface = initialState, 
  action: {type: string, payload?: any}) 
{
  if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.FETCH_SUCCESS) {
    state.entities = action.payload?.results || [];
    state.count    = action.payload?.count   || 0;
  }
  else if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.FETCH_SUMMARY_SUCCESS) {
    state.summary = action.payload?.summary
  }
  else if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.FETCH_AVAILABLE_SUCCESS) {
    state.entities = action.payload?.results || [];
    state.count    = action.payload?.count   || 0;
  }
  else if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.FETCH_ITEM_SUCCESS) {
    const entityIndex = state.entities.findIndex(entity => entity._id ==action.payload?.result?._id);
    if (entityIndex == -1) {
      state.count += 1;
      state.entities.push(action.payload?.result)
    } else {
      state.entities[entityIndex] = action.payload?.result;
    }
  }
  else if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.SAVE_SUCCESS) {
    const entityIndex = state.entities.findIndex(entity => entity._id ==action.payload?.result?._id);
    if (entityIndex == -1) {
      state.count += 1;
      state.entities.push(action.payload?.result)
    } else {
      state.entities[entityIndex] = action.payload?.result;
    }
  }

  else if (action.type === KILL_DATA_CONFIG_ACTION_TYPES.ARCHIVE_SUCCESS) {
    state.entities = state.entities.filter(doc => doc._id != action.payload.killDataConfigId);
    state.count--;
  }

  return state;
}