import { KillDataConfigColumnInterface } from "../../interfaces/schema/kill-data-config-column.interface";
import * as xml2js from 'xml2js';
import { KillDataConfigColumnDataSource } from "../../enums/kill-data-config-column-data-source.enum";

export const KillDataConfigService = {

  /**
   * Read file and convert to columns
   * 
   * @param file 
   * @returns 
   */
  readFile(options: {
    file               : File,
    column_prefix     ?: string,
    column_suffix     ?: string,
    file_category      : string,
    accept_range_from ?: number,
    accept_range_to   ?: number
  }): Promise<KillDataConfigColumnInterface[]> {
    const {
      file,
      column_prefix,
      column_suffix,
      file_category
    } = options;
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = function(){
        const rawData = reader.result as string;

        /** Read Completed, Process XML */
        if (file.type == 'text/xml') {
          const parser = new xml2js.Parser();
          parser.parseStringPromise(rawData).then((parsedData: any) => {
            if (parsedData?.GradeData?.Record?.length) {
              const cleanData = parsedData.GradeData.Record.map((record: any) => record['$']);
              if (!cleanData[0])
                return rej("No Data Found")
              let headers = Object.keys(cleanData[0]);
              if (options.accept_range_to)
                headers = headers.slice(options.accept_range_from || 0, options.accept_range_to)
              return res(
                headers.map((header: string) => ({
                  text              : `${column_prefix || ""}${header}${column_suffix||""}`,
                  value_key         : header,
                  merge_key         : "",
                  anchor_key        : "",
                  not_null          : false,
                  kill_date_column  : false,
                  hidden            : false,
                  is_key_attribute  : false,
                  data_source       : KillDataConfigColumnDataSource.MANUAL_IMPORT,
                  file_name_pattern : file_category
                }))
              )
            }
            else {
              return rej("Unable to parse XML")
            }
          });
        }
        /** Read Completed, Process CSV */
        else if (file.type == 'text/csv' || file.type.includes("ms-excel")) {
          const data    = rawData.split("\n").map(row => row.trim().split(",")) as string[][];
          if (!data.length) 
            return rej("File Is Empty");

          let headers = data.splice(0, 1)[0].map(key => {
            /** Format headers and values */
            if ([`"`, `'`].includes(key && key[0])) {
              key = key.slice(1, key.length);
            }
            if ([`"`, `'`].includes(key && key[key.length - 1])) {
              key = key.slice(0, key.length - 1);
            };
            return key;
          });

          if (options.accept_range_to)
            headers = headers.slice(options.accept_range_from || 0, options.accept_range_to)
          return res(
            headers.map((header: string) => ({
              text              : `${column_prefix || ""}${header}${column_suffix||""}`,
              value_key         : header,
              anchor_key        : "",
              merge_key         : "",
              not_null          : false,
              kill_date_column  : false,
              hidden            : false,
              is_key_attribute  : false,
              data_source       : KillDataConfigColumnDataSource.MANUAL_IMPORT,
              file_name_pattern : file_category
            }))
          )
        }
        else {
          return rej("Invalid File Type: " + file.type);
        }
      };
      reader.onerror = err => {
        rej(err)
      }
      reader.readAsText(file);
    })
  }

}