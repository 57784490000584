import { SessionInterface } from "../../interfaces/schema/session.interface";
import { SESSION_ACTION_TYPES } from "./session.actions";

export interface SessionStateInterface {
  entities: SessionInterface[],
  count   : number
}

const initialState: SessionStateInterface = {
  entities: [],
  count   : 0
}

export function SessionReducer(
  state: SessionStateInterface = initialState, 
  action: {type: string, payload?: any}) 
{
  if (action.type === SESSION_ACTION_TYPES.FETCH_VIEW_SUCCESS) {
    state.entities = action.payload.results;
    state.count    = action.payload.count;
  }
  else if (action.type === SESSION_ACTION_TYPES.ARCHIVE_SESSION_SUCCESS) {
    state.entities = state.entities.filter(entity => entity._id != action.payload);
    state.count--
  }
  else if (action.type === SESSION_ACTION_TYPES.MODIFY_SESSION_SUCCESS) {
    const index = state.entities.findIndex(entity => entity._id == action.payload._id);
    if (index != -1) state.entities[index] = action.payload;
  }
  return state;
}