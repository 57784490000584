import { KillDataConfigInterface } from "../../interfaces/schema/kill-data-config.interface";
import { RequestService } from "../common/request.service";

export const KillDataConfigService = {
  /**
   * Fetch killDataConfig
   */ 
   fetch() {
    const _http = new RequestService();
    return _http.get(`/admin/kill-data-configs`).then(res => res.response);
  },

  /**
   * Fetch available kill data configs
   */ 
    fetchAvailable() {
      const _http = new RequestService();
      return _http.get(`/admin/kill-data-configs/available`).then(res => res.response);
    },

  /**
   * FETCH SUMMARY
   */
  fetchSummary() {
    const _http = new RequestService();
    return _http.get(`/admin/kill-data-configs/summary`).then(res => res.response);
  },

  /**
   * Fetch killDataConfig by id
   */ 
    fetchItem(id: string) {
      const _http   = new RequestService();
      return _http.get(`/admin/kill-data-configs/${id}/detail`).then(res => res.response);
    },

  /**
   * Insert or update killDataConfig
   * @param payload 
   */
   save(payload: KillDataConfigInterface) {
    const _http   = new RequestService();
    return _http.post(`/admin/kill-data-configs`, payload).then(res => res.response?.result);
  },

  /**
   * Archive killDataConfig
   * @param killDataConfigId 
   */
  archive(killDataConfigId: string) {
    const _http = new RequestService();
    return _http.delete(`/admin/kill-data-configs/${killDataConfigId}`).then(res => res.response);
  }
}