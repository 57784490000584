import store from '../store';
import { AuthenticationStateInterface } from './authentication.reducer';

export const AuthenticationGetter = {
  isLoggedIn: () => 
    store.getState()?.authentication?.isLoggedIn,

  currentRole: () => 
    store.getState()?.authentication?.role?.role,

  currentCompanyRole: () => 
    store.getState()?.authentication?.role,

  currentCompany: () => 
    store.getState()?.authentication?.role?.company,

  currentUser: () => 
    store.getState()?.authentication?.login,

  currentOperator: () => 
    store.getState()?.authentication?.operator,

  token: () => 
    store.getState()?.authentication?.tokens?.token,

  refreshToken: () => 
    store.getState()?.authentication?.tokens?.refreshToken,

  roles: () =>
    store.getState()?.authentication?.roles,

  hasResource: (resourceName: string) => 
    (store.getState()?.authentication as AuthenticationStateInterface)?.resources?.some(resource => resource.resource_name == resourceName)
}