import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AuthenticationGetter } from "../../store/authentication/authentication.getters";
import { RoleInterface } from "../../interfaces/schema/role.interface";
import { Checkbox, Icon } from "@material-ui/core";
import store from "../../store/store";
import { AUTHENTICATION_ACTIONS } from "../../store/authentication/authentication.actions";
import { UtilsService } from "../../services/common/utils.service";
import { CompanyRoleItem } from "../../interfaces/page-state/dashboard-page-state.interface";

export default function ChangeRoleDialog(props: {
  show: boolean,
  setShow: (show: boolean) => void,
  history: any
}) {

  const [selectedRole, setSelectedRole] = useState({} as CompanyRoleItem)

  useEffect(() => {
    setSelectedRole(AuthenticationGetter.currentCompanyRole());
    return () => {
    }
  }, [])

  /**
   * Determine is current role
   * @param role 
   * @returns 
   */
  const selected = (role: RoleInterface) => {
    return selectedRole?.role?._id == role?._id;
  }
/**
 * Filter available roles
 * @returns 
 */
  const roles = () => {
    return AuthenticationGetter.roles() || [];
  }

  /**
   * change role
   */
  const changeRole = () => {
    UtilsService.showLoading();
    props.setShow(false);
    store.dispatch(AUTHENTICATION_ACTIONS.CHANGE_ROLE(selectedRole))
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.show}
        onClose={() => {
          props.setShow(false);
          setSelectedRole(AuthenticationGetter.currentRole());
        }}
      >
        <DialogTitle>
          Available Roles:
        </DialogTitle>
        <DialogContent>
          <table>
            <tbody>
              {
                roles().map((companyRole: CompanyRoleItem, roleIndex: number) => {
                  const role: RoleInterface = companyRole?.role || {};
                  return <tr key={roleIndex}>
                    <th>
                      {role.role_name}
                    </th>
                    <td>
                      <Checkbox 
                        checked={selected(role)}
                        icon={
                          <Icon>
                            radio_button_unchecked
                          </Icon>
                        }
                        checkedIcon={
                          <Icon>radio_button_checked</Icon>
                        }
                        color="primary"
                        onChange={() => {
                          setSelectedRole(companyRole);
                        }} />
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => props.setShow(false)} color="primary">
            <b>Cancel</b>
          </Button>
          <Button color="secondary" onClick={changeRole}>
            <b>Change</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}