
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Chip, Icon, IconButton, TextField, Tooltip } from '@material-ui/core';
import store from '../../../../store/store';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { cloneDeep } from 'lodash';

import { UtilsService } from '../../../../services/common/utils.service';
import { RoleInterface } from '../../../../interfaces/schema/role.interface';
import { RoleGetter } from '../../../../store/role/role.getter';
import { ROLE_ACTION } from '../../../../store/role/role.actions';
import { GlobalStyleConstant } from '../../../../constants/styles/global.const';
import { RESOURCE_ACTION } from '../../../../store/resource/resource.actions';
import { ResourceGetter } from '../../../../store/resource/resource.getter';
import { ResourceInterface } from '../../../../interfaces/schema/resource.interface';

/**
 * Roles Page
 * @returns {JSX.Element}
 */
export default function RolesPage() {

  const [roles, setRoles] = useState([] as RoleInterface[])
  const [showDialog, setShowDialog] = useState(false)
  const [editingItem, setEditingItem] = useState({} as RoleInterface)

  useEffect(() => {
    store.dispatch(ROLE_ACTION.FETCH());
    store.dispatch(RESOURCE_ACTION.FETCH());
    const unsubscribe = store.subscribe(() => {
      setRoles(RoleGetter.listAll());
    })

    return () => {
      unsubscribe();
    }
  }, [])

  /**
   * Pop up edit dialog
   * 
   * @param role 
   */
  const editRole = (role?: RoleInterface) => {
    setEditingItem(cloneDeep(role || {}))
    setShowDialog(true);
  }

  const canView = (resource: ResourceInterface) => {
    return editingItem.resources?.some(r => r.resource == resource._id && r.can_view);
  }

  const canCreate = (resource: ResourceInterface) => {
    return editingItem.resources?.some(r => r.resource == resource._id && r.can_create);
  }

  const canEdit = (resource: ResourceInterface) => {
    return editingItem.resources?.some(r => r.resource == resource._id && r.can_edit);
  }

  const canDelete = (resource: ResourceInterface) => {
    return editingItem.resources?.some(r => r.resource == resource._id && r.can_delete);
  }

  const togglePermission = (resource: ResourceInterface, permission: 'can_view' | 'can_edit' | 'can_delete' | 'can_create') => {
    const newRole = cloneDeep(editingItem);
    if (!newRole.resources) newRole.resources = [];
    const res = newRole.resources?.find(r => r.resource == resource._id );

    if (!res?.resource)
      newRole.resources?.push({
        resource: resource._id,
        [permission]: true
      })
    else 
      res[permission] = !res[permission]
    setEditingItem(newRole);
  }

  const save = () => {
    store.dispatch(ROLE_ACTION.SAVE(editingItem, () => {
      setShowDialog(false);
      UtilsService.showToast("Saved");
    }));
  }

  /**
   * Load list of resources by role
   */
  const roleResources = (role: RoleInterface) => {
    return ResourceGetter.listAll().filter(resource => {
      return role.resources?.some(r => r.resource == resource._id && (r.can_view || r.can_create || r.can_delete || r.can_edit));
    })
  }

  /**
   * Must Viewable
   */
  const mustViewable = (resource: ResourceInterface) => {
    return !!editingItem.resources?.some(r => r.resource == resource._id && 
      (r.can_create || r.can_delete || r.can_edit)
    )
  }

  return (
    <div>
      <div className="d-flex mb-3">
        <span className="h3">Roles</span>
        <Button variant="contained" color="primary" className="ms-auto" onClick={() => editRole()}>Create Role</Button>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Role Name</TableCell>
                <TableCell>Resources</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell component="th" scope="row">
                    <Icon fontSize="small" className="me-1">{row.role_icon}</Icon>
                    {row.role_name}
                  </TableCell>
                <TableCell>
                  {
                    roleResources(row).map((resource, resourceIndex) => {
                      return <Chip className="me-2" label={resource.resource_name} key={resourceIndex} />
                    })
                  }
                </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Config">
                      <IconButton onClick={() => editRole(row)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="lg">
        <DialogTitle className="pb-0">{editingItem._id ? "Edit" : "Create"} Role</DialogTitle>
        <DialogContent style={{width: GlobalStyleConstant.dialogMd}}>
          <TextField
            autoFocus
            margin="dense"
            label="Role Name"
            value={editingItem.role_name}
            onChange={(e) => {
              setEditingItem({
                ...editingItem,
                role_name: e.target.value
              })
            }}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            label="Role Icon"
            value = {editingItem.role_icon}
            onChange={(e) => {
              setEditingItem({
                ...editingItem,
                role_icon: e.target.value
              })
            }}
            fullWidth
          />
          <TableContainer component={Paper} style={{
            marginTop: GlobalStyleConstant.pmMd
          }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Resource Name</TableCell>
                  <TableCell>Can View</TableCell>
                  <TableCell>Can Create</TableCell>
                  <TableCell>Can Edit</TableCell>
                  <TableCell>Can Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
            {
              ResourceGetter.listAll().map((resource, resourceIndex) => {
                return <TableRow key={resourceIndex}>
                  <TableCell component="th" scope="row">
                    {resource.resource_name}
                  </TableCell>
                  <TableCell>
                    {
                      resource.has_view && 
                      <Checkbox
                        checked={canView(resource) || mustViewable(resource)}
                        disabled={mustViewable(resource)}
                        onChange={(e) => {
                          togglePermission(resource, 'can_view')
                        }}
                        color="primary"
                      />
                    }
                  </TableCell>
                  <TableCell>
                    {
                      resource.has_create &&
                      <Checkbox
                        checked={canCreate(resource)}
                        onChange={(e) => {
                          togglePermission(resource, 'can_create')
                        }}
                        color="primary"
                      />
                    }
                  </TableCell>
                  <TableCell>
                    {
                      resource.has_edit &&
                      <Checkbox
                        checked={canEdit(resource)}
                        onChange={(e) => {
                          togglePermission(resource, 'can_edit')
                        }}
                        color="primary"
                      />
                    }
                  </TableCell>
                  <TableCell>
                    {
                      resource.has_delete &&
                      <Checkbox
                        checked={canDelete(resource)}
                        onChange={(e) => {
                          togglePermission(resource, 'can_delete')
                        }}
                        color="primary"
                      />
                    }
                  </TableCell>
                </TableRow>
              })
            }
            </TableBody>
          </Table>
        </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => save()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
