import { KillDataViewMode } from "../../enums/kill-data-view-mode.enum"

function FETCH(options: {
  kill_date_from: string,
  kill_date_to  : string,
  config_id     : string,
  format       ?: string,
  orderBy      ?: number
  order         : 'desc' | 'asc'
  view         ?: KillDataViewMode
}) {
  return {
    type: KILL_DATA_ACTION_TYPES.FETCH,
    payload: options
  }
}

function FETCH_SUCCESS(options: {
  records: string[][],
  headers: [],
  summary: []
  nestedRecords: []
}) {
  return {
    type: KILL_DATA_ACTION_TYPES.FETCH_SUCCESS,
    payload: options
  }
}

function FETCH_SUMMARY(options: {
  kill_date_from: string,
  kill_date_to: string,
  config_id: string,
}) {
  return {
    type: KILL_DATA_ACTION_TYPES.FETCH_SUMMARY,
    payload: {
      kill_date_from: options.kill_date_from,
      kill_date_to: options.kill_date_to,
      config_id: options.config_id
    }
  }
}

function FETCH_SUMMARY_SUCCESS(options: {
  records: string[][],
  headers: []
}) {
  return {
    type: KILL_DATA_ACTION_TYPES.FETCH_SUMMARY_SUCCESS,
    payload: options
  }
}

function FLUSH_CACHE(options: {
  kill_date_from: string,
  kill_date_to: string
}) {
  return {
    type: KILL_DATA_ACTION_TYPES.FLUSH_CACHE,
    payload: options
  }
}

export const KILL_DATA_ACTIONS = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_SUMMARY,
  FETCH_SUMMARY_SUCCESS,
  FLUSH_CACHE
}

export const KILL_DATA_ACTION_TYPES = {
  FETCH: '[KILL DATA] FETCH',
  FETCH_SUCCESS: '[KILL DATA] FETCH SUCCESS',
  FETCH_SUMMARY : '[KILL DATA] FETCH SUMMARY',
  FETCH_SUMMARY_SUCCESS: '[KILL DATA] FETCH SUMMARY SUCCESS',
  FLUSH_CACHE: '[KILL DATA] FLUSH CACHE'
}