import React, { forwardRef, useImperativeHandle, useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeclarationComponent from "../../components/common/declaration.component";
import { SessionInterface } from "../../interfaces/schema/session.interface";
import ObjectEditorComponent from "../../components/common/object-editor.component";

import store from "../../store/store";
import { SESSION_ACTION } from "../../store/session/session.actions";

export const EditSessionMetaDataDialog = forwardRef((props: {
  show: boolean,
  ref: any,
  setShow: (show: boolean) => void,
  session: SessionInterface
}, ref) => {

  const [checked, setChecked]   = useState(false as boolean);
  const [metaData, setMetaData] = useState("{}" as string);

  useImperativeHandle(ref, () => ({
      /**
       * Initial Record Data
       * 
       * @param record 
       */
      initialEditingItem(session: SessionInterface) {
        setMetaData(JSON.stringify(session.session_meta_data || {}, null, 2))
      }
    })
  )

  /**
   * Save changes
   */
  const save = () => {
    store.dispatch(SESSION_ACTION.MODIFY_META_DATA({
      projectId: props.session.project?._id || "",
      sessionId: props.session._id || "",
      session: {
        ...props.session,
        session_meta_data: JSON.parse(metaData)
      }
    }))
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.show}
        onClose={() => props.setShow(false)}
      >
        <DialogTitle>
          Edit {props.session.session_type?.session_type_name} #{props.session.serial_number} Meta Data
        </DialogTitle>
        <DialogContent>

          <ObjectEditorComponent
            object={metaData}
            setObject={setMetaData}
          ></ObjectEditorComponent>

          <DeclarationComponent checked={checked} setChecked={setChecked}></DeclarationComponent>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => props.setShow(false)} color="primary">
            <b>Cancel</b>
          </Button>
          <Button onClick={save} color="secondary" disabled={!checked}>
            <b>Confirm</b>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
});